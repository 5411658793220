import React, { useEffect, useState } from "react";
import Layout from "../../Admin/layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
//import { LazyLoadImage } from "react-lazy-load-image-component";


const InternForm = () => {
  const [internForm, setInternForm] = useState([]);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");
  const getInternform = async () => {
    try {
      const res = await axios.get("https://bytefaze-backend.onrender.com/api/v1/formUser", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res.data, "res");
      setInternForm(res.data);
    } catch (error) {
      console.log(error, "error is ");
    }
  };

  useEffect(() => {
    getInternform();
  }, []);

  return (
    <Layout>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Students Form</h2>
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="thead-dark">
              <tr>
                {/* <th>Address</th> */}
                <th>First Name</th>
                <th>Last Name</th>
                <th>Age</th>
                <th>Email</th>
                <th>Phone Number</th>
                {/* <th>Social Media Links</th>
                <th>Profile</th>
                <th>Technology</th> */}
                {/* <th>ID Proof</th> */}
              </tr>
            </thead>
            <tbody>
              {internForm.map((item, index) => (
                <tr key={index}>
                  {/* <td>{item.address}</td> */}
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.age}</td>
                  <td>{item.email}</td>
                  <td>{item.phoneNumber}</td>
                  {/* <td>{item?.socialMediaLinks}</td>
                  <td><img loading="lazy"  src={item?.profile} alt="" className="img-fluid" width="50" /></td>
                  <td>{item?.technology  }</td> */}
                  {/* <td>
                    {item.idProof.map((proof, proofIndex) => (
                      <img loading="lazy"  key={proofIndex} src={proof} alt="" className="img-fluid" width="50" />
                    ))}
                  </td> */}
                  <td><button className="btn-success" onClick={()=>{navigate(`/dashboard/intern-details/${item?._id}`)}}>View More</button></td>
                </tr>
              ))}
            </tbody>

           
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default InternForm;
