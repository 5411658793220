
import React from "react";

import whatsapp from "../../assets/WebpImages/whatsapp.webp";
import instagram from "../../assets/WebpImages/instagram.webp";
import facebook from "../../assets/WebpImages/facebook.webp";
import linkedin from "../../assets/WebpImages/linkedin.webp";
import callbro from "../../assets/WebpImages/callbro.webp";
import { Link } from "react-router-dom";
//import { LazyLoadImage } from "react-lazy-load-image-component";
const Socialbtns = () => {
  return (
    <>
      <Link to="https://www.instagram.com/bytefaze/" target="_blank">
       <img loading="lazy" 
          className="social_logo"
          src={instagram}
          alt="Instagram"
          style={{ position: "fixed", bottom: "150px", right: "8px" }}
          width={30}
        />
      </Link>
      <Link to="https://wa.me/+918469717272" target="_blank">
       <img loading="lazy" 
          className="social_logo"
          src={whatsapp}
          alt="Whatsapp"
          style={{ position: "fixed", bottom: "110px", right: "8px" }}
          width={30}
        />
      </Link>
      <Link
        to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
        target="_blank"
      >
       <img loading="lazy" 
          className="social_logo"
          src={facebook}
          alt="Facebook"
          style={{ position: "fixed", bottom: "70px", right: "8px" }}
          width={30}
        />
      </Link>
      <Link to="https://www.linkedin.com/company/bytefaze/" target="_blank">
       <img loading="lazy" 
          className="social_logo"
          src={linkedin}
          alt="Linkedin"
          style={{ position: "fixed", bottom: "30px", right: "8px" }}
          width={30}
        />
      </Link>
      <Link to="tel:+91 8469717272" target="_blank">
       <img loading="lazy" 
          className="social_logo"
          src={callbro}
          alt="Linkedin"
          style={{ position: "fixed", bottom: "190px", right: "8px" }}
          width={30}
        />
      </Link>
    </>
  );
};

export default React.memo(Socialbtns);