import React, { useEffect, useState } from "react";
import "../../../Styles/sidebar.css";
import Layout from "../layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Dashloader from "../Dashloader";

const UserDetails = () => {
  const params = useParams();
  const [form, setForm] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const token = sessionStorage.getItem("token");

  // console.log(params)

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    setLoader(true);
    const token = sessionStorage.getItem("token");
   

    try {
      if (!token) {
        navigate("/login");
      } else {
        const response = await axios.get(
          `https://bytefaze-backend.onrender.com/api/v1/form/get-form/${params?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response)
        if (response.data) {
          // console.log(response)
          setForm(response?.data?.form);
          setLoader(false);
        }
      }
      // console.log(response)
    } catch (error) {
      // console.log(error)
    }
  };

  const deleteform = async () => {
    try {
      const result = await axios.delete(
        `https://bytefaze-backend.onrender.com/api/v1/form/delete-user/${params?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate("/dashboard");
      // console.log(result, "id mil gai");

      getUserDetails();
    } catch (error) {
      // console.log("id nahi gai");
    }
  };
  // console.log(form)
  return (
    <Layout>
      <div className="container mt-5">
        <div className="card">
          <div className="card-header h-50 d-flex justify-content-between">
            <h3>Details</h3>
            <button className="btn-danger border px-3" onClick={deleteform}>
              Delete
            </button>
          </div>
          {loader ? (
            <div className="admin-load">
              <div>
                <Dashloader />
              </div>
              ...Loading
              <div>
                <Dashloader />
              </div>
            </div>
          ) : (
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <strong>
                    First Name: &nbsp;&nbsp;{form?.firstName}&nbsp;
                    {form?.lastName}
                  </strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <strong>Email:&nbsp;&nbsp;{form?.email}</strong>
                </div>
                <div className="col-md-6 mt-3">
                  <strong>Phone Number:&nbsp;&nbsp;{form?.phoneNumber}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <strong>City:&nbsp;&nbsp;{form?.city}</strong>
                </div>
                <div className="col-md-6 mt-3">
                  <strong>Area:&nbsp;&nbsp;{form?.area}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3">
                  <strong>Date:&nbsp;&nbsp;{form?.date}</strong>
                </div>
                <div className="col-md-6 mt-3">
                  <strong>Time:&nbsp;&nbsp;{form?.time}</strong>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12 mt-3">
                  <strong>Subject:&nbsp;&nbsp;{form?.subject}</strong>
                </div>
                <div className="col-md-12 mt-3">
                  <strong>Message:&nbsp;&nbsp;{form?.message}</strong>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default UserDetails;
