import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../layout/Layouts';
//import "../../Styles/styles.css";

import { Link, useNavigate, useParams } from 'react-router-dom';

const HireDevOps = () => {
  const navigate = useNavigate();
  const lastSegment = useParams();
  const lasturl = lastSegment.technology;
  const techdata = lasturl;

  const navigateToContacts = () => {    
    navigate('/contact-us');
  };

  return (
    <Layout 
      title={"Bytefaze | DevOps Company in Ahmedabad | DevOps Services"} 
      // keywords={"DevOps engineer, DevOps services, cloud infrastructure, continuous integration, continuous deployment, CI/CD, infrastructure as code, IaC, automated testing, monitoring, cloud services, DevOps consulting, DevOps automation, Kubernetes, Docker, AWS, Azure, Google Cloud, Jenkins, Ansible, Terraform"}
      description={"Welcome to our esteemed IT services company, where a dedicated team of seasoned professionals specializes in delivering strategic business solutions and customized software. Explore our expertise in Software and Web Development, Mobile Application Development, and Web Design, as we strive to provide world-class IT solutions tailored to your needs. If you're looking for a skilled DevOps engineer to streamline your software development lifecycle, enhance deployment processes, and ensure reliable and scalable infrastructure, you're in the right place. Our team of experienced DevOps engineers is committed to delivering high-quality, scalable, and efficient solutions that meet your specific requirements. Contact us today to discuss your project and see how we can help bring your ideas to life."}
      canonical={`https://www.bytefaze.com/hire-devops-engineers`}
    > 
      <div>
        <div className="col-12 hire_bg">
          <h1 className="display-3 text-center fw-bold">Hire DevOps Engineers</h1>
        </div>
        
        {/* Section1 starts here */}
        <div style={{backgroundColor:"var(--footer-bg)"}}>
          <div className="container row">
            <div className="row">
              <h1 className="col-md-12 heading text-center col-12 mt-4">
                Hire DevOps Engineers
              </h1>
            </div>
          </div>

          <div className="container w-75 mt-2">
            <div className="row">
              <p className="heading-small col-md-12 text-center mt-2 w-100">
                Hire DevOps engineers from Bytefaze- a leading IT services company. Our skilled DevOps engineers have extensive experience delivering highly automated, reliable, and scalable infrastructure solutions, leading your product to success.
              </p>
            </div>
          </div>

          <div className="container font01">
            <div className="row mt-3 mb-5 d-flex justify-content-center text-center gap-3">
              <div className="col-md-12 col-sm-12 col-lg-3 border paddi parh mb-2">
                <i className="ri-checkbox-circle-line"></i>
                Flexible hiring models
              </div>

              <div className="col-md-12 col-sm-12 col-lg-3 border paddi mb-2 text-al task">
                <i className="ri-checkbox-circle-line"></i>
                100% Experienced
              </div>

              <div className="col-md-12 col-sm-12 col-lg-3 border mb-2 paddi">
                <i className="ri-checkbox-circle-line"></i>
                100% Refund policy*
              </div>
            </div>
          </div>
        </div>
        {/* Section1 ends here */}

        <Container className="my-5 how">
          <h2 className="text-center mb-5" style={{color:"var(--link-color)"}}>
            How to Hire Developers Through<Link to="/"> Bytefaze</Link>
          </h2>
          <Row className="text-center align-items-center">
            <Col md={4} className="position-relative">
              <div className="mb-3">
                <div className="circle mb-2">1</div>
                <h5>Talk to One of Our Industry Experts</h5>
                <p>A Bytefaze director of engineering will work with you to understand your goals, technical needs, and team dynamics.</p>
              </div>
              <div className="arrow d-none d-md-block"></div>
            </Col>
            <Col md={4} className="position-relative">
              <div className="mb-3">
                <div className="circle mb-2">2</div>
                <h5>Work With Hand-Selected Talent</h5>
                <p>Within days, we'll introduce you to the right developer for your project. Average time to match is under 24 hours.</p>
              </div>
              <div className="arrow d-none d-md-block"></div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <div className="circle mb-2">3</div>
                <h5>The Right Fit, Guaranteed</h5>
                <p>Work with your new IT developer for a trial period (pay only if satisfied), ensuring they're the right fit before starting the engagement.</p>
              </div>
            </Col>
          </Row>
        </Container>

        <section className="hire-developer py-4">
          <div className="container w-100 h-100">
            <div className="row">
              <h2 className="col-12 b-heading text-center mt-4">
                Hire DevOps Engineers For Evolving Project Needs
              </h2>
              <h6 className="small-heading-2 col-12 b-heading text-center">
                Our dedicated team of DevOps engineers has helped businesses achieve their goals by developing high-performing infrastructure and deployment processes.
              </h6>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Cloud Infrastructure Management</h4>
                  <p className='mt-4'>Build robust and scalable cloud infrastructure with our DevOps expertise to ensure high availability and performance.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Continuous Integration and Continuous Deployment (CI/CD)</h4>
                  <p className='mt-4'>Implement CI/CD pipelines to automate your software development lifecycle, enabling faster and more reliable releases.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Infrastructure as Code (IaC)</h4>
                  <p className='mt-4'>Use IaC tools like Terraform and Ansible to automate the provisioning and management of your infrastructure.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Monitoring and Logging</h4>
                  <p className='mt-4'>Implement comprehensive monitoring and logging solutions to ensure the reliability and performance of your applications and infrastructure.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Containerization and Orchestration</h4>
                  <p className='mt-4'>Leverage Docker and Kubernetes for efficient containerization and orchestration of your applications.</p>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="classWithPad border1 padd">
                  <h4 className='mt-1'>Security and Compliance</h4>
                  <p className='mt-4'>Ensure your infrastructure and processes comply with industry standards and best practices for security and compliance.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hire">
          <div className="container mt-5 text-center">
            <div className="row">
              <div className="col-12">
                <h3 className="hire-title">Looking for Dedicated DevOps Engineers?</h3>
                <p className="hire-description mt-3">Let's discuss your DevOps requirements and build a robust and scalable infrastructure together.</p>
                <div className="call-btn" onClick={navigateToContacts}>
                  <button className="btn mb-5" >BOOK A CALL<i className="ri-phone-line"></i></button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="gapes">
          <div className="container py-2">
            <div className="row">
              <div className="col-md-12">
                <h4 className="text-center text-white">Dedicated Hiring Models We Follow</h4>
                <p className="text-center mt-3 text-white">                Hire skilled talent from Bytefaze who will work dedicatedly as your extended team to meet your diverse digital product development needs responsibly.
                </p>
              </div>
            </div>
          </div>

          <div className="container py-2">
            <div className="row d-flex justify-content-between">
              <div className="col-md-6">
                <div className="feature-card">
                  <h3 className="feature-title">Enhance Your Current Team</h3>
                  <p className="feature-description">Choose this model when you want to hire specific skill sets from our diverse talent pool to complement your existing team.</p>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="feature-card">
                  <h3 className="feature-title">Create a Team From Ground Up</h3>
                  <p className="feature-description">Choose this model when you want to build a dedicated team from scratch with specific skills sets from our diverse talent pool.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="middle-heading">
          <div className="container py-3 mt-1">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="Z">
                  <h2 className="b-heading mt-4">Hire Dedicated DevOps Engineers From India in 4 Easy Steps</h2>
                </div>
              </div>
              <div className="col-md-12">
                <h6 className="py-3 w-100 text-center">Looking to hire the best-fit remote engineers for your mission-critical project? Look no further, as Bytefaze follows a quick and easy 4-step process to get you the best talent meeting your requirements.</h6>
              </div>

              <div className="container-fluid midsec">
                <div className="row d-flex justify-content-between">
                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-share-circle-line icon"></i>
                      <h5>1. Share Your Requirements</h5>
                      <p>Provide detailed information about your project requirements, including the specific skills you are looking for in the talent you want to hire. You can fill out the contact form on our website or email us directly with your project details.</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-account-pin-circle-line icon"></i>
                      <h5>2. Shortlist the Right-Fit</h5>
                      <p>Once we receive your project requirements, our team will review them thoroughly to identify the right candidates who match your criteria. We will then shortlist the most suitable talent and provide you with their profiles for your review and consideration.</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-checkbox-circle-fill icon"></i>
                      <h5>3. Interview the Talent</h5>
                      <p>After shortlisting the candidates, you will have the opportunity to interview them to assess their skills, experience, and fit for your project. We can arrange interviews through video calls or in-person meetings, depending on your preference and availability.</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3 mt-4">
                    <div className="padding fact h-100">
                      <i className="ri-computer-line icon"></i>
                      <h5>4. Onboard & Project Kickoff</h5>
                      <p>Once you've selected the talent you want to hire, we will assist you with the onboarding process, including setting up contracts, access to project resources, and any necessary training. Once everything is in place, you can begin the project with your newly onboarded team members.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="who-we-are">
          <div className="container w-100">
            <h3 className="text-center pt-1 mb-4">What We Assure to Provide</h3>
            <div className="row">
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Guaranteed 160 working hours</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Monthly/yearly hiring options available</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Weekly/monthly reporting via email or preferred communication channel</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Weekly/monthly code delivery to track progress</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Direct communication support with our team members</p>
                </div>
              </div>
              <div className="col-md-4 mb-4">
                <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
                  <i className="ri-checkbox-circle-line icon mb-3"></i>
                  <p>Flexible office hours depending on the time zone</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HireDevOps;

