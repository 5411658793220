import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'

const NewSideNav = () => {
  return (
   <>
     <div className="p-4 shadow-sm " style={{ background: "var(--footer-bg)" }}>
              {/* Side Navigation Links */}
              <ul className="list-group mb-4 " style={{listStyle:"none"}} >
                <li >
                <FaCheckCircle className="text-danger mr-2" /> &nbsp; <a href="#section1" >Website Design Services</a>
                </li>
                <li >
                  <FaCheckCircle className="text-danger mr-2" /> &nbsp;<a href="#section2" >SEO Services</a>
                </li>
                <li >
                 <FaCheckCircle className="text-danger mr-2" /> &nbsp; <a href="#section3" >Content Writing</a>
                </li>
                <li >
                  <FaCheckCircle className="text-danger mr-2" /> &nbsp;<a href="#section4" >Web Hosting</a>
                </li>
                <li >
                 <FaCheckCircle className="text-danger mr-2" /> &nbsp; <a href="#section5" >E-commerce Solutions</a>
                </li>
                <li >
                 <FaCheckCircle className="text-danger mr-2" /> &nbsp; <a href="#section6" >Custom Development</a>
                </li>
              </ul>

              {/* Inquiry Now Button */}
              <div className="text-center mt-4">
                <button className="button-33 w-100">
                  Inquiry Now
                </button>
              </div>
            </div>
   </>
  )
}

export default NewSideNav