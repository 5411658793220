import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../layout/Layouts'
//import "../../Styles/styles.css";

import { Link, useNavigate ,useParams } from 'react-router-dom';
import { data } from '../Js/HireDeveloperJson';

const HireDeveloper = () => {
  const navigate = useNavigate();
  const  lastSegment  = useParams();
  // console.log(lastSegment.technology)
  const lasturl = lastSegment.technology
  // const lasturl = location.pathname.substring(location.pathname.lastIndexOf('/') + 1).toString();
  const techdata = lasturl;


  const [result,setResult]=useState()
  
  useEffect(() => {
    const fetchData = async () => {
      const arr = await data;
      const result = arr.find(({ url }) => url === techdata);
      if (!result) {
        navigate('/');
      }else{
        setResult(result)
      }
    };
        fetchData();
  }, [navigate, techdata]);

  const navigateToContacts = () => {    
    navigate('/contact-us');
  };
  return (
    <Layout title={result?.seo.title} keywords={result?.seo.keywords} description={result?.seo.description}
    canonical={`https://www.bytefaze.com/${techdata}`}
    > 
        <>



        <div className="col-12   hire_bg">
        <h1 className="display-3 text-center fw-bold">Hire Developers</h1>
      </div>
    {/* Section1 starts here */}


    <div style={{backgroundColor:"var(--footer-bg)"}}>

        <div className="container row  ">

     
          <div className="row">
            <h1 className="col-md-12 heading text-center col-12 mt-4">
             {result?.section1.title}
            </h1>
          </div>
        </div>

        <div className="container w-75 mt-2 ">
          <div className="row ">
            <p className=" heading-small col-md-12 text-center  mt-2 w-100 ">
            {result?.section1.description}
            </p>
          </div>
        </div>

        <div className="container  font01">
          <div className=" row  mt-3 mb-5 d-flex justify-content-center text-center gap-3 ">  
            <div className="col-md-12 col-sm-12 col-lg-3 border paddi parh  mb-2 ">
              <i className=" ri-checkbox-circle-line"></i>
              {result?.section1.point1}
            </div>

            <div className="col-md-12  col-sm-12 col-lg-3  border paddi mb-2 text-al task ">

              <i className="   ri-checkbox-circle-line"></i>
              {result?.section1.point2}
            </div>
            <div className="col-md-12 col-sm-12 col-lg-3 border mb-2 paddi ">
        

              <i className="   ri-checkbox-circle-line"></i>
              {result?.section1.point3}
            </div>
          </div>
        </div>
        </div>
  {/* Section1 ends here */}



    <Container className="my-5 how">
      <h2 className="text-center mb-5"style={{color:"var(--link-color"}}>How to Hire Developers Through<Link to="/"> Bytefaze</Link></h2>
      <Row className="text-center align-items-center">
        <Col md={4} className="position-relative">
          <div className="mb-3">
            <div className="circle mb-2">1</div>
            <h5>Talk to One of Our Industry Experts</h5>
            <p>A Bytefaze director of engineering will work with you to understand your goals, technical needs, and team dynamics.</p>
          </div>
          <div className="arrow d-none d-md-block"></div>
        </Col>
        <Col md={4} className="position-relative">
          <div className="mb-3">
            <div className="circle mb-2">2</div>
            <h5>Work With Hand-Selected Talent</h5>
            <p>Within days, we'll introduce you to the right developer for your project. Average time to match is under 24 hours.</p>
          </div>
          <div className="arrow d-none d-md-block"></div>
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <div className="circle mb-2">3</div>
            <h5>The Right Fit, Guaranteed</h5>
            <p>Work with your new IT developer for a trial period (pay only if satisfied), ensuring they're the right fit before starting the engagement.</p>
          </div>
        </Col>
      </Row>
    </Container>


    


    <section className="hire-developer py-4   ">
  <div className="container w-100 h-100">
    <div className="row ">
      <h2 className="col-12 b-heading text-center mt-4">
        {result?.section2.title}
      </h2>
      <h6 className="small-heading-2 col-12  b-heading text-center">
        {result?.section2.description}
      </h6>

      <div className="col-md-6 mt-4">
        <div className="classWithPad border1 padd">
          <h4 className='mt-1'>{result?.section2.point1_Title}</h4>
          <p className='mt-4'>{result?.section2.point1_desc}</p>
        </div>
      </div>

      <div className="col-md-6 mt-4">
        <div className="classWithPad border1 padd">
          <h4 className='mt-1'>{result?.section2.point2_Title}</h4>
          <p className='mt-4'>{result?.section2.point2_desc}</p>
        </div>
      </div>

      <div className="col-md-6 mt-4">
        <div className="classWithPad border1 padd">
          <h4 className='mt-1'>{result?.section2.point3_Title}</h4>
          <p className='mt-4'>{result?.section2.point3_desc}</p>
        </div>
      </div>

      <div className="col-md-6 mt-4">
        <div className="classWithPad border1 padd">
          <h4 className='mt-1'>{result?.section2.point4_Title}</h4>
          <p className='mt-4'>{result?.section2.point4_desc}</p>
        </div>
      </div>

      <div className="col-md-6 mt-4">
        <div className="classWithPad border1 padd">
          <h4 className='mt-1'>{result?.section2.point5_Title}</h4>
          <p className='mt-4'>{result?.section2.point5_desc}</p>
        </div>
      </div>

      <div className="col-md-6 mt-4">
        <div className="classWithPad border1 padd">
          <h4 className='mt-1'>{result?.section2.point6_Title}</h4>
          <p className='mt-4'>{result?.section2.point6_desc}</p>
        </div>
      </div>
    </div>
  </div>
</section>


        <section className="hire">
      <div className="container mt-5 text-center">
        <div className="row">
          <div className="col-12">
            <h3 className="hire-title">{result?.section3.title}</h3>
            <p className="hire-description mt-3">{result?.section3.description}</p>
            <div className="call-btn" onClick={navigateToContacts}>
              <button className="btn mb-5" style={{color:"var(--link-color)"}}>BOOK A CALL <i className="ri-phone-line"></i>
</button>
            </div>
          </div>
        </div>
      </div>
    </section>

        <section className="gapes">
      <div className="container py-2">
        <div className="row">
          <div className="col-md-12">
            <h4 className="text-center text-white">{result?.section4.title}</h4>
            <p className="text-center mt-3 text-white">{result?.section4.description}</p>
          </div>
        </div>
      </div>

      <div className="container py-2 ">
        <div className="row d-flex justify-content-between">
          <div className="col-md-6">
            <div className="feature-card">
              <h3 className="feature-title">{result?.section4.card_1_title}</h3>
              <p className="feature-description">{result?.section4.card_1_desc}</p>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="feature-card">
              <h3 className="feature-title">{result?.section4.card_2_title}</h3>
              <p className="feature-description">{result?.section4.card_2_desc}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

        <section className="middle-heading  ">
      <div className="container py-3 mt-1">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="Z">
              <h2 className="b-heading mt-4">{result?.section5.title}</h2>
            </div>
          </div>
          <div className="col-md-12">
            <h6 className="py-3 w-100 text-center">{result?.section5.description}</h6>
          </div>

          <div className="container-fluid midsec">
            <div className="row d-flex justify-content-between">
              <div className="col-md-6 col-lg-3 mt-4">
                <div className="padding  fact h-100">
                  <i className="ri-share-circle-line icon"></i>
                  <h5>1. {result?.section5.card1Title}</h5>
                  <p>{result?.section5.card1Description}</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 mt-4">
                <div className="padding fact h-100">
                  <i className="ri-account-pin-circle-line icon"></i>
                  <h5>2. {result?.section5.card2Title}</h5>
                  <p>{result?.section5.card2Description}</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 mt-4">
                <div className="padding fact h-100 ">
                  <i className="ri-checkbox-circle-fill icon"></i>
                  <h5>3. {result?.section5.card3Title}</h5>
                  <p>{result?.section5.card3Description}</p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 mt-4">
                <div className="padding fact h-100">
                  <i className="ri-computer-line icon"></i>
                  <h5>4. {result?.section5.card4Title}</h5>
                  <p>{result?.section5.card4Description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

        <section className="who-we-are ">
      <div className="container w-100">
        <h3 className="text-center pt-1 mb-4">{result?.section6.title}</h3>
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
              <i className="ri-checkbox-circle-line icon mb-3"></i>
              <p>{result?.section6.point1}</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
              <i className="ri-checkbox-circle-line icon mb-3"></i>
              <p>{result?.section6.point2}</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
              <i className="ri-checkbox-circle-line icon mb-3"></i>
              <p>{result?.section6.point3}</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
              <i className="ri-checkbox-circle-line icon mb-3"></i>
              <p>{result?.section6.point4}</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
              <i className="ri-checkbox-circle-line icon mb-3"></i>
              <p>{result?.section6.point5}</p>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="feature-box text-center p-4 h-100 rounded shadow-sm">
              <i className="ri-checkbox-circle-line icon mb-3"></i>
              <p>{result?.section6.point6}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

 

          {/* <section className="About">
            <div className="container">
              <div className="row">
                <h2 className="text-center  display-4 mt-5">About Us</h2>
                <p className="text-center ">
                  Crafting cutting-edge digital solution with creative mind
                </p>

              


                <div className="img-wrap">

                  <div className="container">
                    <div className="row  d-flex align-items-center justify-content-center justify-content-between">
                    <div className="col-md-3">
                  <h6>who we are</h6>
                <p className="who">A mindful team of tech innovators bringing world-class tech ides 
                  to reality. We embracethe power of technology to provide cutting-edge
                  digital solutions that propal our clients towards unprecendented success.
                </p>
                </div>
                      
                      
                      <div className="box-3 col-md-4 d-flex align-items-center justify-content-center justify-content-between ">
                      <h1 className=""> 250+</h1>
                        <div className="">
                      <img loading="lazy"   src={about} alt='about' className="img-fluid"/>
                      </div>
                      
                      </div>
                      <div className="box-2 col-md-4 d-flex align-items-center justify-content-center justify-content-between">
                      <h1 className=""> 13+ </h1>
                  

                        <div className="">
                      <img loading="lazy"   src={mit} alt='mit' className="img-fluid"/>
                      </div>

                  
                      
                      </div>
                      <div className="box-21 col-md-4 d-flex align-items-center justify-content-center justify-content-between mt-4 mb-3">
                      <h1 className=""> 143+</h1>
                        <div className="">
                      <img loading="lazy"   src={team} alt='team' className="img-fluid"/>
                      </div>
                      
                      
                      </div>
                      <div className="box-22 col-md-4 d-flex align-items-center justify-content-center justify-content-between mt-4 mb-3">
                      <h1 className=""> 13+</h1>
                        <div className="">
                      <img loading="lazy"   src={mitting} alt='meeting' className="img-fluid"/>
                      </div>
                      
                      </div>
                      
                      <div className="col-md-3">
                  <h6 className="">what drives us</h6>
                <p className="who">Creatively is our heartebeat. We constantly challenge ourseleves
                to furthur our technical prowness and help our customers experiance.
                </p>
                </div>

                      
                      
                  

                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </section> */}
      </>
   </Layout>
  )
}

export default HireDeveloper