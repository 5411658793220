import React from 'react'
import "../Styles/styles.css"
const Terms = () => {
    return (
        <>
            <h3 className='text-center'>Terms And Conditions</h3>
            <hr />
            <div className='container col-6 terms'>
                <p><strong> 1. </strong>Introduction
                    Welcome to Bytefaze Websolutions' 15-Day Internship Program. These Terms and Conditions outline the rules and regulations for participation in our program. By enrolling in the program, you agree to comply with and be bound by these Terms and Conditions.</p>
                <p><strong>2. </strong>Program Enrollment</p>
                <p><strong>2.1 </strong>To enroll in the 15-Day Internship Program, participants must complete the registration process and make the full payment of the program fee.</p>
                <p><strong>2.2</strong> Enrollment is subject to availability and acceptance by Bytefaze Websolutions.</p>
                <p><strong>3.</strong> Program Fee</p>
                <p><strong>3.1 </strong>The total fee for the 15-Day Internship Program is ₹1000, which includes registration and certification costs.</p>
                <p><strong>3.2 </strong>The program fee is due in full at the time of registration.</p>
                <p><strong>4.</strong> Non-Refundable Policy</p>
                <p><strong>4.1</strong> All fees paid for the 15-Day Internship Program are non-refundable under any circumstances.</p>
                <p><strong>4.2</strong> This non-refundable policy applies regardless of the participant's attendance, completion, or withdrawal from the program.</p>
                <p><strong>5.</strong> Program Schedule and Attendance</p>
                <p><strong>5.1</strong> The 15-Day Internship Program will commence on [Start Date] and conclude on [End Date].</p>
                <p><strong>5.2</strong> Participants are expected to attend all scheduled sessions and complete any assignments or projects as required.</p>
                <p><strong>5.3</strong> Bytefaze Websolutions reserves the right to modify the program schedule or content as necessary.</p>
                <p><strong>6.</strong> Code of Conduct</p>
                <p><strong>6.1</strong> Participants are expected to behave professionally and respectfully throughout the program.</p>
                <p><strong>6.2</strong> Any form of harassment, discrimination, or inappropriate behavior will not be tolerated and may result in immediate dismissal from the program without refund.</p>
                <p><strong>7.</strong> Intellectual Property</p>
                <p><strong>7.1 </strong>All materials, content, and resources provided during the program are the intellectual property of Bytefaze Websolutions.</p>
                <p><strong>7.2 </strong>Participants are prohibited from sharing, reproducing, or distributing any program materials without prior written consent from Bytefaze Websolutions.</p>
                <p><strong>8.1</strong> Bytefaze Websolutions is not responsible for any personal injury, loss, or damage to property during the program.
                    <p> <strong>8.</strong> Limitation of Liability
                    </p>
                </p>
                <p><strong>8.2 </strong>Participants are responsible for their own actions and conduct during the program.
                </p>
                <p><strong>9.</strong> Privacy Policy
                </p>
                <p><strong>9.1</strong> Bytefaze Websolutions will collect and process personal data in accordance with our Privacy Policy.
                </p>
                <p><strong>9.2</strong> By enrolling in the program, participants consent to the collection and use of their personal data for program-related purposes.
                </p>
                <p><strong>10.</strong> Governing Law
                </p>
                <p><strong>10.1</strong> These Terms and Conditions are governed by and construed in accordance with the laws of Gujarat, India.
                </p>
                <p><strong>10.2</strong> Any disputes arising from or in connection with these Terms and Conditions will be subject to the exclusive jurisdiction of the courts of Ahmedabad, Gujarat.
                </p>
                <p><strong>11.</strong> Amendments
                </p>
                <p><strong>11.1</strong> Bytefaze Websolutions reserves the right to amend these Terms and Conditions at any time.
                </p>
                <p><strong>11.2</strong> Participants will be notified of any changes to these Terms and Conditions via email or through our website.

                <br />
                <br /><br />
                <br />
                <hr />
                <br />
                <br />


                </p>
                <p><strong> Contact Information</strong>
                </p>
                <ul>
                    <li>For any questions or concerns regarding these Terms and Conditions, please contact us at:
                    </li>
                    <li>Bytefaze Websolutions <br />
                        B-408 Sumel8, 4th Floor, near Ajitmill, Cross Rd, Rakhial,<br />
                        Ahmedabad, Gujarat 380023, India <br />
                        Email: <a href=""> info@bytefaze.com</a><br />
                        Phone: <a href="">+91 8469717272</a></li>
                    <li>By enrolling in the 15-Day Internship Program, you acknowledge that you have read, understood, and agree to abide by these Terms and Conditions.</li>
                    <li>Enrollment Confirmation and ID Proof Submission
                        To confirm your participation and acceptance of these Terms and Conditions, please reply to this email with the following statement:</li>
                    <li>"I, [Your Full Name], agree to the Terms and Conditions of the Bytefaze Websolutions 15-Day Internship Program and understand that the fee of ₹1000 is non-refundable. Attached is a copy of my [specify type of ID] as proof of my identity."</li>
                    <li>Please attach a copy of your ID proof (Aadhaar Card, Passport, Voter ID, or Driving License) with your reply.</li>
                    <li>We look forward to having you as part of our program. If you have any questions, feel free to reach out.</li>
                    <li>Best regards,</li>
                    <li>[Your Name] <br />
                        Bytefaze Websolutions</li>
                    <li>Making the Terms and Conditions Available Online
                        Draft the Terms and Conditions Page</li>
                    <li>Create a document with the above terms and conditions. <br />
                        Convert to PDF</li>
                    <li>Save the document as a PDF file. <br />
                        Upload to Cloud Storage</li>
                    <li>Use a cloud storage service like Google Drive, Dropbox, or OneDrive to upload the PDF file. <br />
                        Share the Link</li>
                    <li>Include the link to the PDF in your email to participants so they can read the terms and conditions before replying. <br />
                        Example Email Template with Link to Terms and Conditions <br />
                        Subject: Bytefaze Websolutions 15-Day Internship Program - Terms and Conditions</li>
                    <li>Dear [Participant's Name],</li>
                    <li>Thank you for your interest in the 15-Day Internship Program at Bytefaze Websolutions. Before we proceed with your enrollment, please carefully read the Terms and Conditions available at the link below:</li>
                    <li>[Link to PDF of Terms and Conditions]
                    </li>
                    <li>To confirm your participation and acceptance of these Terms and Conditions, please reply to this email with the following statement:
                    </li>
                    <li>"I, [Your Full Name], agree to the Terms and Conditions of the Bytefaze Websolutions 15-Day Internship Program and understand that the fee of ₹1000 is non-refundable. Attached is a copy of my [specify type of ID] as proof of my identity."
                    </li>
                    <li>Please attach a copy of your ID proof (Aadhaar Card, Passport, Voter ID, or Driving License) with your reply.
                    </li>
                    <li>We look forward to having you as part of our program. If you have any questions, feel free to reach out.
                    </li>
                    <li>Best regards,
                    </li>
                    <li>[Your Name] <br />
                        Bytefaze Websolutions
                    </li>
                </ul>
                <div className='terms-check'>
                    <div>
                        <input type="checkbox" name="" id="" /> agree and continue
                    </div>
                    <div className='terms-btn'>
                        <button className='btn btn-outline-dark'> Next </button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Terms