import React from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import Layout from "../layout/Layouts.jsx";
import { EarthCanvas } from "../canvas/index.js";
import Typewriter from "typewriter-effect";
//import "../../Styles/styles.css";
import Accordian from "../homecomponents/Accordian.jsx";
import Why from "../homecomponents/Why.jsx";
import { Link } from "react-router-dom";
import idea from "../../assets/WebpImages/idea.webp";
import DreamProject from "../../assets/WebpImages/dreamproject.webp";
import Responsive from "../../assets/WebpImages/responsive.webp";
import mobile from "../../assets/WebpImages/mobile.webp";
import seo from "../../assets/WebpImages/seo.webp";
import Web from "../../assets/WebpImages/web-design.webp";
import digital from "../../assets/WebpImages/social-media.webp";
import domain from "../../assets/WebpImages/domain.webp";
import Card from "../blog/blogs/Card.jsx";
import ProjectCounter from "./ProjectCounter.jsx";
import Testimonials from "./Testimonials.jsx";
//import { LazyLoadImage } from "react-lazy-load-image-component";
// import Teastloader from "../Teastloader.jsx";

const Hero = () => {
  // const [count,setCount]=useState(0)

  return (
    <>
      <Layout
        className="cool"
        title={
          "Top Website Designing Company in Ahmedabad | Web Development Services - Bytefaze"
        }
        keywords={
          "Web Design & Development Company, IT Services, Best IT Company, Web Developer in Ahmedabad, Web Designer in Ahmedabad, Web Design Company, Website Development Services"
        }
        description={
          "Top Web Design & Development Company in Ahmedabad, Gujarat, India. Bytefaze as expert website designers have extensive experience in Website Designing, Mobile App Development, SEO, Dynamic Webpage Making"
        }
        canonical={"https://www.bytefaze.com/"}
        ldName={"Website Designing & Development"}
        ldImage={"https://bytefaze.com/static/media/best-web-designer.76aaa934964c89846462.webp"}
        serviceType={"Web Development Services"}
      >
        {/* <div className="carasole">

        <Carasole/> 
          </div> */}

        <header className="py-5 headHero">
          <div className="container px-md-5">
            <section>
              <div className=" text-center w-100">
                <h1 className="display-6 fw-bolder">
                  <span className="d-inline main-heading">
                    Best Web Designing Company in Ahmedabad
                  </span>
                </h1>
                <div className="lead">
                  <p>
                    Are you searching for a trustworthy and{" "}
                    <b>top website design company Ahmedabad?</b> Setting the
                    Standard for Professional Website Designing services with
                    Bytefaze. We are expert in Website Designing, Social Media
                    Marketing (SMO), Search Engine Optimization, React JS
                    Website Development etc.We do note just create or develop
                    websites, we weave successful digital dreams.
                  </p>
                </div>
              </div>
            </section>
            <div className="row gx-3 align-items-center">
              <div className="col-md-6">
                <div className="text-center text-xxl-start">
                   <div className="fs-2 mb-4  fw-light secondary-banner randomtext">
                    We Are&nbsp;
                    <span
                      style={{ color: "red", display: "inline-block" }}
                      className="randomtext"
                    >
                      <Typewriter
                        options={{
                          strings: [
                            "Best Web Developers",
                            "Top Website Designers",
                            "Excellent Coders",
                            "Bytefaze",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </span>
                  </div>
                  <div className="fs-6 mb-4 para-banner">
                    <div>
                      A <b>web designing  company </b>plays a crucial role in
                      creating innovative websites, E-commerce platforms, and
                      mobile applications that we see today on the internet. An
                      expert web designing company can create an impressive
                      website for your business that is both SEO-friendly and
                      responsive. Bytefaze is a &nbsp;
                      <b><Link to="/website-designing">
                        professional web designer in Ahmedabad
                      </Link></b>&nbsp;
                      that ensures your website follows the latest design and
                      functionality standards. To create a well-rounded website,
                      it is important to balance technical components like
                      <b> HTML, Javascript, and CSS </b>with using platforms
                      such as <b>React JS, Angular JS, PHP or Python </b>
                      effectively. If you are looking for a top website designer near you or the best web designers in Ahmedabad, Gujarat, you've come to the right place.
                    </div>
                  </div>
                  <div className=" gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-0  ">
                    <Link className=" me-sm-3 fs-6 fw-bolder" to="/contact-us">
                      <button className="button-24">Join Us</button>
                    </Link>
                    <Link className=" me-sm-3 fs-6 fw-bolder" to="/what-we-do">
                      <button className="button-92">Learn More</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-center align-center">
                  <div className="earth_canvas">
                    <EarthCanvas />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 mt-md-0">
            <ProjectCounter />
          </div>
          {/* <ProjectShowcase/> */}
        </header>

        {/* --------------experimental start-----------------------=========================================-- */}

        <section id="services" className="services">
          <div className="container">
            <header className="section-header">
              <h2>Our Comprehensive Website Design Services in Ahmedabad</h2>
            </header>
            <div>
              <div className="top-para mb-4">
                At Bytefaze, we provide a comprehensive suite of{" "}
                <b>website design services in Ahmedabad</b>. Our services
                include custom web design, responsive web design, e-commerce web
                design, and more. We are dedicated to helping businesses create
                an impactful online presence with our tailored website design
                services. As a <b>top web design company in Ahmedabad</b>, we
                use the latest technologies and design trends to build websites
                that are not only visually appealing but also user-friendly and
                optimized for search engines. Partner with us for all your web
                design needs and experience the difference that a professional
                web design company can make.
              </div>
            </div>
                        <header className="section-header mt-3">
              <h3 style={{ color: "var(--link-color)" }}>
                Tailored Website Designing Services for Your Business
              </h3>
            </header>
            <div className="row gy-4 mb-5">
              <div className="col-lg-4 col-md-6">
                <div className="service-box">
                  <i className="icon">
                    <img loading="lazy"  src={Web} alt="Web Desiging Ahmedabad" height={50} />
                  </i>

                  <h3>Website Designing</h3>
                  <p>
                    At Bytefaze Web Solution, a leading website design company
                    in Ahmedabad , we create websites that not only look amazing
                    but also deliver results. Our team of experienced designers
                    and developers will work closely with you to understand your
                    unique business goals and target audience.
                  </p>
                  <Link to="/website-designing" className="read-more">
                    <span>Explore Web Designing</span>{" "}
                    <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="zoom-in">
                <div className="service-box">
                  <i className=" icon">
                    <img loading="lazy" 
                      src={Responsive}
                      alt="Responsive Website Design Services in Ahmedabad"
                      height={50}
                    />
                  </i>
                  <h3>Responsive Website</h3>
                  <p>
                    Creating a <b>responsive website</b> is crucial for success.
                    Customers are our priority. Let's collaborate to ensure our
                    site meets their needs, showcasing our skills and commitment
                    to satisfaction.Ensuring a seamless experience across all
                    devices with responsive design techniques.
                  </p>
                  <Link to="/responsive-website" className="read-more mt-3">
                    <span>Explore Responsive Designing</span>{" "}
                    <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="service-box">
                  <i className=" icon">
                    <img loading="lazy"  src={domain} alt="Domain Registration" height={50} />
                  </i>
                  <h3>Domain Registration</h3>
                  <p>
                    Choosing the right domain is crucial. We prioritize
                    customers, aiming for one that mirrors our brand and appeals
                    to our target audience. Let's collaborate for digital
                    success.Providing hassle-free domain registration services
                    to establish your online territory.
                  </p>
                  <Link to="/domain-registration" className="read-more">
                    <span>Explore Domain Registration</span>{" "}
                    <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="service-box ">
                  <i className=" icon">
                    <img loading="lazy" 
                      src={mobile}
                      alt="Mobile App Development company"
                      height={50}
                    />
                  </i>
                  <h3>Application Development</h3>
                  <p>
                    Bytefaze is an Ahmedabad-based web and{" "}
                    <b>mobile app development company</b> that specializes in
                    creating high-quality software solutions. Our professional
                    development teams are skilled in a variety of technological
                    stacks, including React JS, PHP, Node JS, Phython, and many
                    more.
                  </p>
                  <Link to="/application-development" className="read-more">
                    <span>Explore App Development</span>{" "}
                    <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="zoom-out">
                <div className="service-box ">
                  <i className=" icon">
                    <img loading="lazy" 
                      src={digital}
                      alt="Digital Marketing Services"
                      height={50}
                    />
                  </i>
                  <h3> Digital Marketing</h3>
                  <p>
                    <b>Digital marketing</b> strategies are essential for
                    business success. Let's work together to engage our audience
                    through compelling content, social media, and SEO for
                    maximum online visibility. Strategizing and executing
                    digital marketing campaigns that amplify your online
                    presence and engagement.
                  </p>
                  <Link to="/digital-marketing" className="read-more mt-3">
                    <span>Explore Digital Marketing</span>{" "}
                    <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-6" data-aos="fade-up">
                <div className="service-box">
                  <i className=" icon">
                    <img loading="lazy"  src={seo} alt="SEO Company Ahmedabad" height={50} />
                  </i>
                  <h3>SEO</h3>
                  <p>
                    Bytefaze Web Solutions is a one of the{" "}
                    <b>best SEO agency in Ahmedabad</b>, Gujarat. We offer 100%
                    organic & result oriented SEO, Social Media Optimization,
                    Link Building & Digital Marketing, PPC services in market at
                    affordable prices. We are experts in optimizing your website
                    by doing in-depth research and analysis of keywords.
                  </p>
                  <Link to="/seo" className="read-more mt-3">
                    <span>Explore Seo Services</span>{" "}
                    <i className="ri-arrow-right-line"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* --------------experimental------------------------=====================================================- */}

        {/* --------------------------------------------------------------dreamproject-------------------- */}
        <div style={{ background: "var(--footer-bg)" }}  className=" " >
          <div className="container row  text-center pt-5 pb-5">

          
            <h3 className="top-para text-center">"Transform Your Online Presence with Expert Website Design"</h3>
              <div className="d-flex justify-content-center">
                    <Link to="/contact-us">
              <button className="button-85 mb-2 col-12 col-lg-12 mt-3">Contact Us Now for Free Quote</button>
              </Link>
              </div>

              </div>
            </div>
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container mt-5 ">
            <div className="row align-items-center justify-content-center ">
              <div
                className="col-lg-6 d-flex align-items-center justify-content-center"
                data-aos="fade-up"
              >
                <img loading="lazy" 
                  src={DreamProject}
                  alt="Website Design In Ahmedabad"
                  className="img-fluid mb-3"
                  width={500}
                  height={500}
                />
              </div>
              <div className="col-lg-6" data-aos="fade-up">
                <div>
                  <h2 className="top-heading">Website Designing Ahmedabad</h2>
                  <p className="top-para">
                    Bytefaze is recognized as a leading web design company in
                    Ahmedabad. Our team of skilled designers and developers is
                    committed to delivering top-notch website design services
                    that help businesses achieve their online goals. We take
                    pride in our collaborative approach, working closely with
                    our clients to understand their vision and bring it to life.
                    As a trusted web design company, we prioritize customer
                    satisfaction and strive to exceed expectations with every
                    project. Learn more about our journey and discover why
                    businesses in Ahmedabad choose Bytefaze for their website
                    design services.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/website-designing">
                      <button className="button-85 mb-2 col-12 col-lg-12 mt-3">
                        Know More : Website Designing Services
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* --------------------------------------------------------------dreamproject-------------------- */}
        <Why />

        {/* ------------------------ideas to reality------------------------------- */}
        <section className="py-3 py-md-5 py-xl-8">
          <div className="container ">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h2 className="top-heading">Best SEO Company in Ahmedabad</h2>
                  <h3 className="top-para">
                    {" "}
                    "Boost Your Digital Presence Today With Bytefaze"
                  </h3>
                  <p className="top-para">
                    At Bytefaze, we offer comprehensive SEO services in
                    Ahmedabad designed to improve your website's visibility and
                    drive more organic traffic. Our team of SEO experts utilizes
                    the latest techniques and strategies to ensure your website
                    ranks higher on search engines like Google. From keyword
                    research and on-page optimization to link building and
                    content creation, our SEO services cover all aspects of
                    search engine optimization. As a leading SEO company, we are
                    committed to helping businesses in Ahmedabad achieve their
                    online marketing goals and stay ahead of the competition.
                  </p>
                  <div className="d-flex justify-content-center ">
                    <Link to="/seo">
                      <button className="button-85 mb-2 col-12 col-lg-12 mt-3">
                        Know SEO Cost Ahmedabad
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
                <img loading="lazy" 
                  src={idea}
                  alt="Web Design Services Ahmedabad"
                  className="img-fluid mt-1"
                  width={500}
                />
              </div>
            </div>
          </div>
        </section>
        {/* Blog Section */}
        <div className="mt-5 mb-5">
          {/* <BlogCard/> */}
          <Card />
        </div>
        {/* Blog Section */}
        <section>
          <div className="container ">
            <header className="section-header">
              <h2>
                BEST WEB DEVELOPMENT INTERNSHIP & PROJECT TRAINING AHMEDABAD
              </h2>
              {/* <p > Explore our most premium services.</p> */}
            </header>
            <div>
              <div className="top-para">
                At Bytefaze, we believe in empowering individuals and helping
                them excel in their careers. That's why we offer comprehensive
                IT Courses internship training programs in PHP, React JS, Node
                JS, Laravel, UI/UX and Python. Our live training programs are
                designed to provide hands-on experience and equip you with the
                skills needed to succeed in the competitive world of
                programming. Whether you are a students, freshers or an
                experienced professional looking to upskill, our internship
                training programs are tailored to meet your needs. In addition
                to, We provide{" "}
                <Link to="/internships">
                  <b>Web Development Internship in Ahmedabad </b>
                </Link>
                that offers a complete program to prepares students to excel in
                the competitive industry of Web Designing. Our professional
                faculty members deliver Practical{" "}
                <b>Web Design Training in Ahmedabad</b> through live projects,
                ensuring that interns understand the most Latest Web Design
                Trends and layouts. Bytefaze curriculum includes crucial topics
                such as{" "}
                <b>
                  Responsive Website, Dark Mode Website Design, Ecommerce
                  Website
                </b>{" "}
                preparing interns to efficiently design websites for stunning
                look. Our strong industry connections and commitment to
                excellence result in profitable placement opportunities,
                allowing prospective Web Designer professionals to make a smooth
                transition from <b>web designing training </b>to the job market.
                Our <b>Web Designing Course in Ahmedabad</b> is the perfect for
                interns looking for a job in the quickly changing world.
              </div>
              <div className="d-flex justify-content-center ">
                <Link to="/contact-us">
                  <button className="button-85 mb-2 col-12 col-lg-12 mt-3">
                    Book for Free Demo Class
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* ------------------------ideas to reality------------------------------- */}

        {/* <div className="col-12  time_banner">
        <h1 className="display-4 fw-bold text-center">
          Get your dream project done.
        </h1>
        <p className="display-4 fw-bolder time" >
          {ctime}
        </p>
        <button
          className="figure_btn btn py-3 px-5"
          onClick={navigateToContacts}
        >
          Get in touch with us &#8594;
        </button>
      </div> */}

        {/* <Slider /> */}

        {/* <Card /> */}
        <div className="mt-5">
          <Testimonials />
        </div>
        <Accordian />
      </Layout>
    </>
  );
};

export default Hero;
