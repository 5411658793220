import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../blog.css";
import img1 from "../../../assets/WebpImages/local-grow.webp";
import img2 from '../../../assets/WebpImages/web-designing-ahmedabad.webp';
import img3 from '../../../assets/WebpImages/web-designing-australia.webp';
import img4 from '../../../assets/WebpImages/latest-trens-web-designing.webp';
import img5 from '../../../assets/WebpImages/web-design-for-seo.webp';
import img6 from '../../../assets/WebpImages/choose-web-design-company.webp';
import img7 from '../../../assets/WebpImages/web-design-ahmedabad.webp';
import img8 from '../../../assets/WebpImages/technologies-web-design.webp';
import img10 from '../../../assets/WebpImages/what-is-web-design.webp';
import img11 from '../../../assets/WebpImages/boost-website-traffic.webp';
import img12 from '../../../assets/WebpImages/best-web-designer.webp';
import img13 from '../../../assets/WebpImages/live.webp'
import img14 from '../../../assets/WebpImages/top-web-development-internship-company.webp'
import img15 from '../../../assets/WebpImages/15-days-internship.webp'
import img16 from '../../../assets/WebpImages/one-months-internship.webp'
import img17 from '../../../assets/WebpImages/45-days-internship.webp'
import img18 from '../../../assets/WebpImages/expert-web-services.webp'
import img19 from '../../../assets/WebpImages/ahmedabad-web-design.webp'
import img20 from "../../../assets/WebpImages/digital-marketing-services-for-gmb.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";

import { Link } from "react-router-dom";

const settings = {
  autoplay: true,
  autoplaySpeed: 3000,
  arrow: true,
  pauseOnFocus: false,
  pauseOnHover: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

    const data = [
        {   
            title:"Website Design Services for Local Businesses Grow Your Local Reach",
            description:"Website Design Services for Local Businesses Grow Your Local Reach",
            keywords: "Website Designing Near Me, Web Design Services, Web Design For Local Business, Web Design Ahmedabad, Web Designing Company Near Me, Affordable Web Design For Local Small Business, Small Business Website Design,",
            img: img1,
            altTag: "Grow Your Local Reach", //image Alt tag
            tag: "Grow Your Local Reach",
            label:"Grow Your Local Reach",
            date: "10 Mar 2024",
            desc: (<p className="lead">In today's digital world,
            having a strong online presence is essential for any local business.
            A well-designed website acts as your digital storefront, attracting 
            new customers and keeping existing ones engaged. Here's a breakdown 
            of<b><Link style={{color:'#5755FE'}} to="/website-designing"> website design services  </Link> </b>
            specifically tailored for local businesses:</p>),
            link:'website-design-services-for-local-businesses-grow-your-local-reach'
        },
        {
        tag: "Website Designing In Australia",
        description:"Bytefaze, a web design company located in Ahmedabad, India, extends our expertise across the globe, offering a comprehensive suite of web design services specifically tailored for clients in Australia. Despite our physical location, Our company leverages the power of remote collaboration to deliver high-quality websites for businesses in Australia's major cities  ",
        keywords:"Website Designing In Australia",
        img: img3,
        altTag: "Website Designing image", //image Alt tag 
        label:"Website Designing In Australia",
        title:"Website Designing In Australia",
        date: "04 APR 2024",
        desc: (<p className="lead">Bytefaze, a web design company based in India, caters to businesses in Australia looking for website design and development. We provide cost-effective solutions for <b>website designing in Australia</b>, including mobile-friendly websites optimized for local SEO. As web design agency we creates high-quality websites with clear contact information to resonate with Australian audiences.
         </p>),
        link:'website-designing-in-australia'
        },
        {
            title:"Embracing the Future of Web Design in Ahmedabad with Bytefaze Web Solution",
            description:"Ahmedabad businesses, elevate your online game! ByteFaze designs user-friendly websites (desktop, mobile, tablet) that convert visitors. We craft e-commerce stores, captivating portfolios, and brand-aligned websites. Our services include development, maintenance, and SEO to ensure your website thrives. Partner with ByteFaze for digital success",
            keywords: "Web Design In Ahmedabad, Website Designing in Ahmedabad, Website Designing Services, Web Designing Services, Web Designing Company in Ahmedabad",
            img: img2,
            altTag: "Web Design in Ahmedabad", //image Alt tag
            tag: "Web Design in Ahmedabad",
            label:"Web Design in Ahmedabad",
            date: "11 Apr 2024",
            desc: (<p className="lead ">In the heart of Gujarat, Ahmedabad is not just known for its rich cultural heritage but also for its burgeoning tech scene. Among the many facets of this tech expansion is the rise of web design companies that are transforming the digital landscape.</p>),
            link:"embracing-the-future-of-web-design-in-ahmedabad-with-bytefaze-web-solution"
          },
          {
            title:"Riding the Wave: Unveiling the Latest Trends in Web Design",
            description:"Unveil the latest trends in web design! ByteFaze, your website design company, explores VR/AR, microinteractions & accessibility. Learn how to stay ahead of the curve and captivate your audience. Let's ride the wave together!",
            keywords: "Latest Trend in Web Designing, Website Designing in Ahmedabad, Dark Mode Website Designing, New Design in Website Designing, Trendy Website Designing Company, Classy Web Design Services",
            img: img4,
            altTag: "Latest Trend in Web Designing", //image Alt tag
            tag: "Latest Trend in Web Designing",
            label:"Latest Trend in Web Designing",
            date: "18 Apr 2024",
            desc: (<p className="lead">The landscape of web design is ever-evolving, constantly adapting to new technologies and user preferences. If you want your website to stay ahead of the curve, here are some of the hottest trends making waves in 2024:</p>),
            link:"riding-the-wave-unveiling-the-latest-trends-in-web-design"
          },
          {
            title:"Website Designing for SEO: Attract Visitors and Rank Higher",
            description:"Tired of low website traffic? ByteFaze designs websites for SEO! We combine stunning visuals with strategic keywords, smooth user experience, and mobile-friendliness to make you shine in search results. Let's conquer search engines together! ",
            keywords: "Website Designing for SEO, SEO Freindly Web Desing, Seo Competitive Web Design, SEO Web Design Services, SEO &  Website Designing, Web Designing Company",
            img: img5,
            altTag: "Website Designing for SEO", //image Alt tag
            tag: "Website Designing for SEO",
            label:"Website Designing for SEO",
            date: "20 Apr 2024",
            desc: (<p className="lead">In today's digital world, your website is often the first impression potential customers have of your business. But simply having a website isn't enough. You need a website that's designed to be found by top search engines like Google, Bing etc. and engage visitors. That's where SEO website design comes in.</p>),
            link:"website-designing-for-seo-attract-visitors-and-rank-higher"
          },
          {
            title:"The Ultimate Guide to Choosing a Website Design Company in Ahmedabad",
            description:"Unveil the Latest Web Design Trends & Dominate SEO!  Discover how cutting-edge web design can boost your website's ranking and drive organic traffic. Learn SEO-friendly design strategies for ultimate online success.",
            keywords: "Website Designing for SEO, SEO Freindly Web Desing, Seo Competitive Web Design, SEO Web Design Services, SEO &  Website Designing, Web Designing Company",
            img: img6,
            altTag: "Guide to Choose Web Design Company", //image Alt tag
            tag: "Guide to Choose Web Design Company",
            label:"Guide to Choose Web Design Company",
            date: "26 Apr 2024",
            desc: (<p className="lead">The digital age has transformed the way businesses operate in Ahmedabad.  Websites are no longer optional – they're essential tools for attracting customers and establishing your brand online. Creating a website, however, requires expertise. That's where web design company come in, offering a helping hand to bring your vision to life.</p>),
            link:"the-ultimate-guide-to-choosing-a-website-design-company-in-ahmedabad"
          },
          {
            title:"Web Designing Ahmedabad",
            description:"Get here details about Web Designing, Web Designing Company, Professional Web Designing Services, Top Website Designing Agency, Web Designer Cost in Ahmedabad",
            keywords: "Web Designing Ahmedabad, Web Designing Company in Ahmedabad, Professional Web Designing Services, Top Website Designing Agency in Ahmedabad, Web Designer Cost in Ahmedabad",
            img: img7,
            altTag: "Web Designing Ahmedabad", //image Alt tag
            tag: "Web Designing Ahmedabad",
            label:"Web Designing Ahmedabad",
            date: "01 May 2024",
            desc: (<p className="lead">Bytefaze web solutions is leading Ahmedabad located website designing company have a vast experience of 2+ years in providing innovative web solutions in all over India. We have expertise in developing websites that come true to the all aspects of clients.</p>),
            link:"web-designing-ahmedabad"
          },
          {
            title:"Leveraging Technologies for Cutting-Edge Website Design in 2024",
            description:"Get here details about Web Designing, Web Designing Company, Professional Web Designing Services, Top Website Designing Agency, Web Designer Cost in Ahmedabad",
            keywords: "Web Designing Ahmedabad, Web Designing Company in Ahmedabad, Professional Web Designing Services, Top Website Designing Agency in Ahmedabad, Web Designer Cost in Ahmedabad",
            img: img8,
            altTag: "Technologies for Website Design 2024", //image Alt tag
            tag: "Technologies for Website Design 2024",
            label:"Technologies for Website Design 2024",
            date: "04 May 2024",
            desc: (<p className="lead">Web designers, this blog dives into hot tech shaping web design! Generative AI creates layouts, the Metaverse offers 3D experiences, and voice search demands optimization. Embrace these trends for future-proof websites!
            </p>),
            link:"leveraging-technologies-for-cutting-edge-website-design-in-2024"
          },
          {
            title:"What do you mean by website design?",
            description:"Confused by website design & development? ByteFaze Web Solutions (Ahmedabad) clears it up!  This blog dives into web design basics: crafting user-friendly websites that look great & convert visitors into customers. Learn how a professional website design can elevate your business online! ",
            keywords: "Website Designing, Website Design, Web Design, Web Development, Web Programming, Web Designing Company Ahmedabad",
            img: img10,
            altTag: "What is Website Design?", //image Alt tag
            tag: "What is Website Design?",
            label:"What is Website Design?",
            date: "11 May 2024",
            desc: (<p className="lead">Have you ever landed on a website that felt clunky and confusing, or perhaps one that looked outdated and unappealing? Conversely, have you encountered websites that are not only visually stunning but also intuitive and easy to navigate? These contrasting experiences highlight the importance of website design, a crucial aspect of creating a successful online presence.
            </p>),
            link:"what-is-website-design"
          },
          {
            title:"Stop Traffic Woes! Boost Your Ahmedabad Website with ByteFaze",
            description:"Struggling with low website traffic in Ahmedabad? ByteFaze Web Solutions can help! Our expert web design & SEO services increase traffic, leads & conversions. Get a free quote today! ",
            keywords: "Website Designing, Website Design, Web Design, Web Development, Web Programming, Web Designing Company Ahmedabad",
            img: img11,
            altTag: "Boost Ahmedabad Website Traffic", //image Alt tag
            tag: "Boost Ahmedabad Website Traffic",
            label:"Boost Ahmedabad Website Traffic",
            date: "14 May 2024",
            desc: (<p className="lead">Conquer low website traffic with our award-winning responsive web design and development. We prioritize user experience, optimize for mobile, and craft strategic SEO plans to get your Ahmedabad business seen online.
            </p>),
            link:"boost-website-traffic-ahmedabad"
          },
          {
            title:"Crafting a Winning Web Presence with Bytefaze - Best Website Designer in Ahmedabad",
            description:"Find here best Web Designer firm in Ahmedabad, bytefaze is Award Winning Company having expertise in Website Designing & Development as per clients vision and requirements. ",
            keywords: "Website Designing, Website Design, Web Design, Web Development, Web Programming, Web Designing Company Ahmedabad",
            img: img12,
            altTag: "Best Web Designer Ahmedabad", //image Alt tag
            tag: "Best Web Designer Ahmedabad",
            label:"Best Web Designer Ahmedabad",
            date: "22 May 2024",
            desc: (<p className="lead">In today's digital age, a website is no longer a luxury - it's a necessity. For businesses in Ahmedabad, the competition for online dominance is fierce. This is where ByteFaze Web Solutions steps in, a rising star in Ahmedabad's web design landscape. But what makes us the best choice for clients business? Let's delve deeper and discover why we deserves to be your trusted web design partner in Ahmedabad.
            </p>),
            link:"best-web-designer-ahmedabad"
          },
          {
            title:"Live Project Training in Ahmedabad for Final Year Students",
            description:"Bytefaze offer Live Project Training in Ahmedabad,  Free demo Internship for MCA, BCA, BE, B Tech, MSC IT, BSC IT, MBA students with 100% Job placement - Call for Free Demo ",
            keywords: "Live Project Training, IT Training, Final Year Project Training In Ahmedabad, Free Live Project Training In Ahmedabad, Free Demo Internship",
            img: img13,
            altTag: "Live Project Training in Ahmedabad", //image Alt tag
            tag: "Live Project Training in Ahmedabad",
            label:"Live Project Training in Ahmedabad",
            date: "6 June 2024",
            desc: (<p className="lead">Empower yourself with the practical skills and experience to become a sought-after web developer in Ahmedabad. ByteFaze Web Solutions offers comprehensive Live Project Training programs designed to equip you with the latest technologies used in the industry.
            </p>),
            link:"live-project-training-ahmedabad"
          },
          {
            title:"Launch Your Tech Career: Bytefaze Summer Internship & Training Program",
            description:"Final year IT students in Ahmedabad! Get a head start in web design & development with Bytefaze's Summer Internship (3 months + 2-week training). Learn top technologies & work on real projects! Apply Now! ",
            keywords: "3 Months Summer Internship, Final Year Students Internship, Web Development Internship, 15 Days Summer INternship, Summer Training for IT Students",
            img: img13,
            altTag: "Summer Internship & Training Program", //image Alt tag
            tag: "Summer Internship & Training Program",
            label:"Summer Internship & Training Program",
            date: "7 June 2024",
            desc: (<p className="lead">Calling all IT-enthusiastic final year students in Ahmedabad! Are you looking to gain hands-on experience in web design and development and get a head start in your tech career? Look no further than Bytefaze, a leading web development company, and their exciting Summer Internship & Training Program!
            </p>),
            link:"summer-internship-and-training-in-ahmedabad"
          },
          {
            title:"5 Skills You'll Master During a Bytefaze Web Development Internship",
            description:"Aspiring Top Internship Company for Web Development ? Bytefaze (Ahmedabad) equips you with 5 in-demand skills & a stellar portfolio. Launch your tech career today.",
            keywords: "Top Internship Company for Web Development, Best IT Company for Internship, Best Web Development Internship Company",
            img: img14,
            altTag: "Top Internship Company for Web Development", //image Alt tag
            tag: "Top Internship Company for Web Development",
            label:"Top Internship Company for Web Development",
            date: "11 June 2024",
            desc: (<p className="lead">Are you looking to launch your career as a web developer? Bytefaze, a top internship company for web development in Ahmedabad, offers exceptional programs designed to equip you with the skills and experience you need to succeed. Whether you're a complete beginner or a student seeking to bridge the gap between theory and practice, Bytefaze provides the perfect platform to hone your talents.
            </p>),
            link:"top-internship-company-for-web-development-ahmedabad"
          },
          {
            title:"15 Days Internship & Training Program For Students in Ahmedabad",
            description:"Bytefaze - Free 15 Day Web Design Internship in Ahmedabad. Gain skills, build portfolio, earn certificate online. Perfect for collage students & web design enthusiasts. Whatsapp now!",
            keywords: "15 Days Internship, 15 days Internship with certificate, 15 Days Internship in ahmedabad, online internship for 15 days, 15 days internship for collage students, 15 days internship for students, free 15 days internship in ahmedabad, 15 day internship near me, Free Online Internships for Students",
            img: img15,
            altTag: "15 Days Intenship in Ahmedabad", //image Alt tag
            tag: "15 Days Intenship in Ahmedabad",
            label:"15 Days Intenship in Ahmedabad",
            date: "14 June 2024",
            desc: (<p className="lead">Looking for a 15 day internship with a certificate to jumpstart your web design career? ByteFaze, a leading web design company in Ahmedabad, is offering a FREE online internship designed for passionate college students!
            </p>),
            link:"15-days-internship-in-ahmedabad"
          },
          {
            title:"One Months Internship & Training Program For Students in Ahmedabad",
            description:"Ahmedabad Students! Free  One Month Web Design Internship (Online & offline) with Certificate at ByteFaze. Apply on WhatsApp!",
            keywords: "One Month Internship, 1 Months Web Design Internship, One Month Internship With Certificate, Online Internship For One Months, 1 Months Internship For Collage Students, One Months Web Development Internship For students, One Month Internship In Ahmedabad, 1 Month Internship Near Me, Free Online Internships For Students",
            img: img16,
            altTag: "One Months Intenship in Ahmedabad", //image Alt tag
            tag: "One Months Intenship in Ahmedabad",
            label:"One Months Intenship in Ahmedabad",
            date: "14 June 2024",
            desc: (<p className="lead">Aspiring web designer seeking a head start in the dynamic world of web design? Look no further than ByteFaze, a leading web design and development company in Ahmedabad! We're offering an exciting 1 Month Internship Program that equips you with the skills and practical experience to impress future employers.
            </p>),
            link:"one-month-internship-in-ahmedabad"
          },
          {
            title:"45 Days Summer Internship & Training in Ahmedabad",
            description:"Last-semester BE, BTech, MCA, BCA students? Launch your web developer career with Bytefaze's 45-day internship in Ahmedabad! Gain hands-on experience, build a portfolio, & explore diverse specializations. Apply now!",
            keywords: "45 Days Internship, 45 days Internship with certificate, 45 Days Internship in ahmedabad, online internship for 45 days, 45 days internship for collage students, 45 days internship for students, free 45 days internship in ahmedabad, 45 day internship near me, Free Online Internships for Students",
            img: img17,
            altTag: "45 Days Intenship in Ahmedabad", //image Alt tag
            tag: "45 Days Intenship in Ahmedabad",
            label:"45 Days Intenship in Ahmedabad",
            date: "18 June 2024",
            desc: (<p className="lead">Are you a highly motivated BE, BTech, MCA, BCA, or MSc IT student in your final semester, eager to gain practical experience in the dynamic field of web development? Bytefaze, a leading website designing and development company in Ahmedabad, is proud to offer a comprehensive 45-day internship program designed to equip you with the in-demand skills and knowledge necessary to excel in the industry.
            </p>),
            link:"45-days-internship-in-ahmedabad"
          },
          {
            title:"Expert Website Designing Services - ByteFaze Ahmedabad, Gujarat, India",
            description:"Looking for affordable and professional website designing services in Ahmedabad? ByteFaze offers customized web design solutions to enhance your online presence, create stunning responsive websites, and drive business growth. Let our expert designers help your business stand out online",
            keywords: "Expert Web Design Services, Website Design Services in Ahmedabad, Web Design Company in Ahmedabad, Website designing services, Professional web design, Affordable website design, Ahmedabad web design, Customized web design solutions, Responsive website design, Expert web designers,  Business growth online, ByteFaze web Solutions, Top web design services",
            img: img18,
            altTag: "Website Designing Services in Ahmedabad", //image Alt tag
            tag: "Website Designing Services in Ahmedabad",
            label:"Website Designing Services in Ahmedabad",
            date: "21 June 2024",
            desc: (<p className="lead">At ByteFaze, a premier website designing company in Ahmedabad, we specialize in offering customized website designing services. Each project begins with a thorough consultation to understand your unique requirements and business goals. Our team crafts bespoke web designs that reflect your brand’s identity and engage your target audience effectively.
            </p>),
            link:"expert-website-designing-services-in-ahmedabad"
          },
          {
            title:"Top 5 Reasons Why Ahmedabad Businesses Need a Strong Website in 2024",
            description:"Strengthen your Ahmedabad Website Design ! Discover 5 reasons why a powerful website is crucial for business success in 2024. Consult Bytefaze today!",
            keywords: "Ahmedabad Web Design, Local SEO, Attract New Customers, website for business Ahmedabad, Enhance Brand Credibility, professional website Maker, website with relevant keywords",
            img: img19,
            altTag: "Strong Website Design in 2024", //image Alt tag
            tag: "Why Need Strong Web Design",
            label:"Strong Website Design in 2024",
            date: "06 July 2024",
            desc: (<p className="lead">At ByteFaze, a premier website designing company in Ahmedabad, we specialize in offering customized website designing services. Each project begins with a thorough consultation to understand your unique requirements and business goals. Our team crafts bespoke web designs that reflect your brand’s identity and engage your target audience effectively.
            </p>),
            link:"ahmedabad-web-design-2024"
          },
          {
            title:"Digital Marketing Services for Optimizing Google My Business (GMB)",
            description:"Optimize your Google My Business profile with Bytefaze Web Solutions. Enhance local visibility, attract more customers, and manage your online reputation effectively with our comprehensive GMB optimization services",
            keywords: "Google My Business optimization, GMB services, Local SEO, /Business profile setup, Online reputation management, Local  search rankings, Digital marketing services, GMB posts, Customer reviews management, High-quality business photos, Local SEO optimization, Business insights and analytics, GMB profile verification Services Provider, Enhance local visibility, Attract more customers",
            img: img20,
            altTag: "GMB Optimization Services", //image Alt tag
            tag: "GMB Optimization Services",
            label:"GMB Optimization Services",
            date: "02 Aug 2024",
            desc: (<p className="lead">In today’s digital age, having a strong online presence is crucial for businesses of all sizes. Google My Business (GMB) is a powerful tool that allows you to manage how your business appears on Google Search and Maps. At Bytefaze Web Solutions, we offer comprehensive digital marketing services to optimize your GMB listing, ensuring you stand out from the competition and attract more customers.
            </p>),
            link:"digital-marketing-services-for-optimizing-gmb"
          }

]

const Card = () => {
  return (
    <div className="container overflow-hidden px-4">
      <div className="slider-container">
        <h2
          className="mb-4 text-center display-4 "
          style={{ color: "var(--primary-color)" }}
        >
          Our Blogs
        </h2>
        <Slider {...settings}>
{data && data.map((el,i)=>(
     <div key={i} className="card border-0 bg-transparent">
     <figure className="card-img-top mb-4 overflow-hidden bsb-overlay-hover">
       <Link to={`/blog/${el.link}`}>
         <img loading="lazy" 
           className="img-fluid bsb-scale bsb-hover-scale-up"
           src={el.img}
           alt={el.altTag}
           style={{height:300,width:400}}
         />
       </Link>
       <figcaption>
         <svg
           xmlns="http://www.w3.org/2000/svg"
           width="32"
           height="32"
           fill="currentColor"
           className="bi bi-eye  bsb-hover-fadeInLeft"
           viewBox="0 0 16 16"
         >
           <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
           <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
         </svg>
         <Link to={`/blog/${el.link}`}>
           <h4 className="h6  bsb-hover-fadeInRight mt-2">Read More</h4>
         </Link>
       </figcaption>
     </figure>
     <div className="card-body m-0 p-0">
       <div className="entry-header mb-3">
         <ul className="entry-meta list-unstyled d-flex mb-3">
           <li>
             <Link
               className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
               to={`/blog/${el.link}`}
             >
              {el.tag}
             </Link>
           </li>
         </ul>
         <h2 className="card-title entry-title h4 m-0">
           <p className=" text-decoration-none">
            {el.title}
           </p>
         </h2>
         <p className="mt-2">
           {el.desc}
           <Link style={{ color: "#5755FE" }} to={`/blog/${el.link}`}>
             Know More
           </Link>
         </p>
       </div>
     </div>
     <div className="card-footer border-0 bg-transparent p-0 m-0">
       <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
         <li>
           <a
             className="fs-7  text-decoration-none d-flex align-items-center"
             href="#"
           >
             <svg
               xmlns="http:www.w3.org/2000/svg"
               width="14"
               height="14"
               fill="currentColor"
               className="bi bi-calendar3"
               viewBox="0 0 16 16"
             >
               <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
               <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
             </svg>
             <span className="blog-date ms-2 fs-7">{el.date}</span>
           </a>
         </li>
       </ul>
     </div>
   </div>
))}
         

    

        </Slider>
      </div>
    </div>
  );
};

export default React.memo(Card);
