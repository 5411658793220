
import React from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
//import "../../Styles/styles.css";
import Layout from "../layout/Layouts";
import WD from "../../assets/WebpImages/webs-design.webp";
import RD from "../../assets/WebpImages/RD.webp";
import EC from "../../assets/WebpImages/EC.webp";
import DR from "../../assets/WebpImages/DR.webp";
import MD from "../../assets/WebpImages/MD.webp";
import CP from "../../assets/WebpImages/CP.webp";
import WH from "../../assets/WebpImages/WH.webp";
import DM from "../../assets/WebpImages/DM.webp";
import LD from "../../assets/WebpImages/LD.webp";
import SEO1 from "../../assets/WebpImages/SEO1.webp";
// import cool from "../../assets/WebpImages/cool.jpg";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <Layout
      title={"Paid Intership Company in Ahmedabad | Website Design India | SEO"}
      keywords={
        "paid Internship Company Ahmedabad, Website Design India, SEO Company Ahmedabad, Website Design Company"
      }
      description={
        "Crafting digital excellence with bytefaze, Be the part of our company through Website design, SEO, Paid Internship, Web Development Services. Explore now!"
      }
      canonical={"https://www.bytefaze.com/what-we-do"}
    >
      <div className="col-12 service_bg">
        <h1 className="display-3 fw-bold">Our Services</h1>
      </div>

      <section className="py-3 py-md-5 py-xl-8">
        <div className="container mt-5 mb-5">
          <div className="row align-items-center justify-content-center ">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
             <img loading="lazy"  
                src={WD}
                alt="Website Desigining Services"
                className="img-fluid mb-3"
                width={700}
              />
            </div>
            <div className="col-lg-6 ">
              <div>
                <h2 className="top-heading">Website Designing</h2>
                <p className="top-para">
                  Crafting a captivating website is paramount to our business.
                  Our customers are the core of our existence. Let's collaborate
                  to ensure our site reflects their importance and showcases our
                  development expertise. Together, we'll create an engaging
                  digital experience that leaves a lasting impression.
                </p>
                <Link to="/website-designing">
                  <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "var(--footer-bg)" }}>
          <div className="container mt-5 mb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h2 className="top-heading">Responsive Website</h2>
                  <p className="top-para">
                    Ensuring a responsive website is vital for our success. Our
                    customers remain central to our business. Let's work
                    together to guarantee our site adapts seamlessly to their
                    needs, showcasing our development prowess. Together, we'll
                    craft an engaging digital platform that reflects our
                    commitment to customer satisfaction.
                  </p>
                  <Link to="/responsive-website">
                    <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
               <img loading="lazy"  
                  src={RD}
                  alt="Responsive Website Design"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center justify-content-center mt-5">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
             <img loading="lazy"  
                src={EC}
                alt="E-Commerce Website Design Company"
                className="img-fluid mt-1"
                width={500}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <div>
                <h2 className="top-heading">E-Commerce Website</h2>
                <p className="top-para">
                  Crafting an effective e-commerce website is essential for our
                  business. Our customers are our top priority. Let's
                  collaborate to create a dynamic platform that showcases our
                  products and services, while prioritizing user experience and
                  satisfaction. Together, we'll build an engaging online store
                  that drives sales and fosters customer loyalty.
                </p>
                <Link to="/ecommerce-website">
                  <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "var(--footer-bg)" }}>
          <div className="container mt-5 mb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h2 className="top-heading"> Domain Registration</h2>
                  <p className="top-para">
                    Securing the right domain is pivotal for our online
                    presence. Our customers are at the heart of everything we
                    do. Let's ensure we register a domain that reflects our
                    brand identity and resonates with our target audience.
                    Together, we'll select a memorable and relevant domain name
                    that sets the foundation for our digital success.
                  </p>
                  <Link to="/domain-registration">
                    <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
               <img loading="lazy"  
                  src={DR}
                  alt="Domain Registration Service"
                  className="img-fluid mt-1"
                  width={500}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center justify-content-center mt-5">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
             <img loading="lazy"  
                src={MD}
                alt="Mobile Application Development"
                className="img-fluid mt-1"
                width={400}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <div>
                <h2 className="top-heading"> Application Development</h2>
                <p className="top-para">
                  Developing innovative applications is key to our business
                  strategy. Our customers are our driving force. Let's
                  collaborate to build cutting-edge solutions that cater to
                  their needs and exceed their expectations. Together, we'll
                  create user-friendly applications that enhance their
                  experience and propel our business forward.
                </p>
                <Link to="/application-development">
                  <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "var(--footer-bg)" }}>
          <div className="container mt-5 mb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h2 className="top-heading"> Corporate Presentation</h2>
                  <p className="top-para">
                    Crafting a compelling corporate presentation is essential
                    for our brand. Our customers remain our top priority. Let's
                    collaborate to design a dynamic presentation that
                    effectively communicates our values, achievements, and
                    offerings. Together, we'll create a visually stunning and
                    informative presentation that captivates our audience and
                    reinforces our commitment to excellence.
                  </p>
                  <Link to="/corporate-presentation">
                    <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
               <img loading="lazy"  
                  src={CP}
                  alt="Corporate Presentation Service"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center justify-content-center mt-5">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
             <img loading="lazy"  
                src={WH}
                alt="Web Hosting Services"
                className="img-fluid mt-1"
                width={600}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <div>
                <h2 className="top-heading">Web Hosting</h2>
                <p className="top-para">
                  Selecting reliable web hosting is crucial for our online
                  presence. Our customers are central to our business. Let's
                  ensure we choose a hosting service that guarantees optimal
                  performance and uptime, providing a seamless experience for
                  our visitors. Together, we'll secure a robust hosting solution
                  that supports our growth and reflects our commitment to
                  customer satisfaction.
                </p>
                <Link to="/web-hosting">
                  <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "var(--footer-bg)" }}>
          <div className="container mt-5 mb-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h2 className="top-heading">Digital Marketing</h2>
                  <p className="top-para">
                    Implementing effective digital marketing strategies is
                    imperative for our business success. Our customers are our
                    primary focus. Let's collaborate to develop dynamic
                    campaigns that engage our target audience across various
                    online channels. Together, we'll create compelling content,
                    leverage social media platforms, and optimize SEO to
                    maximize our online visibility and drive meaningful
                    engagement with our brand.
                  </p>
                  <Link to="/digital-marketing">
                    <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
               <img loading="lazy"  
                  src={DM}
                  alt="Digital Marketing Services"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center justify-content-center mt-5">
            <div className="col-lg-6 d-flex align-items-center justify-content-center">
             <img loading="lazy"  
                src={LD}
                alt="Logo Designing Services"
                className="img-fluid mt-1"
                width={600}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <div>
                <h2 className="top-heading"> Logo Designing</h2>
                <p className="top-para">
                  Crafting a standout logo is essential for our brand identity.
                  Our customers are at the core of our business. Let's
                  collaborate to design a memorable logo that resonates with our
                  target audience and reflects our values. Together, we'll
                  create a visually appealing and distinctive logo that sets us
                  apart and leaves a lasting impression on our customers.
                </p>
                <Link to="/logo-designing">
                  <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                    Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{ background: "var(--footer-bg)" }}>
          <div className="container mt-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1 mt-2">
                <div>
                  <h2 className="top-heading">Search Engine Optimization (SEO)</h2>
                  <p className="top-para">
                    Implementing effective SEO strategies is vital for our
                    online visibility. Our customers are the cornerstone of our
                    business. Let's collaborate to optimize our website content
                    and structure to rank higher in search engine results,
                    ensuring that our target audience can easily find us.
                    Together, we'll enhance our SEO efforts to attract more
                    organic traffic and maximize our digital presence,
                    ultimately driving growth and success for our business.
                  </p>
                  <Link to="/seo">
                    <button className="button-85 mb-2 col-12 col-lg-6 mt-3">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center">
               <img loading="lazy"  
                  src={SEO1}
                  alt="SEO Services Ahmedabad"
                  className="img-fluid mt-1"
                  width={600}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Services;