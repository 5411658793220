import React from "react";
import Layout from "../layout/Layouts";
import "./blog.css";
import img1 from "../../assets/WebpImages/about-img1.webp";
// import BlogCard from './BlogCard'
import Card from "./blogs/Card";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Blog = () => {
  return (
    <Layout 
    title={"Website Development & Designing From Scratch"} 
    //keywords={"Digital Marketing Company in Ahmedabad, Digital Marketing Agency in Ahmedabad, Digital Marketing, Digital Marketing Services in Ahmedabad, Internet Marketing Agency, 360o Digital Marketing, Digital Experts in Ahmedabad, Digital Consultant in Ahmedabad, Digital Marketing Firms in Ahmedabad, Digital Data Driven Agency "} 
    description={"How to Develop Website From Scratch ? Learn Web Development With Bytefaze is a Top IT Company in Ahmedabad offers fanstastic Web Designing Services"}
    canonical={"https://www.bytefaze.com/blog"}
    ldName={"Website Development From Scratch"}
    ldImage={"https://www.bytefaze.com/static/media/about-img1.6d5c57e0fdfca7d68e90.webp"}>
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <article>
            <div className="card ">
              <div className="row g-0">
                <div className="col-12 col-md-6 order-1 order-md-0 d-flex align-items-center">
                  <div className="card-body p-md-4 p-xl-6 p-xxl-9">
                    <div className="entry-header mb-3">
                      <ul className="entry-meta list-unstyled d-flex mb-4">
                        <li>
                          <a
                            className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                            href="#"
                          >
                            Entrepreneurship
                          </a>
                        </li>
                      </ul>
                      <h1 className="card-title entry-title display-3 fw-bold mb-4 lh-1">
                        How to Design and Develop Websites from Scratch
                      </h1>
                    </div>
                    <p className="card-text entry-summary  mb-4">
                      Creating a successful websites from scratch is the stuff
                      that entrepreneurial dreams are made of, but it's a
                      journey that's equal parts challenging and thrilling.
                    </p>
                    <div className="entry-footer">
                      <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                        <li>
                          <a
                            className="fs-7  text-decoration-none d-flex align-items-center"
                            href="#"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="currentColor"
                              className="bi bi-calendar3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                              <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                            </svg>
                            <span className="ms-2 fs-7">13 Apr 2023</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 d-flex">
                  <img loading="lazy" 
                    className="img-fluid rounded-end object-fit-cover"
                    src={img1}
                    alt="Website Designing Company Ahmedabad"
                  />
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
      <main id="main">
        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1">
          {/* <div className="container overflow-hidden">
    <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}

          {/* <BlogCard/> */}
          <Card />
          {/* </div>
  </div> */}

          {/* <div className="container">
    <div className="row">
      <div className="col text-center">
        <a href="/#" className="btn bsb-btn-2xl border rounded-pill mt-5 mt-xl-10">All Articles</a>
      </div>
    </div>
  </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2 className="card-title display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis">
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"                     
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Blog;
