import React from "react";
import Layout from "../../layout/Layouts";
import img1 from "../../../assets/WebpImages/web-design-for-seo.webp";
import { Link } from "react-router-dom";
import Card from "./Card";
import logo from "../../../assets/WebpImages/bytedark.webp";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const WebdesignForSEO = () => {
  const data = {
    tag: "Website Designing for SEO",
    description:
      "Unveil the Latest Web Design Trends & Dominate SEO! Discover how cutting-edge web design can boost your website's ranking and drive organic traffic. Learn SEO-friendly design strategies for ultimate online success.",
    keywords:
      "Website Designing for SEO, SEO Freindly Web Desing, Seo Competitive Web Design, SEO Web Design Services, SEO &  Website Designing, Web Designing Company ",
    img: img1,
    altTag: "Website Designing for SEO", //image Alt tag
    label: "Website Designing for SEO",
    title: "Website Designing for SEO | Web Designer Ahmedabad",
    PageTitle: "Website Designing for SEO: Attract Visitors and Rank Higher",
    date: "20 APR 2024",
    desc: (
      <p className="lead fw-bold">
        In today's digital world, your website is often the first impression
        potential customers have of your business. But simply having a website
        isn't enough. You need a website that's designed to be found by top
        search engines like Google, Bing etc. and engage visitors. That's where
        SEO website design comes in.
      </p>
    ),
    img2: img1,
    points: (
      <>
        <div className="container w-75">
          <div className="row  item-align-center col-md-12 text-center">
            <table className="table border">
              <thead>
                <tr>
                  <th style={{ color: "var(--link-color)" }}>
                    Table of Contents
                  </th>
                </tr>
              </thead>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#website-designing-for-seo">
                    Website Designing for SEO
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#what-is-seo-website-design">
                    What is SEO Website Design?
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#importance-of-seo-website-design">
                    Why is SEO Website Design Important
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#key-elements-website-design-for-seo">
                    Key Elements of Design Website for SEO Purpose
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#benefits-of-cms-seo-website-design">
                    Benefits of a CMS for SEO Website Design
                  </a>
                </td>
              </tr>
              <tr>
                <td className="pt-1 pb-1">
                  <a href="#tracking-seo-progress">
                    Tracking Your SEO Progress and Making Adjustments
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
        {/* point1 */}
        <div className="top-para">
        <h2
          style={{ color: "var(--link-color)" }}
          id="website-designing-for-seo"
        >
          Website Designing for SEO: Attract Visitors and Rank Higher
        </h2>
        <p>
          In today's digital world, your website is often the first impression
          potential customers have of your business. But simply having a website
          isn't enough. You need a website that's designed to be found by top
          search engines like Google, Bing etc. and engage visitors. That's
          where <b>SEO website design</b> comes in.
        </p>
       

        {/* point2 */}

             <h2
          style={{ color: "var(--link-color)" }}
          id="what-is-seo-website-design"
        >
          What is SEO Website Design?
        </h2>
        <p>
          <Link style={{ color: "blue" }} to="/">
            <b>website design for SEO</b>
          </Link>{" "}
          is the practice of creating websites that are not only visually
          appealing and user-friendly but also optimized for search engines like
          Google. This means incorporating elements that search engines consider
          important when ranking websites in search results.
        </p>
        {/* point3*/}
        <h2
          style={{ color: "var(--link-color)" }}
          id="importance-of-seo-website-design"
        >
          Why is SEO Website Design Important?
        </h2>
        <p>
          There are several key reasons why SEO Friendly website design is
          important:
        </p>
        <ul >
          <li>
            <b>Increased Organic Traffic:</b> A well-designed website for SEO
            will be easier for search engines to crawl and index, making it more
            likely to appear in search results for relevant keywords. This can
            lead to a significant increase in organic traffic, which is free
            traffic from people actively searching for what you offer.
          </li>
          <li>
            <b>Improved User Experience (UX):</b> SEO and UX (user experience)
            often go hand-in-hand. A website that is easy for search engines to
            navigate is usually also easy for users to navigate. This means
            visitors are more likely to find the information they're looking for
            and stay on your site longer.
          </li>
          <li>
            <b>Improved User Experience (UX):</b> SEO and UX (user experience)
            often go hand-in-hand. A website that is easy for search engines to
            navigate is usually also easy for users to navigate. This means
            visitors are more likely to find the information they're looking for
            and stay on your site longer.
          </li>
          <li>
            <b>Brand Credibility:</b> A website that is professional and
            well-designed can help to improve your brand credibility and make
            you appear more trustworthy to potential customers.
          </li>
        </ul>
        {/* point4*/}
        <h2
          style={{ color: "var(--link-color)" }}
          id="key-elements-website-design-for-seo"
        >
          Key Elements of Design Website for SEO Purpose
        </h2>
        <p className="top-para">
          Here are some of the key elements to consider when designing a website
          for SEO:
        </p>
        <ul className="top-para">
          <li>
            <b>Keyword Research:</b> The first step is to identify the keywords
            that your target audience is searching for. You can then use these
            keywords throughout your website content, including titles, meta
            descriptions, headings, and image alt tags.
          </li>
          <li>
            <b>Clear Website Structure:</b> Your website should have a clear and
            logical structure that makes it easy for both users and search
            engines to navigate. This includes having a well-defined sitemap and
            using internal linking to connect relevant pages.
          </li>
          <li>
            <b>Mobile-Friendly Design:</b> In today's mobile-first world, it's
            essential to have a website that is responsive and looks good on all
            devices, including smartphones and tablets.
          </li>
          <li>
            <b>Fast Loading Speed:</b> Website speed is a major ranking factor
            for search engines. Make sure your website loads quickly to avoid
            frustrating users and hurting your SEO.
          </li>
          <li>
            <b>High-Quality Content:</b> Your website should contain
            high-quality, informative, and engaging content that is relevant to
            your target audience and optimized for your chosen keywords.
          </li>
          <li>
            <b>Image Optimization:</b> Images can be a great way to break up
            text and make your website more visually appealing. However, it's
            important to optimize your images for SEO by using descriptive file
            names and alt tags.
          </li>
        </ul>
        {/* point5*/}
        <h2
          style={{ color: "var(--link-color)" }}
          id="benefits-of-cms-seo-website-design"
        >
          Benefits of a CMS for SEO Website Design
        </h2>
        <p>
          Using a content management system (CMS) can offer several advantages
          for SEO website design:
        </p>
        <ul>
          <li>
            <b>Ease of Use:</b> A CMS typically offers a user-friendly interface
            that allows you to create and update content without needing
            extensive coding knowledge. This makes it easier to keep your
            website fresh with new content, which is important for SEO.
          </li>
          <li>
            <b>SEO-Friendly Features:</b> Many CMS platforms come with built-in
            SEO features, such as the ability to optimize titles, meta
            descriptions, and URLs. They may also offer plugins or extensions
            that can further enhance your SEO efforts.
          </li>
          <li>
            <b>Mobile-Responsive Templates:</b> Most modern CMS platforms offer
            mobile-responsive templates that ensure your website looks good and
            functions well on all devices.
          </li>
          <li>
            <b>Mobile-Responsive Templates:</b> Most modern CMS platforms offer
            mobile-responsive templates that ensure your website looks good and
            functions well on all devices.
          </li>
        </ul>
        {/* point6*/}
        <h2 style={{ color: "var(--link-color)" }} id="tracking-seo-progress">
          Tracking Your SEO Progress and Making Adjustments
        </h2>
        <p className="top-para">
          Once you've launched your <b>SEO website design</b>, it's important to
          track your progress and make adjustments as needed. Here are a few
          ways to do that:
        </p>
        <ul className="top-para">
          <li>
            <b>Monitor Your Website Traffic:</b> Use analytics tools like Google
            Analytics to track your website traffic and see how your SEO efforts
            are performing. Pay attention to metrics like organic traffic,
            keyword rankings, and bounce rate.
          </li>
          <li>
            <b>Track Your Keyword Rankings:</b> There are a number of tools
            available that can help you track your website's ranking for
            specific keywords. This will help you see which keywords you're
            ranking well for and which ones need improvement.
          </li>
          <li>
            <b>Use SEO Audit Tools:</b> There are a number of SEO audit tools
            available that can help you identify areas of your website that can
            be improved for SEO. These tools can crawl your website and identify
            issues such as broken links, missing alt tags, and slow loading
            times.
          </li>
        </ul>
        <p>
          By regularly monitoring your SEO progress and making adjustments as
          needed
        </p>
        </div>
      </>
    ),
    lastp:
      "By following these tips, you can develop a website that is not only beautiful and user-friendly but also optimized for search engines. This will help you attract more visitors, improve your brand credibility, and achieve your business goals.",

    // "In today's digital world, having a strong online presence is essential for any local business. A well-designed website acts as your digital storefront, attracting new customers and keeping existing ones engaged. Here's a breakdown of website design services specifically tailored for local businesses:"

    Fieldtags: (
      <>
        <div className="container mt-1">
          <div className="row">
            <div className="col">
              <div className="d-flex flex-wrap">
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Website Designing for SEO
                </Link>
                <Link
                  to="/website-designing"
                  className="btn btn-outline-secondary m-1"
                >
                  SEO Freindly Web Desing
                </Link>
                <Link
                  to="/responsive-website"
                  className="btn btn-outline-secondary m-1"
                >
                  SEO Web Design Services
                </Link>
                <Link
                  to="/digital-marketing"
                  className="btn btn-outline-secondary m-1"
                >
                  SEO & Website Designing
                </Link>
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Web Designing Company{" "}
                </Link>
                <Link to="/" className="btn btn-outline-secondary m-1">
                  Seo Competitive Web Design
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };

  return (
    <Layout
      title={data?.title}
      keywords={data?.keywords}
      description={data?.description}
      canonical={"https://www.bytefaze.com/blog/website-designing-for-seo-attract-visitors-and-rank-higher/"}
      ldName={"Website Designing For SEO"}
      ldImage={img1}
    >
      {/* <!-- Featured 1 - Bootstrap Brain Component --> */}
      <section className="py-3 py-md-5 py-xl-8 ">
        <div className="container">
          <div
            className="row gy-3 gy-lg-0 align-items-lg-center justify-content-lg-between"
            style={{ color: "var(--link-color)" }}
          >
            <div className="col-12 col-lg-7 order-1 order-lg-0">
              <div className="entry-header mb-3">
                <ul className="entry-meta list-unstyled d-flex mb-3">
                  <li>
                    <a
                      className="d-inline-flex px-2 py-1 link-accent text-accent-emphasis bg-accent-subtle border border-accent-subtle rounded-2 text-decoration-none fs-7"
                      href="#"
                    >
                      {data?.label}
                    </a>
                  </li>
                </ul>
                <h1 className="display-3 fw-bold mb-4">{data?.PageTitle}</h1>
              </div>
              <div className="entry-footer">
                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                  <li>
                    <a
                      className="fs-7  text-decoration-none d-flex align-items-center"
                      href="#"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fillRule="currentColor"
                        className="bi bi-calendar3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                      </svg>
                      <span
                        className="ms-2 fs-7"
                        style={{ color: "var(--link-color)" }}
                      >
                        {data?.date}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <a
                className="d-block bsb-hover-image overflow-hidden rounded"
                href="#"
              >
                <img loading="lazy" 
                  className="img-fluid bsb-scale bsb-hover-scale-up"
                  src={img1}
                  alt="Website Designing For SEO"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Main --> */}
      <main id="main">
        {/* <!-- Entry Content Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10">
          <div className="container">
            <div className="row justify-content-md-center gy-3 gy-xl-4">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.desc}
                {/* <p className="lead fw-bold">{data?.desc} <a href="#">emotional support</a> in a way that is healthy for both you and your partner.</p> */}
              </div>

              <div className="col-12 text-center">
                <img loading="lazy" 
                  className="img-fluid my-4 rounded"
                  src={img1}
                  alt="Web Design For SEO"
                />
              </div>

              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6 gy-3 gy-xl-4 bsb-entry-content">
                {data?.points}
                <p>{data?.points2}</p>
                {/* <h2 style={{color:"var(--link-color)"}}>Offer Physical Affection</h2> */}
                {/* <img loading="lazy"  className="img-fluid mt-3 mb-5 rounded" src={img1} alt=""/> */}
                <p>{data?.lastp}</p>
                {/* <p>Different people like different levels of physical affection at different times. If you are feeling unsure, communicate with your partner so you can learn what they are open to receiving and can provide it accordingly.</p> */}
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Entry Meta --> */}
        <div className="pb-3 pb-md-5 pb-xl-10">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
                <span
                  className="me-1 fw-bold"
                  style={{ color: "var(--link-color)" }}
                >
                  {" "}
                  Tags:
                </span>
                <ul className="entry-meta list-unstyled d-inline-flex align-items-center justify-content-center mb-3">
                  {data.Fieldtags}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Post Pagination 2 - Bootstrap Brain Component --> */}
        <section className="bsb-post-pagination-2  py-0 py-md-0 py-xl-0 bsb-section-py-xxl-1X">
          <div className="container overflow-hidden">
            <div className="row justify-content-lg-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7"></div>
            </div>
          </div>
        </section>

        {/* <!-- Author 2 - Bootstrap Brain Component --> */}
        <section className="bsb-author-2 py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1X">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 col-xl-8 col-xxl-7">
                <div className=" border-light-subtle p-4 border">
                  <div className="row gy-3 align-items-center">
                    <div className="col-md-4">
                      <a
                        href="#"
                        className="bsb-hover-image d-block rounded overflow-hidden"
                      >
                        <img loading="lazy" 
                          className="img-fluid author-avatar bsb-scale bsb-hover-scale-up"
                          src={logo}
                          alt="Website Designing Company"
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h6
                          className="card-subtitle mb-2  "
                          style={{ color: "var(--link-color)" }}
                        >
                          Article written by
                        </h6>
                        <h3 className="card-title mb-2">
                          <a
                            className="card-link  text-decoration-none"
                            href="#"
                          >
                            Bytefaze
                          </a>
                        </h3>
                        <p className="card-text mb-3">
                          We are skilled in all aspects of web design, from
                          planning and development to design and testing. I also
                          have experience in web development technologies.
                        </p>
                        <ul className="bsb-social-media nav m-0">
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-facebook"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                              </svg>
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                  <a className="nav-link " href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fillRule="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li> */}
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              to="https://x.com/Bytefaze"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-twitter"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                              </svg>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              target="_blank"
                              className="nav-link "
                              href="https://www.linkedin.com/company/bytefaze/"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fillRule="currentColor"
                                className="bi bi-linkedin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                              </svg>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Blog 5 - Bootstrap Brain Component --> */}
        <section className="bsb-blog-5 py-3 py-md-5 py-xl-8 bsb-section-py-xxl-1X ">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12">
                <h2
                  className="h4 mb-4 mb-md-5"
                  style={{ color: "var(--link-color)" }}
                >
                  Related Articles
                </h2>
              </div>
            </div>
          </div>

          {/* <div className="container overflow-hidden px-4"> */}
          {/* <div className="row gy-4 gy-md-5 gx-xl-6 gy-xl-6 gx-xxl-9 gy-xxl-9"> */}
          <Card />
          {/* </div> */}
          {/* </div> */}
        </section>

        {/* <!-- Newsletter 1 - Bootstrap Brain Component --> */}
        <section className="py-3 py-md-5 py-xl-10 bsb-section-py-xxl-1 bg-accent">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-9 col-lg-8 col-xl-8 col-xxl-7">
                <h2
                  className="display-4 fw-bold mb-4 mb-md-5 mb-xxl-6 text-center text-accent-emphasis"
                  style={{ color: "var(--link-color)" }}
                >
                  Sign up for our newsletter and never miss a thing.
                </h2>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                <form className="row gy-3 gy-lg-0 gx-lg-2 justify-content-center">
                  <div className="col-12 col-lg-8">
                    <label
                      htmlFor="email-newsletter-component"
                      className="visually-hidden"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control bsb-form-control-3xl"
                      id="email-newsletter-component"
                      placeholder="Email Address"
                      aria-label="email-newsletter-component"
                      aria-describedby="email-newsletter-help"
                      required
                    />
                    <div
                      id="email-newsletter-help"
                      className="form-text text-center text-lg-start"
                    >
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 text-center text-lg-start">
                    <button type="submit" className="btn border bsb-btn-3xl">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default WebdesignForSEO;
