import React,{ createContext, useContext, useEffect, useState } from "react";


export const authVerify = createContext()

export const AuthProvider = ({children , navigate })=>{
    
    const [admin, setAdmin] = useState(() => {
        const savedAdmin = sessionStorage.getItem('isAdmin');
        return savedAdmin ? JSON.parse(savedAdmin) : false;
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);


    useEffect(() => {
        sessionStorage.setItem('isAdmin', JSON.stringify(admin));
    }, [admin]);


    const logout = () => {
        sessionStorage.clear()
        // setAdmin(false);
        navigate('/login');
    };


    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
          setIsAuthenticated(true);
        }
      }, []);
      
      const checkAuth = () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
          navigate('/login');
        }
      };

    return (
    <authVerify.Provider value={{admin,setAdmin,logout , isAuthenticated, setIsAuthenticated , checkAuth}}>
        {children}
    </authVerify.Provider>
    )
}
export const useAuthContext = () => {
    const context = useContext(authVerify);

    if (context === undefined) {
        throw new Error("useAuthContext must be used within an AuthProvider");
    }

    return context;
};

