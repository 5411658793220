import React from 'react'
import "../../Styles/styles.css"

const Dashloader = () => {
  return (
    <div>
    
    <div className="dash-loading">
  <span></span>
  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>
    </div>
  )
}
  
export default Dashloader
