import React, { useEffect, useState } from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Routes, Route, BrowserRouter, useNavigate} from "react-router-dom";
import './App.css'
import Hero from './Components/pages/Hero.jsx'
import About from "./Components/pages/About.jsx";
import Services from "./Components/pages/Services.jsx";
import Contact from "./Components/pages/Contact.jsx";
import Internship from "./Components/pages/Internship.jsx";
import WebsiteDesign from "./Components/ServicesPages/WebsiteDesign.jsx";
import ResponsiveWebsite from "./Components/ServicesPages/ResponsiveWebsite.jsx";
import WebHosting from "./Components/ServicesPages/WebHosting.jsx";
import Seo from "./Components/ServicesPages/Seo.jsx";
import LogoDesigning from "./Components/ServicesPages/LogoDesigning.jsx";
import EcommerceWebsite from "./Components/ServicesPages/EcommerceWebsite.jsx";
import DomainRegistration from "./Components/ServicesPages/DomainRegistration.jsx";
import DigitalMarketing from "./Components/ServicesPages/DigitalMarketing.jsx";
import CorporatePresentation from "./Components/ServicesPages/CorporatePresentation.jsx";
import ApplicationDevelopment from "./Components/ServicesPages/Application Development.jsx";
import Ract from "./Components/pages/Ract.jsx";
import ReactIntern from "./Components/InternPages/ReactIntern.jsx";
import AngularIntern from "./Components/InternPages/AngularIntern.jsx";
import PhpIntern from "./Components/InternPages/PhpIntern.jsx";
import IosIntern from "./Components/InternPages/IosItern.jsx";
import NodeIntern from "./Components/InternPages/NodeIntern.jsx";
import LaravelIntern from "./Components/InternPages/LaravelIntern.jsx";
import UiUxIntern from "./Components/InternPages/UiUxIntern.jsx";
import PythonIntern from "./Components/InternPages/PythonIntern.jsx";
import ErrorPage from "./Components/pages/ErrorPage.jsx";
import HireDeveloper from "./Components/pages/HireDeveloper.jsx";
import Blog from "./Components/blog/Blog.jsx";
import Websitedesignblog from "./Components/blog/blogs/Websitedesignblog.jsx";
import AustraliaWebsiteDesign from "./Components/blog/blogs/AustraliaWebsiteDesign.jsx";
import LocalGrowblog from "./Components/blog/blogs/LocalGrowblog.jsx";
import LatestTrends from "./Components/blog/blogs/LatestTrends.jsx";
import WebdesignForSEO from "./Components/blog/blogs/WebdesignForSEO.jsx";
import GuideForWebsiteDesign from "./Components/blog/blogs/GuideForWebsiteDesign.jsx";
import WebDesignAhmedabad from "./Components/blog/blogs/WebDesignAhmedabad.jsx";
import TechnologiesWebDesign from "./Components/blog/blogs/TechnologiesWebDesign.jsx";
import WebDevelopmentInternship from "./Components/blog/blogs/WebDevelopmentInternship.jsx";
import WhatWebDesigning from "./Components/blog/blogs/WhatWebDesigning";
import BoostAhmedabadWebsite from "./Components/blog/blogs/BoostAhmedabadWebsite.jsx";
import BestWebDesigner from "./Components/blog/blogs/BestWebDesigner.jsx";
import Popup from "./Components/popup/Popup.jsx";
import HireFlutter from "./Components/HirePages/HireFlutter.jsx";
import HireIos from "./Components/HirePages/HireIos.jsx";
import HireAndroid from "./Components/HirePages/HireAndroid.jsx";
import HireSwift from "./Components/HirePages/HireSwift.jsx";
import HireReactNative from "./Components/HirePages/HireReactNative.jsx";
import HireIonic from "./Components/HirePages/HireIonic.jsx";
import HireReact from "./Components/HirePages/HireReact.jsx";
import HireVue from "./Components/HirePages/HireVue.jsx";
import HireNext from "./Components/HirePages/HireNext.jsx";
import HireAngular from "./Components/HirePages/HireAngular.jsx";
import HireGolang from "./Components/HirePages/HireGolang.jsx";
import HireNode from "./Components/HirePages/HireNode.jsx";
import HirePhp from "./Components/HirePages/HirePhp.jsx";
import HirePython from "./Components/HirePages/HirePython.jsx";
import HireFullStack from "./Components/HirePages/HireFullStack.jsx";
import HireDevOps from "./Components/HirePages/HireDevOps.jsx";
import HireGame from "./Components/HirePages/HireGame.jsx";
import HireData from "./Components/HirePages/HireData.jsx";
import HireAi from "./Components/HirePages/HireAi.jsx";
import HireML from "./Components/HirePages/HireMl.jsx";
import HireUiUx from "./Components/HirePages/HireUiUx.jsx";
import HireQaEngineers from "./Components/HirePages/HireQaEngineers.jsx";
import LiveProjectTraining from "./Components/blog/blogs/LiveProjectTraining.jsx";
import SummerInternship from "./Components/blog/blogs/SummerInternship.jsx";
import TopWebInternship from "./Components/blog/blogs/TopWebInternship.jsx";
import Signup from "./Components/Auth/Signup.jsx";
import Forgot from "./Components/Auth/Forgot.jsx";
import Login from "./Components/Auth/Login.jsx";
import UserDetails from "./Components/Admin/pages/UserDetails.jsx";
import ProgramPage from "./Components/ProgramPage.jsx";
import Terms from "./Components/Terms.jsx";
import OneMonthInternship from "./Components/blog/blogs/OneMonthInternship.jsx";
import DaysInternship from "./Components/blog/blogs/DaysInternship.jsx";
import OneHalfInternship from "./Components/blog/blogs/OneHalfInternship.jsx";

import ExpertWebServices from "./Components/blog/blogs/ExpertWebServices.jsx";
import WebDesigningBaroda from "./Components/Market/WebDesigningBaroda.jsx";
import WebDesigningSurat from "./Components/Market/WebDesigningSurat.jsx";
import WebDesigningRajkot from "./Components/Market/WebDesigningRajkot.jsx";
import WebDesignGandhinagar from "./Components/Market/WebDesignGandhinagar.jsx";
import WebDesignJamnagar from "./Components/Market/WebDesignJamnagar.jsx";
import WebDesignMahesana from "./Components/Market/WebDesignMahesana.jsx";
import WebDesignMorbi from "./Components/Market/WebDesignMorbi.jsx";
import WebDesignMumbai from "./Components/Market/WebDesignMumbai.jsx";
import WebDesignBhavnagar from "./Components/Market/WebDesignBhavnagar.jsx";

import Progressive from "./Components/Progressive/Progressive.jsx";
// import Datadashboard from "./Components/Datadashboard.jsx";
import { AuthProvider } from "./Components/context/AuthContext.jsx";

import Dashboard from "./Components/Dashboard/Dashboard.jsx";
import Newpass from "./Components/Auth/Newpass.jsx";
import CreateTechnology from "./Components/Dashboard/admin/CreateTechnology.jsx";
import InternForm from "./Components/Dashboard/admin/InternForm.jsx";
import Interndetails from "./Components/Dashboard/admin/Interndetails.jsx";
import AhmedabadWebDesign from "./Components/blog/blogs/AhmedabadWebDesign.jsx";
import WebDesignPune from "./Components/Market/WebDesignPune.jsx";
import WebDesignAnkleshwar from "./Components/Market/WebDesignAnkleshwar.jsx";
import DigitalGMB from "./Components/blog/blogs/DigitalGMB.jsx";
import Addmetogooglesearch from "./Components/blog/blogs/Addmetogooglesearch.jsx";
import Itservices from "./Components/ServicesPages/Itservices.jsx";
import Ittraining from "./Components/ServicesPages/Ittraining.jsx";
import Portfolio from "./Components/pages/Portfolio.jsx";
import PricingPlan from "./Components/pages/PricingPlan.jsx";
import PriceCalculator from "./Components/pages/PriceCalculator.jsx";
import PriceCalculatorResult from "./Components/pages/PriceCalculatorResult.jsx";
import './Styles/styles.css'

// import Design100 from "./Components/Design100.jsx";


const App = () => {
  return (
      <BrowserRouter >
          <AuthWrapper />
      </BrowserRouter>
  );
};


function AuthWrapper () {
  const navigate = useNavigate();
  const [modal,setModal]=useState(false)
  
  useEffect(()=>{
    setTimeout(()=>{
      setModal(true)
    },10000)
  },[])


  return (
    <>
      {modal ? <Popup/>: ''} 
      <AuthProvider navigate={navigate}>
        <Routes>
          <Route path='/' element={<Hero />} />
          <Route path='/who-we-are' element={<About />} />
          <Route path={'/what-we-do'} element={<Services />} />
          <Route path={'/services'} element={<Services />} />
          <Route path='/:technology' element={<HireDeveloper />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/internships' element={<Internship />} />
          {/* <Route path='/blog' element={<Blog/>} /> */}

          {/* ------------------blog pages----------------------------------- */}
          {/* <Route path='/blog' element={<Blogpost/>} /> */}
          <Route path='/blog' element={<Blog/>} />
          {/* {/ <Route path='/blog?/:title' element={<BlogDetail/>} /> } */}
          <Route path="/blog/embracing-the-future-of-web-design-in-ahmedabad-with-bytefaze-web-solution" element={<Websitedesignblog/>}/>
          <Route path="/blog/website-designing-in-australia" element={<AustraliaWebsiteDesign/>}/>
          {/* <Route path="/blog/tag/website-designing-services-australia" element={<AustraliaWebsiteDesign/>}/> */}
          <Route path="/blog/website-design-services-for-local-businesses-grow-your-local-reach" element={<LocalGrowblog/>}/>
          <Route path="/blog/riding-the-wave-unveiling-the-latest-trends-in-web-design" element={<LatestTrends/>}/>
          <Route path="/blog/website-designing-for-seo-attract-visitors-and-rank-higher" element={<WebdesignForSEO/>}/>
          <Route path="/blog/the-ultimate-guide-to-choosing-a-website-design-company-in-ahmedabad" element={<GuideForWebsiteDesign/>}/>
          <Route path="/blog/web-designing-ahmedabad" element={<WebDesignAhmedabad/>}/>
          <Route path="/blog/leveraging-technologies-for-cutting-edge-website-design-in-2024" element={<TechnologiesWebDesign/>}/>
          <Route path="/blog/web-development-internship-in-ahmedabad" element={<WebDevelopmentInternship/>}/>
          <Route path="/blog/what-is-website-design" element={<WhatWebDesigning/>}/>
          <Route path="/blog/boost-website-traffic-ahmedabad" element={<BoostAhmedabadWebsite/>}/>
          <Route path="/blog/best-web-designer-ahmedabad" element={<BestWebDesigner/>}/>
          <Route path="/blog/live-project-training-ahmedabad" element={<LiveProjectTraining/>}/>
          <Route path="/blog/summer-internship-and-training-in-ahmedabad" element={<SummerInternship/>}/>
          <Route path="/blog/top-internship-company-for-web-development-ahmedabad" element={<TopWebInternship/>}/>
          <Route path="/blog/one-month-internship-in-ahmedabad" element={<OneMonthInternship/>}/>
          <Route path="/blog/15-days-internship-in-ahmedabad" element={<DaysInternship/>}/>
          <Route path="/blog/45-days-internship-in-ahmedabad" element={<OneHalfInternship/>}/>
          <Route path="/blog/expert-website-designing-services-in-ahmedabad" element={<ExpertWebServices/>}/> 
          <Route path="/blog/ahmedabad-web-design-2024" element={<AhmedabadWebDesign/>}/> 
          <Route path="/blog/digital-marketing-services-for-optimizing-gmb" element={<DigitalGMB/>}/>
          <Route path="/blog/add-me-to-search-google-people-card-for-seo" element={<Addmetogooglesearch/>}/>           
          {/* ------------------blog pages----------------------------------- */}
      
          {/* <Route path='/hire-flutter-app-developers' element={<Flutter />} /> */}

          {/* ----------------------service pages------------------------- */}
          <Route path='/website-designing' element={<WebsiteDesign />} />
          <Route path='/responsive-website' element={<ResponsiveWebsite />} />
          <Route path='/web-hosting' element={<WebHosting />} />
          <Route path='/seo' element={<Seo />} />
          <Route path='/logo-designing' element={<LogoDesigning />} />
          <Route path='/ecommerce-website' element={<EcommerceWebsite />} />
          <Route path='/domain-registration' element={<DomainRegistration />} />
          <Route path='/digital-marketing' element={<DigitalMarketing />} />
          <Route path='/corporate-presentation' element={<CorporatePresentation />} />
          <Route path='/application-development' element={<ApplicationDevelopment />} />
          <Route path='/react-developer' element={<Ract/>} />
          <Route path='/it-services-in-ahmedabad' element={<Itservices/>} />
          <Route path='/it-training-in-ahmedabad' element={<Ittraining/>} />
          {/* ----------------------service pages------------------------- */}
          {/* ---------------------------intern pages----------------------------- */}
          <Route path='/react-internship' element={<ReactIntern />} />
          <Route path='/angular-internship' element={<AngularIntern />} />
          <Route path='/php-internship' element={<PhpIntern />} />
          <Route path='/node-internship' element={<NodeIntern />} />
          <Route path='/ios-internship' element={<IosIntern />} />
          <Route path='/laravel-internship' element={<LaravelIntern />} />
          <Route path='/uiux-internship' element={<UiUxIntern />} />
          <Route path='/python-internship' element={<PythonIntern />} />
          {/* ---------------------------intern pages----------------------------- */}

            {/* ----------------------Hire Developer pages------------------------- */}                    

          {/* --------------------------------------hire pages---------------------------------- */}
          <Route path='/hire-flutter-app-developers' element={<HireFlutter/>} />
          <Route path='/hire-ios-app-developers' element={<HireIos/>} />
          <Route path='/hire-android-app-developers' element={<HireAndroid/>} />    
          <Route path='/hire-swift-developers' element={<HireSwift/>} />
          <Route path='/hire-react-native-developers' element={<HireReactNative/>} />
          <Route path='/hire-ionic-developers' element={<HireIonic/>} />
          <Route path='/hire-react-developers' element={<HireReact/>} />
          <Route path='/hire-vue-developers' element={<HireVue/>} />
          <Route path='/hire-nextjs-developers' element={<HireNext/>} />
          <Route path='/hire-angular-developers' element={<HireAngular/>} />
          <Route path='/hire-golang-developers' element={<HireGolang/>} />
          <Route path='/hire-nodejs-developers' element={<HireNode/>} />
          <Route path='/hire-php-developers' element={<HirePhp/>} />
          <Route path='/hire-python-developers' element={<HirePython/>} />
          <Route path='/hire-full-stack-developers' element={<HireFullStack/>} />
          <Route path='/hire-qa-engineers' element={<HireQaEngineers/>} />
          <Route path='/hire-devops-engineers' element={<HireDevOps/>} />
          <Route path='/hire-game-developers' element={<HireGame/>} />
          <Route path='/hire-data-scientist' element={<HireData/>} />
          <Route path='/hire-ai-developers' element={<HireAi/>} />
          <Route path='/hire-ml-developers' element={<HireML/>} />
          <Route path='/hire-ui-ux-developers' element={<HireUiUx/>} />
          <Route path='/web-designing-in-baroda' element={<WebDesigningBaroda/>} />
          <Route path='/web-designing-in-surat' element={<WebDesigningSurat/>} />
          <Route path='/web-designing-in-rajkot' element={<WebDesigningRajkot/>} />
          <Route path='/web-designing-in-gandhinagar' element={<WebDesignGandhinagar/>} />
          <Route path='/web-designing-in-jamnagar' element={<WebDesignJamnagar/>} />
          <Route path='/web-designing-in-mehsana' element={<WebDesignMahesana/>} />
          <Route path='/web-designing-in-morbi' element={<WebDesignMorbi/>} />
          <Route path='/web-designing-in-mumbai' element={<WebDesignMumbai/>} />
          <Route path='/web-designing-in-bhavnagar' element={<WebDesignBhavnagar/>} />
          <Route path='/web-designing-in-pune' element={<WebDesignPune/>} />
          <Route path='/web-designing-in-ankleshwar' element={<WebDesignAnkleshwar/>} />
                  
          {/* <Route path='/hire' element={<HireUiUx/>} />  */}

          {/* --------------------------------------hire pages---------------------------------- */}

          {/* --------------------------------------Error pages---------------------------------- */}
          <Route path="*" element = {<ErrorPage/>} />
          {/* --------------------------------------Error pages---------------------------------- */}
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/forgot" element={<Forgot/>}/>


        {/* <Route path="/dashboard" element={<Dashboard/>}/> */}
        <Route path="/dashboard/details/:id" element={<UserDetails/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
       
        


        <Route path="/internship-program" element={<ProgramPage/>}/>
        <Route path="/terms" element={<Terms/>}/>
      
        
        <Route path="/progress" element={<Progressive/>}/>
        {/* <Route path="/data" element={<Datadashboard/>}/> */}

        <Route path="/reset-password/:token" element={<Newpass/>}/>



        <Route path="/dashboard/create-technology" element={<CreateTechnology/>}/>
        <Route path="/dashboard/intern-form" element={<InternForm/>}/>
        <Route path="/dashboard/intern-details/:id" element={<Interndetails/>}/>
  


         <Route path="/portfolio" element={<Portfolio/>}/>
         <Route path="/pricing" element={<PricingPlan/>}/>
         <Route path="/website-design-cost-calculator" element={<PriceCalculator/>}/>
         <Route path="/price-calculator-result" element={<PriceCalculatorResult/>}/>


         {/* --------------------------------userinfo----------------------- */}
       

        </Routes>
        </AuthProvider>
    </>
  )
}

export default App
