import React from "react";
//import "../../Styles/styles.css";
import { Link } from "react-router-dom";

const Infonav = () => {
  return (
    <div className="navv d-lg-block d-none glass-morf">
      <div className="container">
        <div className="navv-container social-icons-section"></div>

        <div className="social-icons-desktop d-flex justify-content-between align-items-center">
          <div className="contact-number">
            <Link to="tel:+91-846-971-7272" className="top-para">
              <span>
                <i className="ri-phone-line"></i>
              </span>
              &nbsp;+91-8469717272
            </Link>
            <span className="mx-2">
              <Link to="mailto:info@bytefaze.com" className="top-para">
                <i className="ri-mail-line" ></i>&nbsp;info@bytefaze.com
              </Link>
            </span>
          </div>
          <div className="social-icons-nav  ">
            <Link
              className="top-para"
              to="https://www.linkedin.com/company/bytefaze/"
              target="_blank"
            >
              <i className="ri-linkedin-box-fill h6 px-1"></i>
            </Link>
            <Link
              className="top-para"
              to="https://www.instagram.com/bytefaze/"
              target="_blank"
            >
              <i className="ri-instagram-fill h6 px-1"></i>
            </Link>
            <Link
              className="top-para"
              to="https://x.com/Bytefaze"
              target="_blank"
            >
              <i className="ri-twitter-x-fill h6 px-1"></i>
            </Link>
            <Link
              className="top-para"
              to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
              target="_blank"
            >
              <i className="ri-facebook-box-fill h6 px-1"></i>
            </Link>
            <Link
              className="top-para"
              to="https://wa.me/+918469717272"
              target="_blank"
            >
              <i className="ri-whatsapp-fill h6 px-1"></i>
            </Link>
            <Link
              className="top-para"
              to="https://join.skype.com/invite/rD0feVkZHxlJ"
              target="_blank"
            >
              <i className="ri-skype-fill h6 px-1"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infonav;
