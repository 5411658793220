import React, { useState } from "react";
import Layouts from "../layout/Layouts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NewSideNav from "./NewSideNav";

const PriceCalculatorResult = () => {
  const location = useLocation();

  const finalPrice = location?.state?.price;
  //   console.log(location?.state?.price);

  //   alert(location?.state?.price);

  return (
    <Layouts>
      <div className="col-12 mb-4 bg-light p-4 text-center about_bg">
        <h1 className=" fw-bold">Website Design Cost Calculator</h1>
      </div>

      <section className="container">
        <div className="row justify-content-around">
          {/* Left Column */}
          <div
            className="col-12 col-lg-6 mb-4"
            style={{ background: "var(--footer-bg)" }}
          >
            <div className="p-4 shadow-sm border-dotted border-danger border-2 mt-3 text-center">
              <h3 className=" top-para text-danger text-center">
                Website Cost Calculator
              </h3>
              <p>
                Thank you very much for using our basic Website Cost Calculator
                for your proposed website.
               
              </p>
              <h3 className="top-para text-danger text-center">
              The tentative cost of your website
              design would be: <span className="text-success">INR {finalPrice} .</span> 
              </h3><br />
              <p>
                <strong>NOTE:</strong> Above estimate is tentative based on the
                provided parameters and your basic understanding of the scope of
                work. To get the best competitive rates, contact us by{" "}
                <Link
                  to={"/contact-us"}
                  className="text-success"
                >
                  clicking here
                </Link>
                .
              </p>
            </div>

            <div className="p-4 shadow-sm">
              <h3 className="mt-4 top-para">Why are you so expensive?</h3>
              <p>
                As a leading web design company in India, we do speak to lots of
                business owners who “tried to save money” by getting a cheap
                website. Saving money is not a bad idea however having a
                non-performing website is definitely one. Our cost may be a few
                thousand more compared to a small company or freelancer but the
                expertise and support we bring makes your business successful.
                The cost of a “cheap” website may seem more appealing to begin
                with until you realize that it doesn’t deliver customers. Then
                you’ll have to waste more time and spend more money getting your
                site redesigned.
              </p>
              <p>
                The second-best reason is “Support”. An established company with
                2000+ clients and 12+ years’ experience will always respond to
                your requirement once the website is done and live. Most
                freelancers and small companies shut their operations in one or
                two years and make you helpless in the middle of a crisis.
                Believe us, we get such inquiries every other day.
              </p>
              <p>
                We may charge a few thousand more but give you peace of mind.
              </p>

              <h3 className="mt-4 top-para">Why are you so cheap?</h3>
              <p>
                Yep, we’ve also been asked that question too. Unlike most of the
                big branding agencies, we do not pay “kickbacks” for getting
                website orders. Our sales approach is very simple and based on
                the reality of website design, and we don’t make “pie in the
                sky” promises. You are paying for our time, efforts, and
                expertise. We understand that inexpensive often translates to
                cheap quality; however, with ByteFaze, you get international
                standard quality at a reasonable price.
              </p>

              <h3 className="mt-4 top-para">
                Are there any other costs for my website?
              </h3>
              <p>
                We believe in complete transparency and business ethics. At the
                time of approval, we will explain all costing elements and you
                will be charged based on that. The cost calculator price is
                based on your input and you will not be charged for things you
                don’t need, e.g., Domain Name, Web Hosting, Content Writing, and
                Website Maintenance.
              </p>

              <h3 className="mt-4 top-para">
                What are the payment terms for the design and development of my
                website?
              </h3>
              <p>
                We like to work on a 50% upfront basis with 50% payable after
                the development has been completed and signed off. For large
                projects, the payment is in installments and module delivery
                basis.
              </p>

              <h3 className="mt-4 top-para">
                What happens next? How do we start a project?
              </h3>
              <p>
                The next step is to get in touch and arrange a call or a meeting
                with us. Our executive will take your requirements and send you
                an official proposal. Please feel free to call us at +91
                8469717272.
              </p>
            </div>
          </div>

          {/* Right Column (Website Calculator) */}
          <div className="col-12 col-lg-5 mb-4" >
            <NewSideNav />
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default PriceCalculatorResult;
