
import React from "react";
//import { LazyLoadImage } from "react-lazy-load-image-component";

import { useNavigate } from "react-router-dom";
import rec from "../../assets/WebpImages/uiux-web-design.webp";
import "../../Styles/Internship.css";
import Layout from "../layout/Layouts";

const UiUxIntern = () => {
  const navigate = useNavigate();
  
  const navigateToContacts = () => {
    
    navigate('/contact-us');
  };
  return (
    <Layout
    title={"Ui/UX Internship for Freshers | Ui/UX Internship Provider Company in Ahmedabad"}
    keywords={
      "UI/UX Internship For Freshers, UI/UX Internship Provider Company in Ahmedabad,  UI/UX Graphics Designer Training, Urgent UI/UX Internship Job In Ahmedabad"
    }
    description={
      "Bytefaze looking forward to hire UX/UI Design Freshers for Internship & Job. We Provide Training & Internship in UI/UX Designining in Ahmedabad."
    }
    canonical={"https://www.bytefaze.com/uiux-internship"}
  >
      <div className="col-12 int-rect">
  <h1 className="display-5 text-center fw-bold">
    UI/UX Internship Company
  </h1>
  <p className=" mb-3 col-lg-6 text-center fs-6">
    Join Bytefaze for an
    immersive UI/UX internship, where you'll learn from experienced
    mentors, work on real projects, and kickstart your career in web
    design and user experience.
  </p>
</div>

<section className="py-3 py-md-5 py-xl-8">
  <div className="container" id="topic1">
    <div className="d-flex justify-content-center">
      <h1 style={{color:"var(--primary-color)"}}>What is UI/UX?</h1>
    </div>
    <div className="row align-items-center justify-content-center mt-3 reac-para">
      <p>
        1.&nbsp; User-Centric Design: UI/UX focuses on creating designs that prioritize the needs and preferences of users. By understanding user behaviors and motivations, designers can develop interfaces that are intuitive and enjoyable to use.
      </p>
      <p>
        2.&nbsp;Visual Design: UI/UX involves crafting visually appealing interfaces that communicate information effectively. Designers utilize principles such as typography, color theory, and layout to create aesthetically pleasing designs.
      </p>
      <p>
        3.&nbsp;Information Architecture: UI/UX encompasses structuring and organizing information in a way that is easy to understand and navigate. Designers create logical hierarchies and clear pathways to enhance usability and findability.
      </p>
      <p>
        4.&nbsp;Interaction Design: UI/UX focuses on designing interactive elements that facilitate user engagement and task completion. Designers consider factors such as feedback, responsiveness, and affordance to create intuitive interactions.
      </p>
      <p>
        5.&nbsp;User Research: UI/UX involves conducting research to gain insights into user behaviors, preferences, and pain points. Designers utilize methods such as surveys, interviews, and usability testing to inform design decisions.
      </p>
      <p>
        6.&nbsp;Prototyping and Testing: UI/UX encompasses prototyping and testing designs to gather feedback and iterate on solutions. Designers create prototypes to simulate interactions and usability, allowing for refinement before implementation.
      </p>
      <p>
        7.&nbsp;Responsive Design: UI/UX involves designing interfaces that adapt seamlessly to various devices and screen sizes. Designers utilize techniques such as flexible layouts and media queries to ensure optimal user experiences across platforms.
      </p>
      <p>
        8.&nbsp;Accessibility: UI/UX focuses on designing inclusive interfaces that are accessible to users with disabilities. Designers adhere to accessibility standards and guidelines to ensure that all users can access and interact with digital products.
      </p>
      <p>
        9.&nbsp;Collaboration: UI/UX emphasizes collaboration between designers, developers, and stakeholders throughout the design process. By involving cross-functional teams, designers can create cohesive and effective user experiences.
      </p>
      <p>
        10.&nbsp;Continuous Improvement: UI/UX involves iteratively improving designs based on user feedback and evolving needs. Designers analyze metrics, gather insights, and iterate on designs to enhance usability and satisfaction over time.
      </p>
    </div>
  </div>
</section>

<div style={{ background: "var(--footer-bg)" }}>
  <div className="container ">
    <div className="row align-items-center">
      <div className="shead d-flex justify-content-center mt-5 text-center">
        <h2 style={{color:"var(--primary-color)"}}> What You Are Going To Learn In UI/UX:</h2>
      </div>
      <div className="spara mt-3 col-lg-6 order-2 order-lg-1">
        <ol>
          <li>Understanding User Needs: Prioritize understanding the needs and preferences of users to create user-centered designs that meet their expectations and goals.</li>
          <li className="mt-2">Hands-on Projects: Engage interns in practical, real-world projects to apply theoretical knowledge, gain practical experience, and develop critical design skills.</li>
          <li className="mt-2">Collaboration and Communication: Foster a collaborative environment where interns can work closely with team members to share ideas, provide feedback, and solve design challenges.</li>
          <li className="mt-2">Design Critiques: Regularly critique interns' designs and provide constructive feedback to help them improve their design skills, refine their creative process, and develop a critical eye for design.</li>
          <li className="mt-2">Continuous Learning: Encourage interns to continue learning and exploring advanced UI/UX topics, tools, and techniques to stay updated with industry trends and best practices.</li>
        </ol>
      </div>
      <div className="col-lg-6 d-flex align-items-center order-1 order-lg-2 justify-content-center ">
        <img loading="lazy"  
          src={rec}
          alt="UI/UX Internship Company"
          className="img-fluid mt-1"
          width={400}
        />
      </div>
    </div>
  </div>
</div>

<section className="py-3 py-md-5 py-xl-8">
  <div className="container">
    <div className="row d-flex justify-content-center">
      <div className="col-lg-12 d-flex justify-content-center fw-bold text-center" style={{color:"red"}}>
        <h1>What are you waiting for???</h1>
      </div>
      <div className="d-flex justify-content-center fw-bold" style={{color:"blueviolet"}}>
        <h2>Master UI/UX With Us!!!</h2>
      </div>
      <div className="col-lg-12 d-flex justify-content-center" onClick={navigateToContacts}>
        <button className="rbutt">
          Apply Now
        </button>
      </div>
    </div>
  </div>
</section>

    </Layout>
  );
};
export default UiUxIntern;