import React, { useState } from "react";
//import "../../Styles/styles.css";
import { Modal, Button, Offcanvas, Tab, Nav } from "react-bootstrap";
import Layouts from "../layout/Layouts";

import construction1 from "../../assets/portfolio img/construction1.webp";
import course1 from "../../assets/portfolio img/course1.webp";
import coworking1 from "../../assets/portfolio img/coworking1.webp";
import designl from "../../assets/portfolio img/design1.webp";
import ecom1 from "../../assets/portfolio img/ecom1.webp";
import ecom2 from "../../assets/portfolio img/ecom2.webp";
import ecom3 from "../../assets/portfolio img/ecom3.webp";
import ecom4 from "../../assets/portfolio img/ecom4.webp";
import financel from "../../assets/portfolio img/finance1.webp";
import foodl from "../../assets/portfolio img/food1.webp";
import foruml from "../../assets/portfolio img/forum1.webp";
import freelancerl from "../../assets/portfolio img/freelancer1.webp";
import fundingl from "../../assets/portfolio img/funding1.webp";
import furniturel from "../../assets/portfolio img/furniture1.webp";
import gym1 from "../../assets/portfolio img/gym1.webp";
import headphonel from "../../assets/portfolio img/headphone1.webp";
import healthcare1 from "../../assets/portfolio img/healthcare1.webp";
import healthcare2 from "../../assets/portfolio img/healthcare2.webp";
import hostell from "../../assets/portfolio img/hostel1.webp";
import hosting1 from "../../assets/portfolio img/hosting1.webp";
import kinderl from "../../assets/portfolio img/kinder1.webp";
import landingl from "../../assets/portfolio img/landing1.webp";
import lawl from "../../assets/portfolio img/law1.webp";
import mediaagencyl from "../../assets/portfolio img/mediaagency1.webp";
import music from "../../assets/portfolio img/music.webp";
import newsl from "../../assets/portfolio img/news1.webp";
import ngol from "../../assets/portfolio img/ngo1.webp";
import nutritionl from "../../assets/portfolio img/nutrition1.webp";
import parcell from "../../assets/portfolio img/parcel1.webp";
import petI from "../../assets/portfolio img/pet1.webp";
import photographerl from "../../assets/portfolio img/photographer1.webp";
import portfoliol from "../../assets/portfolio img/portfolio1.webp";
import portfolio2 from "../../assets/portfolio img/portfolio2.webp";
import portfolio3 from "../../assets/portfolio img/portfolio3.webp";
import realestatel from "../../assets/portfolio img/realestate1.webp";
import realestate2 from "../../assets/portfolio img/realestate2.webp";
import realestate3 from "../../assets/portfolio img/realestate3.webp";
import restaurantl from "../../assets/portfolio img/restaurant1.webp";
import resumel from "../../assets/portfolio img/resume1.webp";
import seol from "../../assets/portfolio img/seo1.webp";
import spal from "../../assets/portfolio img/spa1.webp";
import spa2 from "../../assets/portfolio img/spa2.webp";
import travell from "../../assets/portfolio img/travel1.webp";
import writerl from "../../assets/portfolio img/writer1.webp";
import yogal from "../../assets/portfolio img/yoga1.webp";

const Portfolio = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const portfolios = [
    // E-commerce category
    {
      category: "E-commerce",
      name: "E-commerce Website 1",
      mainImage: ecom1,
    },
    {
      category: "E-commerce",
      name: "E-commerce Website 2",
      mainImage: ecom2,
    },
    {
      category: "E-commerce",
      name: "E-commerce Website 3",
      mainImage: ecom3,
    },
    {
      category: "E-commerce",
      name: "E-commerce Website 4",
      mainImage: ecom4,
    },
    {
      category: "E-commerce",
      name: "Headphones E-commerce Website",
      mainImage: headphonel,
    },
    {
      category: "E-commerce",
      name: "Furniture Store Website",
      mainImage: furniturel,
    },

    // Healthcare & Wellness category
    {
      category: "Healthcare & Wellness",
      name: "Healthcare Website 1",
      mainImage: healthcare1,
    },
    {
      category: "Healthcare & Wellness",
      name: "Healthcare Website 2",
      mainImage: healthcare2,
    },
    {
      category: "Healthcare & Wellness",
      name: "Nutrition Website",
      mainImage: nutritionl,
    },
    {
      category: "Healthcare & Wellness",
      name: "Gym Website",
      mainImage: gym1,
    },
    {
      category: "Healthcare & Wellness",
      name: "Yoga Studio Website",
      mainImage: yogal,
    },
    {
      category: "Healthcare & Wellness",
      name: "Spa Website 1",
      mainImage: spal,
    },
    {
      category: "Healthcare & Wellness",
      name: "Spa Website 2",
      mainImage: spa2,
    },

    // Creative & Design category
    {
      category: "Creative & Design",
      name: "Design Agency Website",
      mainImage: designl,
    },
    {
      category: "Creative & Design",
      name: "Photographer Portfolio Website",
      mainImage: photographerl,
    },
    {
      category: "Creative & Design",
      name: "Writer Portfolio Website",
      mainImage: writerl,
    },
    {
      category: "Creative & Design",
      name: "Media Agency Website",
      mainImage: mediaagencyl,
    },
    {
      category: "Creative & Design",
      name: "Music Streaming Website",
      mainImage: music,
    },

    // Corporate & Finance category
    {
      category: "Corporate & Finance",
      name: "Law Firm Website",
      mainImage: lawl,
    },
    {
      category: "Corporate & Finance",
      name: "Finance Website",
      mainImage: financel,
    },
    {
      category: "Corporate & Finance",
      name: "SEO Agency Website",
      mainImage: seol,
    },
    {
      category: "Corporate & Finance",
      name: "Forum Website",
      mainImage: foruml,
    },
    {
      category: "Corporate & Finance",
      name: "Landing Page Website",
      mainImage: landingl,
    },

    // Education category
    {
      category: "Education",
      name: "Kindergarten Website",
      mainImage: kinderl,
    },
    {
      category: "Education",
      name: "Course Website",
      mainImage: course1,
    },
    {
      category: "Education",
      name: "Freelancer Portfolio Website",
      mainImage: freelancerl,
    },
    {
      category: "Education",
      name: "Resume Website",
      mainImage: resumel,
    },

    // Lifestyle & Leisure category
    {
      category: "Lifestyle & Leisure",
      name: "Food Delivery Website",
      mainImage: foodl,
    },
    {
      category: "Lifestyle & Leisure",
      name: "Restaurant Website",
      mainImage: restaurantl,
    },
    {
      category: "Lifestyle & Leisure",
      name: "Travel Agency Website",
      mainImage: travell,
    },
    {
      category: "Lifestyle & Leisure",
      name: "Pet Care Website",
      mainImage: petI,
    },
    {
      category: "Lifestyle & Leisure",
      name: "Hostel Booking Website",
      mainImage: hostell,
    },
    {
      category: "Lifestyle & Leisure",
      name: "Coworking Space Website",
      mainImage: coworking1,
    },

    // Technology & Digital Services category
    {
      category: "Technology & Digital Services",
      name: "SEO Agency Website",
      mainImage: seol,
    },
    {
      category: "Technology & Digital Services",
      name: "News Website",
      mainImage: newsl,
    },
    {
      category: "Technology & Digital Services",
      name: "Funding Platform Website",
      mainImage: fundingl,
    },
    {
      category: "Technology & Digital Services",
      name: "Parcel Delivery Website",
      mainImage: parcell,
    },

    // Real Estate & Construction category
    {
      category: "Real Estate & Construction",
      name: "Construction Website 1",
      mainImage: construction1,
    },
    {
      category: "Real Estate & Construction",
      name: "Real Estate Website 1",
      mainImage: realestatel,
    },
    {
      category: "Real Estate & Construction",
      name: "Real Estate Website 2",
      mainImage: realestate2,
    },
    {
      category: "Real Estate & Construction",
      name: "Real Estate Website 3",
      mainImage: realestate3,
    },

    // Food & Hospitality category
    {
      category: "Food & Hospitality",
      name: "Restaurant Website",
      mainImage: restaurantl,
    },
    {
      category: "Food & Hospitality",
      name: "Food Delivery Website",
      mainImage: foodl,
    },

    // Portfolio & Personal Branding category
    {
      category: "Portfolio & Personal Branding",
      name: "Portfolio Website 1",
      mainImage: portfoliol,
    },
    {
      category: "Portfolio & Personal Branding",
      name: "Portfolio Website 2",
      mainImage: portfolio2,
    },
    {
      category: "Portfolio & Personal Branding",
      name: "Portfolio Website 3",
      mainImage: portfolio3,
    },
  ];

  const filteredPortfolios =
    selectedCategory === "All"
      ? portfolios
      : portfolios.filter(
          (portfolio) => portfolio.category === selectedCategory
        );

  const openModal = (imageData) => {
    setCurrentImage(imageData);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setCurrentImage(null);
  };

  const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  return (
    <Layouts>
      <h2 className="text-center mt-5 top-para">Our Portfolio</h2>
      <hr
        style={{
          borderTop: "3px solid #ffc107",
          width: "100px",
          margin: "20px auto",
          opacity: "1",
        }}
      />
      <div className="portfolio-page container mt-5">
        {/* Hamburger Icon Button for Offcanvas (Mobile Devices Only) */}
        <Button
          variant="primary"
          className="d-md-none mb-3 portfolio-menu-toggle"
          onClick={toggleOffcanvas}
        >
          <span className="hamburger-icon">&#9776;</span> Portfolio Menu
        </Button>

        {/* Offcanvas Sidenav for Mobile Devices */}
        <Offcanvas
          show={showOffcanvas}
          onHide={toggleOffcanvas}
          className="portfolio-sidenav-offcanvas"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Categories</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="portfolio-category-list">
              {[
                "All",
                "E-commerce",
                "Healthcare & Wellness",
                "Creative & Design",
                "Corporate & Finance",
                "Education",
                "Lifestyle & Leisure",
                "Technology & Digital Services",
                "Real Estate & Construction",
                "Food & Hospitality",
                "Portfolio & Personal Branding",
              ].map((category) => (
                <li className="portfolio-category-item" key={category}>
                  <Button
                    variant="link"
                  style={{textDecoration: "none"}}
                    onClick={() => {
                      setSelectedCategory(category);
                      toggleOffcanvas();
                    }}
                  >
                    {category}
                  </Button>
                </li>
              ))}
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        {/* Categories on Top for Larger Screens */}
        <Tab.Container id="left-tabs-example" defaultActiveKey="All">
      <Nav
        variant="tabs"
        className="portfolio-categories d-none d-md-flex flex-wrap justify-content-center mb-4"
      >
        {[
          "All",
          "E-commerce",
          "Healthcare & Wellness",
          "Creative & Design",
          "Corporate & Finance",
          "Education",
          "Lifestyle & Leisure",
          "Technology & Digital Services",
          "Real Estate & Construction",
          "Food & Hospitality",
          "Portfolio & Personal Branding",
        ].map((category) => (
          <Nav.Item key={category}>
            <Nav.Link
              eventKey={category}
              onClick={() => setSelectedCategory(category)}
              className="m-2 custom-nav-link"
               style={{color: "var(--link-color)"}}
            >
              {category}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
  

    </Tab.Container>

        {/* Portfolio Content */}
        <div className="portfolio-content">
          <div className="portfolio-grid">
            {filteredPortfolios?.map((portfolio, index) => (
              <div
                key={index}
                className="portfolio-card"
                onClick={() => openModal(portfolio)}
              >
                <img loading="lazy" 
                  effect="blur"
                  src={portfolio?.mainImage}
                  alt={portfolio?.name}
                  className="portfolio-card-image"
                />
                <p className="portfolio-card-title">{portfolio?.name}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Modal for Image Display */}
        <Modal
          show={showModal}
          onHide={closeModal}
          size="lg"
          centered
          className="cool-modal"
        >
          {currentImage && (
            <div className="modal-content-container">
              <div className="modal-main-image">
                <img loading="lazy" 
                  effect="blur"
                  src={currentImage.mainImage}
                  alt={currentImage.name}
                  className="portfolio-card-image"
                />
                <Button className="modal-close-button" onClick={closeModal}>
                  &times;
                </Button>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </Layouts>
  );
};

export default Portfolio;
