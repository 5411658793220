import React from 'react'
import "../../Styles/Loader.css"

const Poploader = () => {
  return (
    <div>
     <div class="loader-container">
  <div class="loader-contact">
    <div class="inner_loader"></div>
  </div>
</div>
       <div className='top-para text-center mt-2 mb-3'style={{color:"black "}}>Too Much Traffic... Please Wait...</div>  
    </div>
  )
}

export default Poploader
