import React from 'react'
import {
    FaUser,
    FaCog,
    FaSignOutAlt,
    FaUserSecret,
    FaTachometerAlt,
    FaProjectDiagram,
    FaChartLine,
    FaPaperclip,
    FaShoppingCart,
    FaGift,
    FaCommentDots,
    FaMapMarkerAlt,
    FaAlignLeft,
    FaHandHoldingUsd,
    FaTruck,
  } from "react-icons/fa";
  
  import bytef from "../../../assets/WebpImages/bytedark.webp";
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Sidebar = () => {
  const {admin,setAdmin,logout} = useAuthContext()


  return (

       <div className="bg-white" id="sidebar-wrapper">
          <div className="sidebar-heading py-2 primary-text text-uppercase border-bottom">
            <img loading="lazy"  src={bytef} className="admin-img" alt="Bytefaze" />Bytefaze
          </div>
          <div className="list-group list-group-flush my-3">

         {admin ? <> <Link to="/dashboard" className="list-group-item admin-item list-group-item-action bg-transparent second-text fw-bold">
              <FaTachometerAlt className="me-2" /> Form Details
            </Link>
            <Link to="/dashboard/create-technology" className="list-group-item admin-item list-group-item-action bg-transparent second-text fw-bold">
              <FaProjectDiagram className="me-2" /> Creact Tech
            </Link>
            <Link to="/dashboard/intern-form" className="list-group-item admin-item list-group-item-action bg-transparent second-text fw-bold">
              <FaChartLine className="me-2" /> InternForm
            </Link>
         
          
            </> 
              :
            //userKo hi dikhega
            <>
           <Link to="#" className="list-group-item admin-item list-group-item-action bg-transparent second-text fw-bold">
               <FaChartLine className="me-2" /> UserInfo
            </Link>
            <Link to="#" className="list-group-item admin-item list-group-item-action bg-transparent text-danger fw-bold">
              <FaSignOutAlt className="me-2" /> User
            </Link></>}

            <button type='button' to="#" onClick={logout} className="list-group-item admin-item list-group-item-action bg-transparent text-danger fw-bold">
              <FaSignOutAlt className="me-2" /> Logout
            </button>
          </div>
        </div>

  )
}

export default Sidebar
