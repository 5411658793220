import React, { useState } from "react";
import { Link } from "react-router-dom";
import bytefaze from "../../assets/WebpImages/bytedark.webp";
//import "../../Styles/styles.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/Loader";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Signup = () => {
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    password: ""
  });

 
  
 
  const [msg, setMsg] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setMsg(<Loader/>);
    e.preventDefault();
    try {
      const response = await axios.post("https://bytefaze-backend.onrender.com/api/v1/user/register", data);

      // console.log(response);
      if (response) {
        setMsg("");
        setMsg(response?.data?.message);
      }

      // Set timeout for 3 seconds (adjust as needed)
    } catch (error) {
      // console.log(error, "error");
      if (error?.response?.data?.message) {
        setMsg(error?.response?.data?.message);
      } else {
        setMsg(error?.response?.data?.error);
      }
      setMsg(error?.response?.data?.message);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      // Set timeout for 3 seconds (adjust as needed)
    }
  };


  
  return (
    <div>
      <div className="py-2 suw">
        <div className="container ">
          <div className="row justify-content-md-center ">
            <div className="col-12 col-md-7 col-lg-6 col-xl-6 col-xxl-5">
              <div className="signbg rounded shadow-sm signsh">
                <div className="row">
                  <div className="col-12 text-center">
                    <a href="/#">
                      <img loading="lazy"  src={bytefaze} alt="" width="180" height="120" />
                    </a>
                  </div>
                  <div className="col-12 text-center mb-3">
                    <h2 className="custom-heading">Create an Account</h2>
                  </div>
                </div>
                {/* {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">{success}</div>} */}
                <form >
                  <div className="row gy-3 gy-md-3 overflow-hidden me-1 mx-1">
                    <div className="col-12 col-md-6 ">
                      <label htmlFor="firstName" className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-person-vcard"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z" />
                          </svg>
                        </span>
                        <input
                          type="text"
                          value={data.firstname}
                          onChange={handleChange}
                          className="form-control"
                          name="firstname"
                          id="firstName"
                          placeholder="Enter First Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="lastName" className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-card-checklist"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0M7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0" />
                          </svg>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          value={data.lastname}
                          onChange={handleChange}
                          name="lastname"
                          id="lastName"
                          placeholder="Enter Last Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                          </svg>
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={data.email}
                          onChange={handleChange}
                          id="email"
                          placeholder="Enter your email address"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="mobile" className="form-label">
                        Mobile <span className="text-danger">*</span>
                      </label>
                      <div className="input-group tele">
                        <span className="input-group-text">
                          <i className="ri-phone-line"></i>
                        </span>
                        <input
                          type="tel"
                          className="form-control"
                          name="mobile"
                          value={data.mobile}
                          onChange={handleChange}
                          id="mobile"
                          placeholder="Enter your Mobile Number"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="password" className="form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-key"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </span>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          value={data.password}
                          onChange={handleChange}
                          id="password"
                          placeholder="Enter your password"
                          required
                        />
                      </div>
                    </div>

           
  


    

    <div class="checkbox-wrapper-19">
  <input id="cbtest-19" type="checkbox"required/>
  <label class="check-box" for="cbtest-19">
</label>&nbsp;<span> I agree to  <Link to="/terms">Terms & Conditions</Link></span>
</div>





                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          disabled={msg ? true : false}
                          onClick={handleSubmit}
                          className={`border btn btn-lg ${msg ? "btn2" : "btn"} `}
                          type="submit"
                        >
                          Sign Up
                        </button>
                      </div>
                      <div
                      className="text-center mt-3 "
                      style={{ color: "black" }}
                    >
                      {msg}
                    </div>
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-12 mb-3">
                    <hr className=" border-secondary-subtle" />
                    <p className="m-0 text-secondary text-center sign-link">
                      Already have an account?{" "}
                      <Link
                        to="/login"
                        className="link-primary text-decoration-none"
                      >
                       Login
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default Signup;
