import React, { useEffect, useState } from 'react'
import {
    FaUser,
    FaCog,
    FaSignOutAlt,
    FaUserSecret,
    FaTachometerAlt,
    FaProjectDiagram,
    FaChartLine,
    FaPaperclip,
    FaShoppingCart,
    FaGift,
    FaCommentDots,
    FaMapMarkerAlt,
    FaAlignLeft,
    FaHandHoldingUsd,
    FaTruck,
  } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
const Navbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const token = sessionStorage.getItem('token')

    const userinfo = sessionStorage.getItem("name");
    

    const navigate = useNavigate()

    const logout = () => {
      localStorage.clear();
      navigate("/login");
    }
  
  
    // console.log(currentPage,'currentPage')
  
    useEffect(() => {
      const el = document.getElementById("wrapper");
      const toggleButton = document.getElementById("menu-toggle");
  
      if (toggleButton) {
        toggleButton.onclick = () => {
          el.classList.toggle("toggled");
        };
      }
    }, []);
  
    const handleDropdownToggle = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4">
    <div className="d-flex align-items-center">
      <FaAlignLeft className="primary-text fs-4 me-3" id="menu-toggle" />
     
    </div>

    <div className="d-flex ms-auto">
      <div className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center"
          href="#"
          id="navbarDropdown"
          role="button"
          onClick={handleDropdownToggle}
          aria-expanded={isDropdownOpen ? "true" : "false"}
          style={{textTransform:"capitalize"}}
        >
          <FaUserSecret className="me-2"  /> {userinfo}
        </a>
        <ul
          className={
            "dropdown-menu admin-dropdown-menu my-dropdown-menu" +
            (isDropdownOpen ? " show" : "")
          }
          aria-labelledby="navbarDropdown"
        >
          <li>
            <a className="dropdown-item user-drop" href="#">
              <FaUser className="me-2" /> Profile
            </a>
          </li>
          <li>
            <a className="dropdown-item user-drop" href="#">
              <FaCog className="me-2" /> Settings
            </a>
          </li>
          <li>
            <button className="dropdown-item user-drop" onClick={logout}>
              <FaSignOutAlt className="me-2" /> Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  )
}

export default Navbar
