import React from 'react'
import Sidebar from '../common/Sidebar'
import Navbar from '../common/Navbar'

const Layout = ({children}) => {
  return (
   <>
    <div className="d-flex" id="wrapper">
      <Sidebar/>
      <div id="page-content-wrapper">
        <Navbar/>
        {children}
      </div>
    </div>
   </>
  )
}


export default Layout
