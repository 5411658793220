import React, { useEffect, useState } from "react";
import Sidebar from "../../Admin/common/Sidebar";
import Layout from "../../Admin/layout/Layout";
import Poploader from "../../pages/Poploader.jsx";
import axios from "axios";
import { color } from "framer-motion";

const CreateTechnology = () => {
  const [tech, setTech] = useState([]);
  const [currentstate, setCurrentstate] = useState(null);
  const token = sessionStorage.getItem("token");
  const [msgColor, setMsgColor] = useState("");

  const [msg, setMsg] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    duration: "",
    price: "",
  });

  const [updateformData, setUpdateFormData] = useState({
    name: "",
    duration: "",
    price: "",
  });

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handelUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData({
      ...updateformData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const alldata = {
      name: formData.name,
      duration: parseInt(formData.duration),
      price: parseFloat(formData.price),
    };

    setMsg(<Poploader />);
    try {
      const res = await axios.post(
        `https://bytefaze-backend.onrender.com/api/v1/technology/create`,
        alldata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(res, "res");

      setMsg( res.data.message);
      setMsgColor("green");
      setFormData({ name: "", duration: "", price: "" });
      getalltechnology();
    } catch (error) {
      // console.log(error?.response?.data?.error , "errorr hai  mihir");
      // alert("not created");
      setMsg(error?.response?.data?.error );
      setMsgColor("red");
    }
  };

  const getalltechnology = async () => {
    try {
      const response = await axios.get(
        `https://bytefaze-backend.onrender.com/api/v1/technology`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTech(response.data);
      console.log(response.data, " all tech");
    } catch (error) {
      // console.error("Error fetching technologies:", error);
    }
  };

  useEffect(() => {
    getalltechnology();
  }, []);

  const deleteTechnology = async (_id) => {
    try {
      const result = await axios.delete(
        `https://bytefaze-backend.onrender.com/api/v1/technology/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(result, "id mil gai");
      setTech(tech.filter((item) => item._id !== _id));

      getalltechnology();
    } catch (error) {
      console.log("id nahi gai");
    }
  };

  const handleUpdate = async () => {
    try {
      const updateData = {
        name: updateformData.name,
        duration: parseInt(updateformData.duration),
        price: parseFloat(updateformData.price),
      };

      await axios.put(
        `https://bytefaze-backend.onrender.com/api/v1/technology/${currentstate._id}`,
        updateData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowModal(false);
      getalltechnology();
    } catch (error) {
      console.log(error, "error");
    }
  };
  const openUpdateModal = (techitems) => {
    setCurrentstate(techitems);
    setUpdateFormData({
      name: techitems.name,
      duration: techitems.duration,
      price: techitems.price,
    });
    setShowModal(true);
  };

  return (
    <Layout>
      <div className="container">
        <h2 className="fs-2 m-0 text-center">Create Technology</h2>
        <form className="row g-3 mt-4" onSubmit={handleSubmit}>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              style={{ border: "2px solid black" }}
              type="text"
              className="form-control black-border"
              id="name"
              name="name"
              placeholder="Enter"
              value={formData.name}
              onChange={handelChange}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="price" className="form-label">
              Price
            </label>
            <input
              style={{ border: "2px solid black" }}
              name="price"
              type="number"
              className="form-control black-border"
              id="price"
              placeholder="Enter Price"
              value={formData.price}
              onChange={handelChange}
            />
          </div>
          <div className="col-md-4 col-sm-6">
            <label htmlFor="duration" className="form-label">
              Duration
            </label>
            <input
              style={{ border: "2px solid black" }}
              name="duration"
              type="number"
              className="form-control black-border"
              id="duration"
              placeholder="Enter Duration"
              value={formData.duration}
              onChange={handelChange}
            />
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Submit
            </button>
            <div style={{color : msgColor}}>
              {msg}
            </div>
          </div>
        </form>
        <div className="container mt-4">
          <div className="table-responsive">
            <table
              className="table table-striped"
              style={{ border: "2px solid black" }}
            >
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Price</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tech.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.price}</td>
                    <td>{item.duration}</td>
                    <td>
                      <button
                        className="btn-danger p-1 border"
                        type="button"
                        onClick={() => deleteTechnology(item._id)}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <button
                        className=" btn-success p-1 border"
                        type="button"
                        onClick={() => openUpdateModal(item)}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal show" tabIndex="-1" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Technology</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="updateName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="updateName"
                      name="name"
                      onChange={handelUpdateChange}
                      value={updateformData.name}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="updatePrice" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="updatePrice"
                      name="price"
                      onChange={handelUpdateChange}
                      value={updateformData.price}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="updateDuration" className="form-label">
                      Duration
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="updateDuration"
                      name="duration"
                      onChange={handelUpdateChange}
                      value={updateformData.duration}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdate}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default CreateTechnology;
