import React from 'react'
// import "../../Styles/styles.css"
const Loader = () => {
  return (
    <>
<div class="loader-container">
  <div class="loader-contact">
    <div class="inner_loader"></div>
  </div>
</div>

   <div className='top-para text-center mt-2 mb-3'>Too Much Traffic... Please Wait...</div>
  </>
  )
}

export default Loader
