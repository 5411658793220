import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bt from "../../assets/WebpImages/bytedark.webp";
//import "../../Styles/styles.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../pages/Loader";
import { useAuthContext } from "../context/AuthContext";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Login = () => {
  const [data, setData] = useState({
    identifier: "",
    password: "",
  });

  const navigate = useNavigate();
  const [msg, setMsg] = useState("");

  const { admin, setAdmin } = useAuthContext();

  console.log(admin, "admib");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    setMsg(<Loader />);
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://bytefaze-backend.onrender.com/api/v1/user/login",
        data
      );
      // console.log(response);
      if (response.data) {      
        console.log(response.data,'data')
      
        // console.log(response,'resp')
        if (response?.data?.user?.role === "admin") {
          console.log("User is admin, setting admin state to true");
          setAdmin(response?.data?.user?.role === "admin");
        } else {
          setAdmin(response?.data?.user?.role === "admin");
        }
        sessionStorage.setItem("token", (response.data.token));
        sessionStorage.setItem("name",`${response?.data?.user?.firstname} ${response?.data?.user?.lastname}`)
        console.log(response?.data?.user?.firstName,'response?.data?.user?.firstName')
        navigate("/dashboard");
        setMsg("");
        setMsg(response?.data?.message);
      }

      // Set timeout for 3 seconds (adjust as needed)
    } catch (error) {
      // console.log(error, "error");
      if (error?.response?.data?.message) {
        setMsg(error?.response?.data?.message);
      } else {
        setMsg(error?.response?.data?.error);
      }
      setMsg(error?.response?.data?.message);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      // Set timeout for 3 seconds (adjust as needed)
    }
  };

  return (
    <div className="container signup-container">
      <div className="card signup-card">
        <div className="row h-100">
          <div className="col-lg-6">
            <div className="logo-container">
              <img loading="lazy"  src={bt} className="logo" alt="logo" />
            </div>
            <div className="image-container mt-4">
              <img loading="lazy" 
                src="https://i.imgur.com/uNGdWHi.png"
                className="signup-image"
                alt="signup"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card signup-form-card">
              <form>
                <div className="form-groups">
                  <label className="form-label">
                    Email Address / Mobile No.
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="identifier"
                    value={data.identifier}
                    onChange={handleChange}
                    placeholder="Enter a valid email address"
                    required
                  />
                </div>
                <div className="form-groups">
                  <label className="form-label">Password</label>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                    placeholder="Enter password"
                    required
                  />
                </div>
                <div className="form-groups text-right">
                  <Link to="/forgot" className="forgot-password-link">
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-groups">
                  <button
                    disabled={msg ? true : false}
                    onClick={handleSubmit}
                    type="submit"
                    className={`border btn btn-lg w-100 ${
                      msg ? "btn2" : "btn"
                    } `}
                  >
                    Login
                  </button>
                </div>
                <div className="text-center mt-3  " style={{ color: "black" }}>
                  {msg}
                </div>
                <div className="form-groups text-center mt-2">
                  <small className="register-text ">
                    Don't have an account?{" "}
                    <Link to="/signup" className="register-link">
                      Register
                    </Link>
                  </small>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Login;
