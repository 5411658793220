import React, { useEffect, useState } from "react";
import "../../../Styles/sidebar.css";
import {
  FaUser,
  FaCog,
  FaSignOutAlt,
  FaUserSecret,
  FaChartLine,
  FaGift,
  FaAlignLeft,
  FaHandHoldingUsd,
  FaTruck,
} from "react-icons/fa";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Dashloader from "../../Admin/Dashloader";
import Layout from "../../Admin/layout/Layout";
import moment from "moment"; // Add moment.js for easier date handling

const AdminDashboard = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [form, setForm] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const itemsPerPage = 15;

  const handlePrevClick = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setPage((prevPage) => Math.min(prevPage + 1, Math.ceil(form.length / itemsPerPage)));
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    getFormData();
  }, []);

  const getFormData = async () => {
    setLoader(true);
    const token = sessionStorage.getItem("token");
    // console.log(token, "token mil gaya");

    try {
      if (!token) {
        navigate("/login");
      } else {
        const response = await axios.get(
          `https://bytefaze-backend.onrender.com/api/v1/form/user-forms?pageSize=15000&page=1`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          setForm(response?.data?.forms?.reverse());
          setLoader(false);
        }
      }
    } catch (error) {
      // console.log(error, "yeee error aa gai");
    }
  };

  // Calculate the current items to display based on the current page
  const currentItems = form.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  // Get today's date
  const today = moment().format("DD/MM/YYYY");

  return (
    <Layout>
      <div className="container-fluid px-3">
        <div className="row g-3 my-2">
          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">{form.length}</h3>
                <p className="fs-5">Total Forms</p>
              </div>
              <FaGift className="fs-1 primary-text border rounded-full secondary-bg p-3" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">19</h3>
                <p className="fs-5">48 hours</p>
              </div>
              <FaHandHoldingUsd className="fs-1 primary-text border rounded-full secondary-bg p-3 " />
            </div>
          </div>

          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">07</h3>
                <p className="fs-5">24 Hours</p>
              </div>
              <FaTruck className="fs-1 primary-text border rounded-full secondary-bg p-3" />
            </div>
          </div>

          <div className="col-md-3">
            <div className="p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded">
              <div>
                <h3 className="fs-2">02</h3>
                <p className="fs-5">Forms Today</p>
              </div>
              <FaChartLine className="fs-1 primary-text border rounded-full secondary-bg p-3" />
            </div>
          </div>
        </div>

        <div className="row my-5">
          <h3 className="fs-3 mb-3">All Data</h3>
          <div className="col">
            {loader ? (
              <div className="admin-load">
                <div>
                  <Dashloader />
                </div>
                ...Loading
                <div>
                  <Dashloader />
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table bg-white rounded shadow-sm table-hover w-100 ">
                  <thead>
                    <tr className="adtable mx-2 ">
                      <th scope="col">#</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col">City</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Button</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((element, i) => (
                      <tr key={i} className={element?.date === today ? "blink" : ""}>
                        <th scope="row">{(page - 1) * itemsPerPage + i + 1}</th>
                        <td>{element?.firstName}</td>
                        <td>{element?.lastName}</td>
                        <td>{element?.phoneNumber}</td>
                        <td>{element?.city}</td>
                        <td>{element?.date}</td>
                        <td>{element?.time}</td>
                        <td>
                          <Link to={`/dashboard/details/${element?._id}`}>
                            <button className="btn-success border p-1">
                              View More
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li
                      className={`page-item ${page === 1 ? "disabled" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={handlePrevClick}
                        disabled={page === 1}
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(Math.ceil(form.length / itemsPerPage))].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${page === index + 1 ? "active" : ""}`}
                        onClick={() => setPage(index + 1)}
                      >
                        <button className="page-link">{index + 1}</button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${page === Math.ceil(form.length / itemsPerPage) ? "disabled" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={handleNextClick}
                        disabled={page === Math.ceil(form.length / itemsPerPage)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
