import React, { useEffect, useRef, useState } from "react";
// import { motion } from "framer-motion";
// //import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import "../../Styles/styles.css";
import { Link, useNavigate ,useLocation} from "react-router-dom";
import logobytefaze from "../../assets/WebpImages/bytedark.webp";
import ecom from "../../assets/WebpImages/shopping.webp";
import logo from "../../assets/WebpImages/logo-design.webp";
import Responsive from "../../assets/WebpImages/responsive.webp";
import mobile from "../../assets/WebpImages/mobile.webp";
import seo from "../../assets/WebpImages/seo.webp";
import Web from "../../assets/WebpImages/web-design.webp";
import digital from "../../assets/WebpImages/social-media.webp";
import domain from "../../assets/WebpImages/domain.webp";
import corporate from "../../assets/WebpImages/presentation.webp";
import host from "../../assets/WebpImages/hosting.webp";
import trendingImg from "../../assets/WebpImages/trend.webp";
import mobileDev from "../../assets/WebpImages/mobile-development.webp";
import frontEnd from "../../assets/WebpImages/front-end.webp";
import backend from "../../assets/WebpImages/back-end.webp";
import Infonav from "./Infonav";
//import { LazyLoadImage } from "react-lazy-load-image-component";

const Navbar = ({ theme, toggleTheme }) => {
  const location = useLocation();

  const underLine = ( <hr
              style={{
                borderTop: "3.1px solid #ffc107",
                width: location.pathname.length > 10 ? "55px" : "30px",
                margin: "-14px auto",
                opacity: "1",
              }}/>
      )
      
  // const[home,setHome]=useState(false)
  const navigate = useNavigate();
  const navbarRef = useRef(null);
  // const path = useLocation();
  const navbarFunc = () => {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      navbarRef.current.classList.add("header_shrink");
    } else {
      navbarRef.current.classList.remove("header_shrink");
    }
  };
  // useEffect(()=>{
  //   if(path.pathname==='/'){
  //     setHome(true)
  //   }
  // },[path])

  useEffect(() => {
    window.addEventListener("scroll", navbarFunc);
    return () => window.removeEventListener("scroll", navbarFunc);
  }, []);

  const [showServices, setShowServices] = useState(false);

  const toggleServices = () => {
    setShowServices(!showServices);
  };

  const [showHire, setShowHire] = useState(false);

  const toggleHire = () => {
    setShowHire(!showHire);
  };

  const [showServicesMob, setShowServicesMob] = useState(false);

  const toggleServicesMob = () => {
    setShowServicesMob(!showServicesMob);
  };

  const [showServicesFront, setShowServicesFront] = useState(false);

  const toggleServicesFront = () => {
    setShowServicesFront(!showServicesFront);
  };

  const [showServicesBack, setShowServicesBack] = useState(false);

  const toggleServicesBack = () => {
    setShowServicesBack(!showServicesBack);
  };

  const [showServicesTrend, setShowServicesTrend] = useState(false);

  const toggleServicesTrend = () => {
    setShowServicesTrend(!showServicesTrend);
  };
  // const ScrollToTop = () => {
  //   // Extracts pathname property(key) from an object
  //   const { pathname } = useLocation();

  //   // Automatically scrolls to top whenever pathname changes
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, [pathname]);
  // };

  const list = [
    {
      whatWeDo: [
        {
          url: "website-designing",
          img: Web,
          text: "Website Designing",
        },
        {
          url: "responsive-website",
          img: Responsive,
          text: "Responsive Website",
        },
        {
          url: "ecommerce-website",
          img: ecom,
          text: "E-Commerce Website",
        },
        {
          url: "domain-registration",
          img: domain,
          text: "Domain Registration",
        },
        {
          url: "application-development",
          img: mobile,
          text: "Application Development",
        },
        {
          url: "corporate-presentation",
          img: corporate,
          text: "Corporate Presentation",
        },
        {
          url: "web-hosting",
          img: host,
          text: "Web Hosting",
        },
        {
          url: "digital-marketing",
          img: digital,
          text: "Digital Marketing",
        },
        {
          url: "logo-designing",
          img: logo,
          text: "Logo Designing",
        },
        {
          url: "seo",
          img: seo,
          text: "SEO",
        },
      ],
    },
  ];



  const firstSegmentOfUrl = location.pathname.slice(1).split('-')[0];



  useEffect(() => {
    const navbarToggle = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");

    if (
      navbarToggle &&
      navbarCollapse &&
      navbarCollapse.classList.contains("show")
    ) {
      navbarToggle.classList.add("collapsed");
      navbarCollapse.classList.remove("show");
    }
  }, [location]);

  return (
    <>
    <Infonav/>
  
    <div className="navbar_wrapper"  ref={navbarRef}>
      
      <div className="container">
        <header className="menu_wrapper">
          <nav className="navbar navbar-expand-lg">
            <div className="container ">
              <Link
                to="/"
                // onClick={ScrollToTop}
                className="navbar-brand me-auto bytelogo"
              >
                <img loading="lazy"  src={logobytefaze} width={150} alt="Web Designing in Ahmedabad" />
              </Link>
              <div className="toggle toggleMobile ">
                {/* <span onClick={toggleTheme}>
                    {theme === "light-theme" ? (
                      <span>
                        <i className="ri-sun-line"></i>Light
                      </span>
                    ) : (
                      <span>
                         <i className="ri-moon-line"></i>Dark
                      </span>
                    )}
                  </span> */}
                <div className="">
                  <input
                    type="checkbox"
                    className="nav-checkbox"
                    id="checkbox"
                    onChange={toggleTheme}
                    checked={theme === "light-theme"}
                  />
                  <label htmlFor="checkbox" className="checkbox-label">
                    <i className="ri-sun-line"></i>
                    <i className="ri-moon-line"></i>
                    <span className="ball"></span>
                  </label>
                </div>
              </div>

              <button
                className="navbar-toggler collapsed  d-flex d-lg-none flex-column justify-content-around"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="toggler-icon top-bar"></span>
                <span className="toggler-icon middle-bar"></span>
                <span className="toggler-icon bottom-bar"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link" to="/" >
                      Home
                    </Link>
                    <div className="nav-underline">
                    {location.pathname === "/" ? underLine : null}
                    </div>
            
                  </li>
             
                  <li className="nav-item">
                    <Link className="nav-link" to="/who-we-are">
                      Who we are
                    </Link>
                    <div className="nav-underline">
                    {location.pathname === "/who-we-are" ? underLine : null}
                    </div>
                  </li>
                  {/* experimemntal; */}

                  <li className="nav-item dropdown dropdown-mega position-static dropdown-hover">
                    <div className="services-container d-flex flex-column justify-content-between align-items-center">
                      <Link
                        className="nav-link"
                        id="navbarDropdown"
                        role="button"
                        to="/what-we-do"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                      >
                        What we do
                        <i className="ri-arrow-down-s-line down_arrow"></i>
                      </Link>
                         <div className="nav-underline">
                    {location.pathname === "/what-we-do" ? underLine : null}
                    </div>
                   
                      <div className="plus-toggle" onClick={toggleServices}>
                        {showServices ? (
                          <i className="ri-subtract-line mega_list_icon"></i>
                        ) : (
                          <i className="ri-add-line mega_list_icon"></i>
                        )}
                      </div>
                      
                      <div className="dropdown-menu shadow">
                        <div className="mega-content px-md-0">
                          <div className="container-fluid">
                            <div className="row ">
                              <div className="col-lg-4 col-sm-12 py-2 px-xl-5 px-1 ">
                                <div className="list-group ">
                                  <Link
                                    to="/website-designing"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy"  src={Web} alt="Website Designing Services in Ahmedabad" width={40} />
                                    &nbsp;&nbsp;Website Designing
                                  </Link>
                                  <Link
                                    to="/domain-registration"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy"  src={domain} alt="Domain Registration Services Ahmedabad" width={40} />
                                    &nbsp;&nbsp;Domain Registration
                                  </Link>
                                  <Link
                                    to="/web-hosting"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy"  src={host} alt="Web Hosting Services" width={40} />
                                    &nbsp;&nbsp;Web Hosting
                                  </Link>
                                  <Link to="/seo" className="list-group-item">
                                    <img loading="lazy"  src={seo} alt="SEO Services Company" width={40} />
                                    &nbsp;&nbsp;SEO
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-4 col-sm-12 py-2 px-xl-5 px-1">
                                <div className="list-group">
                                  <Link
                                    to="/responsive-website"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy" 
                                      src={Responsive}
                                      alt="Responsive Website Services"
                                      width={40}
                                    />
                                    &nbsp;&nbsp;Responsive Website
                                  </Link>
                                  <Link
                                    to="/application-development"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy" 
                                      src={mobile}
                                      alt="Application Development Ahmedabad"
                                      width={40}
                                    />
                                    &nbsp;&nbsp;Application Development
                                  </Link>
                                  <Link
                                    to="/digital-marketing"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy" 
                                      src={digital}
                                      alt="Digital Marketing Agency Ahmedabad"
                                      width={40}
                                    />
                                    &nbsp;&nbsp;Digital Marketing
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-4 col-sm-12  py-2 px-xl-5 px-1">
                                <div className="list-group">
                                  <Link
                                    to="/ecommerce-website"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy" 
                                      src={ecom}
                                      alt="E-Commerce Website Designing Company Ahmedabad"
                                      width={40}
                                    />
                                    &nbsp;&nbsp;E-Commerce Website
                                  </Link>
                                  <Link
                                    to="/corporate-presentation"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy" 
                                      src={corporate}
                                      alt="Corporate Presentation"
                                      width={40}
                                    />
                                    &nbsp;&nbsp;Corporate Presentation
                                  </Link>
                                  <Link
                                    to="/logo-designing"
                                    className="list-group-item"
                                  >
                                    <img loading="lazy" 
                                      src={logo}
                                      alt="Logo Designing Company Ahmedabad"
                                      width={40}
                                    />
                                    &nbsp;&nbsp;Logo Designing
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {showServices && (
                        <div>
                          {list[0].whatWeDo.map((element, i) => {
                            return (
                              <li key={i}>
                                <i className="ri-arrow-right-s-line mobile-nav-links"></i>
                                <Link
                                  to={`/${element.url}`}
                                  className="mobile-nav-links"
                                >
                                  {element.text}
                                </Link>
                              </li>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </li>

                  {/* --------------------------------------------------------experimental-------------------/ */}

                  <li className="nav-item dropdown dropdown-mega position-static dropdown-hover">
                    <div className="services-container d-flex flex-column justify-content-between align-items-center">
                      <Link
                        className="nav-link "
                        id="navbarDropdown"
                        role="button"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        to={'/#'}
                        // onClick={toggleHire}
                      >
                        Hire a developer
                        <i className="ri-arrow-down-s-line down_arrow"></i>
                      </Link>
                      <div className="nav-underline">
                    {firstSegmentOfUrl === "hire" ? underLine : null}
                    </div>
                      <div className="plus-toggle" onClick={toggleHire}>
                        {showHire ? (
                          <i className="ri-subtract-line mega_list_icon"></i>
                        ) : (
                          <i className="ri-add-line mega_list_icon"></i>
                        )}
                      </div>
                      <div className="dropdown-menu shadow">
                        <div className="mega-content px-md-0">
                          <div className="container-fluid">
                            <div className="row ">
                              <div className="col-lg-3 col-sm-12 py-2 px-xl-5 px-1 ">
                                <div className="list-group ">
                                  <h6>
                                    <img loading="lazy" 
                                      src={mobileDev}
                                      alt="Mobile App Developers"
                                      width={30}
                                    />
                                    Mobile App Developers
                                  </h6>
                                  <Link
                                    to="/hire-flutter-app-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Flutter Developers
                                  </Link>

                                  <Link
                                    to="/hire-ios-app-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>IOS
                                    Developers
                                  </Link>
                                  <Link
                                    to="/hire-android-app-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Android Developers
                                  </Link>
                                  <Link
                                    to="/hire-swift-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Swift Developers
                                  </Link>
                                  <Link
                                    to="/hire-react-native-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    React Native Developers
                                  </Link>
                                  <Link
                                    to="/hire-ionic-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Ionic Developers
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-12 py-2 px-xl-5 px-1">
                                <div className="list-group">
                                  <h6>
                                    <img loading="lazy"  src={frontEnd} alt="Frontend Developers" width={30} />
                                    &nbsp;Frontend Developers
                                  </h6>
                                  <Link
                                    to="/hire-react-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    React Developers
                                  </Link>
                                  <Link
                                    to="/hire-vue-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Vue Developers
                                  </Link>
                                  <Link
                                    to="/hire-nextjs-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Next.js Developer
                                  </Link>
                                  <Link
                                    to="/hire-angular-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Angular Developers
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-12  py-2 px-xl-5 px-1">
                                <div className="list-group">
                                  <h6>
                                    <img loading="lazy"  src={backend} alt="Backend Developers" width={30} />
                                    &nbsp;Backend Developers
                                  </h6>
                                  <Link
                                    to="/hire-python-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Python Developers
                                  </Link>
                                  <Link
                                    to="/hire-golang-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Golang Developers
                                  </Link>
                                  <Link
                                    to="/hire-nodejs-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Node.js Developers
                                  </Link>
                                  <Link
                                    to="/hire-php-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    PHP Developers
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-12  py-2 px-xl-5 px-1">
                                <div className="list-group">
                                  <h6>
                                    <img loading="lazy"  src={trendingImg} alt="Trending" width={30} />
                                    &nbsp;Trending
                                  </h6>
                                  <Link
                                    to="/hire-ui-ux-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    UI/UX Designers
                                  </Link>

                                  <Link
                                    to="/hire-full-stack-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Full Stack Developers
                                  </Link>
                                  <Link
                                    to="/hire-qa-engineers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    QA Engineers
                                  </Link>
                                  <Link
                                    to="/hire-devops-engineers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    DevOps Engineers
                                  </Link>
                                  <Link
                                    to="/hire-game-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Game Developers
                                  </Link>
                                  <Link
                                    to="/hire-data-scientist"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    Data Scientist
                                  </Link>
                                  <Link
                                    to="/hire-ai-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    AI Developers
                                  </Link>
                                  <Link
                                    to="/hire-ml-developers"
                                    className="list-group-items"
                                  >
                                    <i className="ri-arrow-right-s-line"></i>
                                    ML Developers
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  {showHire && (
                    <li className="nav-item dropdown dropdown-mega position-static dropdown-hover">
                      <li>
                        <div className="services-container d-flex justify-content-between align-items-center">
                          <li
                            className="mobile-nav-links"
                            onClick={toggleServicesMob}
                          >
                            <img loading="lazy"  src={mobileDev} alt="Mobile App Developers" width={25} />
                            &nbsp; Mobile App Developers
                          </li>
                          <div
                            className="plus-toggle"
                            onClick={toggleServicesMob}
                          >
                            {showServicesMob ? (
                              <i className="ri-subtract-line mega_list_icon"></i>
                            ) : (
                              <i className="ri-add-line mega_list_icon"></i>
                            )}
                          </div>
                        </div>
                      </li>
                      {showServicesMob && (
                        <div className="mobile-nav-wrapper">
                          <li>
                            <Link
                              to="/hire-flutter-app-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Flutter Developer
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-ios-app-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              IOS Developers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-android-app-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Android Developers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-swift-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Swift Developers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-react-native-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              React Native Developers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-ionic-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Ionic Developers
                            </Link>
                          </li>
                        </div>
                      )}
                      <li>
                        <div className="services-container d-flex justify-content-between align-items-center">
                          <li
                            className="mobile-nav-links"
                            onClick={toggleServicesFront}
                          >
                            <img loading="lazy"  src={frontEnd} alt="Frontend Developers" width={30} />
                            &nbsp; Frontend Developers
                          </li>
                          <div
                            className="plus-toggle"
                            onClick={toggleServicesFront}
                          >
                            {showServicesFront ? (
                              <i className="ri-subtract-line mega_list_icon"></i>
                            ) : (
                              <i className="ri-add-line mega_list_icon"></i>
                            )}
                          </div>
                        </div>
                      </li>
                      {showServicesFront && (
                        <div className="mobile-nav-wrapper">
                          <li>
                            <Link
                              to="/hire-react-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              React.js Developers
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/hire-angular-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Angular Developers
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/hire-vue-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Vue Developers
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/hire-nextjs-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Next.js Developers
                            </Link>
                          </li>
                        </div>
                      )}
                      <li>
                        <div className="services-container d-flex justify-content-between align-items-center">
                          <li
                            className="mobile-nav-links"
                            onClick={toggleServicesBack}
                          >
                            <img loading="lazy"  src={backend} alt="Backend Developer" width={30} />
                            &nbsp; Backend Developers
                          </li>
                          <div
                            className="plus-toggle"
                            onClick={toggleServicesBack}
                          >
                            {showServicesBack ? (
                              <i className="ri-subtract-line mega_list_icon"></i>
                            ) : (
                              <i className="ri-add-line mega_list_icon"></i>
                            )}
                          </div>
                        </div>
                      </li>
                      {showServicesBack && (
                        <div className="mobile-nav-wrapper">
                          <li>
                            <Link
                              to="/hire-golang-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Golang Developer
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-nofdejs-pp-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Node.js Developer
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-php-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Php Developer
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-python-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Python Developer
                            </Link>
                          </li>
                        </div>
                      )}
                      <li>
                        <div className="services-container d-flex justify-content-between align-items-center">
                          <li
                            className="mobile-nav-links"
                            onClick={toggleServicesTrend}
                          >
                            <img loading="lazy"  src={trendingImg} alt="Trending" width={30} />
                            &nbsp; Trending
                          </li>
                          <div
                            className="plus-toggle"
                            onClick={toggleServicesTrend}
                          >
                            {showServicesTrend ? (
                              <i className="ri-subtract-line mega_list_icon"></i>
                            ) : (
                              <i className="ri-add-line mega_list_icon"></i>
                            )}
                          </div>
                        </div>
                      </li>
                      {showServicesTrend && (
                        <div className="mobile-nav-wrapper">
                          <li>
                            <Link
                              to="/hire-full-stack-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Full Stack Developers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-qa-engineers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              QA Engineers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-devops-engineers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              DevOps Engineers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-game-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Game Developers
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="hire-data-scientist"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Data Scientist
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-ai-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                           Ai Developer
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-ml-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              Ml Developer
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/hire-ui-ux-developers"
                              className="mobile-nav-links"
                            >
                              <i className="ri-arrow-right-s-line"></i>
                              UI/UX Designers
                            </Link>
                          </li>
                        </div>
                      )}
                    </li>
                  )}

                  {/* hire dropdown ends */}

                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/internships"
                    >
                      Internships
                    </Link>
                    <div className="nav-underline">
                    {location.pathname === "/internships" ? underLine : null}
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Comming Soon"
                      to={"/blog"}
                    >
                      Blog
                    </Link>
                    <div className="nav-underline">
                    {location.pathname === "/blog" ? underLine : null}
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/portfolio"
                    >
                      Portfolio
                    </Link>
                    <div className="nav-underline">
                    {location.pathname === "/portfolio" ? underLine : null}
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/pricing"
                    >
                      Enquiry
                    </Link>
                    <div className="nav-underline">
                    {location.pathname === "/pricing" ? underLine : null}
                    </div>
                  </li>
                </ul>

                <div className="toggle toggleDesktop">
                  <div>
                    <input
                      type="checkbox"
                      className="nav-checkbox"
                      id="checkbox"
                      onChange={toggleTheme}
                      checked={theme === "light-theme"}
                    />
                    <label htmlFor="checkbox" className="checkbox-label">
                      <i className="ri-sun-line"></i>
                      <i className="ri-moon-line"></i>
                      <span className="ball"></span>
                    </label>
                  </div>
                </div>
                <button
                  className="button-33"                 
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>

    </>
  );
};

export default React.memo(Navbar);
