
import React from 'react'

const Websitefaq = () => {
  return (

    <>

      <section className="py-3 py-md-5">
        <div className="container" itemscope itemtype="https://schema.org/FAQPage">
          <div className="row gy-5 gy-lg-0 align-items-lg-center">
            <div className="row justify-content-xl-center" >
              <div className="col-12 col-xl-11" >
                <h2
                  className="  text-capitalize text-center"
                  style={{ color: "var(--link-color)" }}
                >
                  " FAQ's on Website Designing & Web Development"
                </h2>
                <div
                  className="accordion accordion-flush"
                  id="accordionExample"

                >
                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        What services do you offer?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                          We offer a wide range of services including <b>custom Website development & designing, web and mobile app development, UI/UX design website</b>, quality assurance, and maintenance & support.
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        What are the Major steps to designing a website?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                          <p className='mb-2'>As a web designer, I approach website creation with a specific workflow to craft effective and engaging online experiences. Here's a breakdown of the key phases from my perspective:</p>
                          <ol>
                            <li><b>Discovery</b></li>
                            <li><b>Planning</b></li>
                            <li><b>Design</b></li>
                            <li><b>Development</b></li>
                            <li><b>Testing & Launch</b></li>
                            <li><b>Maintenance</b></li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How much does a new website cost?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text"><p>
                          The cost of a new website can vary depending on several factors, such as the complexity of the website, the features you require, the number of pages, and the level of customization needed. However, to give you a general idea, a basic website might start around RS. 18,000  while a more complex website with e-commerce functionality or custom features could cost RS. 1,00,000 or more.</p>
                          <p>Here at Bytefaze, we offer free consultations to discuss your project in detail and provide you with a custom quote that fits your budget and needs.</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Will my website be mobile-friendly?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body ">
                      <div className="top-para mb-2" itemprop="text">
                          Absolutely! All Bytefaze websites are responsive and mobile-friendly, ensuring flawless display across all devices.
                        </div>

                      </div>
                    </div>
                  </div>


                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Who hosts the website?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      <div className="top-para mb-2" itemprop="text">  We offer website hosting solutions, but the choice is yours. We can help you select a reliable hosting provider if needed.
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Can I update the website myself?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      <div className="top-para mb-2" itemprop="text">
                          Content Management Systems (CMS) allow for easy updates on most websites. We provide training and ongoing support for self-management.
                       </div>

                      </div>
                    </div>
                  </div>


                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        How can I add an email signup form?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      <div className="top-para mb-2" itemprop="text">
                          We integrate email signup forms seamlessly into your website design. Capture leads and grow your email list!
                      </div>
                       

                      </div>
                    </div>
                  </div>


                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        How easy is it to change an existing website?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      <div className="top-para mb-2" itemprop="text">
                          Website redesign complexity varies. We assess your current site and provide solutions – refresh, revamp, or a complete rebuild.
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        What technologies do you use for web design?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      <div className="top-para mb-2" itemprop="text">
                          We utilize cutting-edge technologies like HTML5, CSS3, JavaScript frameworks (React.js, Angular.js), and CMS platforms (WordPress, Drupal) to create dynamic and user-friendly websites.
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        How will a well-designed website help my business?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      <div className="top-para mb-2" itemprop="text">
                          A well-designed website attracts visitors, improves brand image, enhances user experience, and increases conversions, ultimately boosting your business.
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                    <h2 className="accordion-header" id="headingEleven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        Do you design logos?
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEleven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                      <div className="top-para mb-2" itemprop="text">
                          Absolutely! We offer logo design services that complement your website and brand identity.
                       </div>

                      </div>
                    </div>
                  </div>

                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingTwelve">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEleven"
                          aria-expanded="false"
                          aria-controls="collapseEleven"
                        >
                          Can I see examples of your work?
                        </button>
                      </h2>
                      <div
                        id="collapseEleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwelve"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            Of course! We have a portfolio showcasing a variety of website designs across different industries. Visit our website to see our work.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingThirteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwelve"
                          aria-expanded="false"
                          aria-controls="collapseTwelve"
                        >
                          Why choose Bytefaze over free website templates?
                        </button>
                      </h2>
                      <div
                        id="collapseTwelve"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThirteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            Free templates offer limited customization and functionality. We create unique, professional websites tailored to your specific needs and brand.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingFourteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThirteen"
                          aria-expanded="false"
                          aria-controls="collapseThirteen"
                        >
                          Do you redesign existing websites?
                        </button>
                      </h2>
                      <div
                        id="collapseThirteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFourteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            Yes! We breathe new life into outdated websites, improving functionality, design, and user experience.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingFifteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFourteen"
                          aria-expanded="false"
                          aria-controls="collapseFourteen"
                        >
                          Do you work with international clients?
                        </button>
                      </h2>
                      <div
                        id="collapseFourteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFifteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            Absolutely! We have a proven process for collaborating with clients worldwide.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFifteen"
                          aria-expanded="false"
                          aria-controls="collapseFifteen"
                        >
                          How do we handle future upgrades or changes?
                        </button>
                      </h2>
                      <div
                        id="collapseFifteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingSixteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            We offer website maintenance and update packages, ensuring your website remains secure and up-to-date. We also handle upgrades and modifications seamlessly.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingSixteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeventeen"
                          aria-expanded="false"
                          aria-controls="collapseSixteen"
                        >
                          Will my website work on all devices?
                        </button>
                      </h2>
                      <div
                        id="collapseSixteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="collapseSeventeen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            Yes, your Bytefaze website will be responsive and accessible on all devices, including iPhones, iPads, Android phones, and tablets.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingEighteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeventeen"
                          aria-expanded="false"
                          aria-controls="collapseSeventeen"
                        >
                          Can I use my existing domain name?
                        </button>
                      </h2>
                      <div
                        id="collapseSeventeen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingEighteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            Yes, you can absolutely use your existing domain name with your Bytefaze website.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingNineteen">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEighteen"
                          aria-expanded="false"
                          aria-controls="collapseEighteen"
                        >
                          What are your payment steps?
                        </button>
                      </h2>
                      <div
                        id="collapseEighteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingNineteen"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            We offer flexible payment plans to suit your budget. We also accept payment through UPI and Cheque. We'll discuss options during the consultation.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingTwenty">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNineteen"
                          aria-expanded="false"
                          aria-controls="collapseNineteen"
                        >
                          How long does a website redesign take?
                        </button>
                      </h2>
                      <div
                        id="collapseNineteen"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwenty"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            The timeline depends on project complexity. We'll provide an estimated timeframe during the consultation.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                      <h2 className="accordion-header" id="headingTwentyone">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwenty"
                          aria-expanded="false"
                          aria-controls="collapseTwenty"
                        >
                          Does my website need HTTPS?
                        </button>
                      </h2>
                      <div
                        id="collapseTwenty"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwentyone"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                        <div className="top-para mb-2" itemprop="text">
                            Yes, HTTPS is crucial for website security and user trust. We configure HTTPS for all our websites.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Websitefaq