import React,{ useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";


//import "../../Styles/styles.css";
import India from "../../assets/WebpImages/india.webp";
import google from "../../assets/WebpImages/Google.webp";
import clutch from "../../assets/WebpImages/clutch.webp";
import designrush from "../../assets/WebpImages/designrush.webp";
import topdevelopers from "../../assets/WebpImages/topdevelopers.webp";
import goodfrims from "../../assets/WebpImages/goodfirms.webp";
import axios from "axios";
import Loader from "../pages/Loader";
//import { LazyLoadImage } from "react-lazy-load-image-component";
const Footer = () => {
  // const form = useRef(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    area: "",
    message: "",
    subject: "",
  });

  const [msg, setMsg] = useState("");

  const [contactus, setContactus] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/contact-us") {
      setContactus(true);
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 const handleSubmit = async (e) => {
    setMsg(<Loader />);

    e.preventDefault();
    try {
      const response = await axios.post(
        "https://bytefaze-backend.onrender.com/api/v1/form/user-form-submit",
        formData
      );
      // console.log(response);
      if (response) {
        setMsg("");
      
      }

      // Set timeout for 3 seconds (adjust as needed)
    } catch (error) {
      // console.log(error, "error");
      if (error?.response?.data?.message) {
       
      } else {
      
      }
      setMsg(error?.response?.data?.message);
      setTimeout(() => {
        setMsg("");
      }, 4000);
      // Set timeout for 3 seconds (adjust as needed)
    }
  };
  return (
    <>
      <hr className="top-para" />
      {!contactus ? (
        <>
          <section className="container ">
            <div className="row ">
              <div className="col-lg-5 ">
                <div className="footer_contact_form overflow-hidden p-4">
                  <h4 className="text-center">Collaborate with Bytefaze</h4>
                  <form
                
                    method="post"
                    id="form1"
                    name="form"
                  >
                    <div className="row gy-3 ">
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-id-card-line"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Enter First Name"
                            className="form-control"
                            id="firstName1"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-id-card-line"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Enter Last Name"
                            className="form-control"
                            id="lastName1"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-mail-check-line"></i>
                          </span>
                          <input
                            type="email"
                            placeholder="Enter Email"
                            id="email1"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.email}
                            name="email"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6  ">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-phone-line"></i>
                          </span>
                          <input
                            type="tel"
                            placeholder="Phone Number"
                            className="form-control"
                            id="phoneNumber1"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={formData.phoneNumber}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 py-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-user-location-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter City"
                        className="form-control"
                        id="city1"
                        name="city"
                        onChange={handleChange}
                        value={formData.city}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 py-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="ri-user-location-line"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Enter Area"
                        className="form-control"
                        id="area1"
                        name="area"
                        onChange={handleChange}
                        value={formData.area}
                        required
                      />
                    </div>
                  </div>

                      <div className="col-12 ">
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className="ri-chat-thread-line"></i>
                          </span>

                          <input
                            type="text"
                            placeholder="Subject"
                            className="form-control"
                            id="subject1"
                            name="subject"
                            onChange={handleChange}
                            value={formData.subject}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <textarea
                          placeholder="About your project"
                          onChange={handleChange}
                          value={formData.message}
                          className="form-control"
                          id="message1"
                          name="message"
                          rows="2"
                          required
                        ></textarea>
                      </div>
                      <div className="col-12  ">
                        <div className="d-grid col-lg-4">
                          <button 
                          disabled={msg ? true : false}
                          onClick={handleSubmit}
                          className={`border ${msg ? "btn2" : "btn"} `}
                           type="submit">
                            INQUIRE NOW
                          </button>
                        </div>
                        <div
                      className="text-center mt-3  "
                      style={{ color: "var(--link-color)" }}
                    >
                      {msg}
                    </div>
                      </div>
                    </div>
                
                  </form>
                </div>
              </div>
              <div className="col-lg-6 mx-auto ">
                <div className="row  ">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 my-2 ">
                      <h5 className="h6 top-headings"> Our Offices:</h5>
                      <p className="top-para">
                        <img loading="lazy"  src={India} alt="India's National Flag" />
                        &nbsp;&nbsp;India
                      </p>
                      <p className="h6">
                        B-408, 4th floor, Sumel-8, <br />
                        Ajit mill cross road, <br />
                        Rakhiyal Ahmedabad,
                        <br /> Gujarat — 380023
                      </p>
                    </div>
                    <div
                      className="col-12 col-md-6 col-lg-6 my-2 "
                      // style={{ backgroundColor: "yellow" }}
                    >
                      <h5 className="h6 top-headings">Skype:  </h5>  
                      <p className="h5">
                        <Link to="" className="footer_list">
                        <i className="ri-skype-fill h4"></i> biz.bytefaze
                        </Link>
                      </p>
                    
                    </div>
                  </div>
                  {/* <hr className="border border-3 " /> */}
                  <div className="row mt-2 ">
                    <div className="col-12 col-md-6 col-lg-6 mb-2 my-3 ">
                      <h5 className="h6 top-headings"> Project Inquiries:</h5>
                      <p className="h5">
                        <Link
                          to="mailto:info@bytefaze.com"
                          target="_blank"
                          className="footer_list"
                        >
                          info@bytefaze.com
                        </Link>
                      </p>
                      <h5 className="h6 top-headings"> Phone Number:</h5>
                      <p className="h5">
                        <Link
                          to="tel:+91 8469717272"
                          target="_blank"
                          className="footer_list"
                        >
                          +91-846-971-7272
                        </Link>
                      </p>
                   
                    </div>
                    <div
                      className="col-12 col-md-6 col-lg-6 my-2 responsive-maps"
                      style={{ color: "var( --primary-color)" }}
                    >
                         <div
                        className="col-12 col-md-6 col-lg-6 my-2"
                        style={{ color: "var( --primary-color)" }}
                      >
                        <h5 className="h6 top-headings"> Social Media </h5>

                        <Link
                          className="top-para"
                          to="https://www.linkedin.com/company/bytefaze/"
                          target="_blank"
                        >
                          <i className="ri-linkedin-box-fill h2 "></i>
                        </Link>

                        <Link
                          className="top-para"
                          to="https://www.instagram.com/bytefaze/"
                          target="_blank"
                        >
                          <i className="ri-instagram-fill h2 px-2 "></i>
                        </Link>
                        <Link
                          className="top-para"
                          to="https://x.com/Bytefaze"
                          target="_blank"
                        >
                          <i className="ri-twitter-x-fill h2 px-2"></i>
                        </Link>
                        <Link
                          className="top-para"
                          to="https://www.facebook.com/bytefazewebsolutionsahmedabad/"
                          target="_blank"
                        >
                          <i className="ri-facebook-box-fill h2 px-2"></i>
                        </Link>
                        <Link
                          className="top-para"
                          to="https://wa.me/+918469717272"
                          target="_blank"
                        >
                          <i className="ri-whatsapp-fill h2 px-2"></i>
                        </Link>
                        <Link
                          className="top-para"
                          to="https://join.skype.com/invite/rD0feVkZHxlJ"
                          target="_blank"
                        >
                          <i className="ri-skype-fill h2 px-2"></i>
                        </Link>
                      </div>
                      {/* <h5 className="h6 top-headings"> Our Location:</h5> */}
                      {/* <embed
                      title="bytefaze map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.0594503828183!2d72.63063977521156!3d23.021589279174385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e87e9bff46ed7%3A0xae649c37dd078be5!2sBytefaze%20-%20Web%20Designing%20%7C%20Free%20internship%20web%20development%20Company%20%7C%20Website%20designing%20in%20React%20JS%20%7C%20SEO%20Services!5e0!3m2!1sen!2sin!4v1714198273573!5m2!1sen!2sin"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        ""
      )}

      {/* <hr className="top-para"/> */}
      <section className="container mt-3 ">
        <div >
          <div className="d-flex align-items-center justify-content-between px-2 scrollable-items gap-5 px-5 ">
            <div className="ratings">
              <Link to='https://clutch.co/profile/bytefaze#highlights' target="_blank">
                <img loading="lazy"  className="footer_img" src={clutch} width={70} alt="Clutch" />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-fill"></i>
              </Link>
              &nbsp;&nbsp;5.0
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to="/" target="_blank">
                <img loading="lazy" 
                  className="footer_img"
                  src={goodfrims}
                  width={100}
                  alt="Goodfrims"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-fill"></i>
              </Link>
              &nbsp;&nbsp;5.0
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to="/" target="_blank">
                <img loading="lazy" 
                  className="footer_img"
                  src={designrush}
                  width={80}
                  alt="Designrush"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.5
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to='https://www.topdevelopers.co/profile/bytefaze-web-solutions' target="_blank">
                <img loading="lazy" 
                  className="footer_img"
                  src={topdevelopers}
                  width={100}
                  alt="Top Developers"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.5
            </div>
            <div className="line-box"></div>
            <div className="ratings">
              <Link to='https://maps.app.goo.gl/htfvmBqbdJubqQ69A' target="_blank">
                <img loading="lazy"  className="footer_img" src={google} width={80} alt="Google" />
                &nbsp;&nbsp;&nbsp;&nbsp;<i className="ri-star-half-line"></i>
              </Link>
              &nbsp;&nbsp;4.9
            </div>
          </div>
        </div>
        <div className="row mt-3 ">
          <div className="col-lg-3 col-12 col-md-6">
            <h5 className="top-para">Services</h5>
            <ul className="list-unstyled">
              <li>
                <Link className="footer_list" to="/website-designing">
                  Website Designing
                </Link>
              </li>
              <li>
                <Link className="footer_list " to="/responsive-website">
                  Responsive Website
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/application-development">
                  Application Development
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/ecommerce-website">
                  E-Commerce Website
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/domain-registration">
                  Domain Registration
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/corporate-presentation">
                  Corporate Presentation
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/web-hosting">
                  Web Hosting
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/digital-marketing">
                  Digital Marketing
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/logo-designing">
                  Logo Designing
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/seo">
                  SEO
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-12 col-md-6">
            <h5 className="top-para">Our Developers</h5>
            <ul className="list-unstyled">
              <li>
                <Link className="footer_list" to="/hire-flutter-app-developers">
                  Flutter Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ios-app-developers">
                  IOS Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-android-app-developers">
                  Android Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-swift-developers">
                  Swift Developers
                </Link>
              </li>
              <li>
                <Link
                  className="footer_list"
                  to="/hire-react-native-developers"
                >
                  React Native Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ionic-developers">
                  Ionic Developers
                </Link>
              </li>

              <li>
                <Link className="footer_list" to="/hire-react-developers">
                  React.js Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-angular-developers">
                  Angular Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-vue-developers">
                  Vue JS Developers
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-12 col-md-6">
            <h3 className="top-para">&nbsp;</h3>
            <ul className="list-unstyled">
              <li>
                <Link className="footer_list" to="/hire-nextjs-developers">
                  Next.js Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-golang-developers">
                  Golang Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-nodejs-developers">
                  Node.js Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-php-developers">
                  Php Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-python-developers">
                  Python Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-full-stack-developers">
                  Full Stack Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-qa-engineers">
                  QA Engineers
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-12 col-md-6">
            <h3>&nbsp;</h3>
            <ul className="list-unstyled">
              <li>
                <Link className="footer_list" to="/hire-devops-engineers">
                  DevOps Engineers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-game-developers">
                  Game Developers
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-data-scientist">
                  Data Scientist
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ai-developers">
                  Ai Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ml-developers">
                  Ml Developer
                </Link>
              </li>
              <li>
                <Link className="footer_list" to="/hire-ui-ux-developers">
                  UI/UX Designers
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <hr className="top-para" />

      <section className="container">
        <div className="d-flex justify-content-between flex-lg-row flex-column-reverse ">
          <div>
            <div className="top-para text-center ">
              © 2023 Bytefaze. All rights reserved.
            </div>
          </div>
          <div>
            <ul className="list-unstyled d-flex gap-4 justify-content-center">
              <li>
                <Link className="footer_list" to={"/"}>
                  Home
                </Link>
              </li>
              <li>
                <Link className="footer_list" to={"/who-we-are"}>
                  About Us
                </Link>
              </li>
              <li>
                <Link className="footer_list" to={"/what-we-do"}>
                  Services
                </Link>
              </li>
              <li>
                <Link className="footer_list" to={"/blog"}>
                  Our Blogs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Footer);
