import React from "react";

//import { LazyLoadImage } from "react-lazy-load-image-component";


const Why = () => {
//   useEffect(() => {
//     AOS.init({ duration: 1000, delay: 500 });
//   }, []);
  return (
    <section className="why-us">
      <div className="container">
        <div className="row" data-aos="zoom-in">
          <div className="col-md-8 offset-md-2">
            <h2 className="mt-5 text-center">Why Choose Bytefaze for Web Design in Ahmedabad?</h2>
            <div className="mb-5 top-para text-center">
            Choose Bytefaze as your <b>web designing and SEO partner</b> and experience the difference. We are dedicated to helping you create a strong online presence, drive more traffic to your website, and achieve your business goals. Contact us today to get started on your journey towards success.

</div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-up">
              <span>01</span>
              <h4>
                Expertise:
              </h4>
              <p> At Bytefaze, we have a team of skilled professionals with extensive expertise in web designing and SEO services. We stay updated with the latest industry trends and technologies to deliver cutting-edge solutions that meet your business needs.</p>
              {/* <img loading="lazy"  className="img-fluid" src={AboutImg1} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-down">
              <span>02</span>
              <h4>
                Customized Solutions:
              </h4>
              <p>We understand that every business is unique and has its own set of requirements. That's why we provide customized solutions tailored to your specific needs. Our team takes the time to understand your goals, target audience, and brand identity to create a website or implement SEO strategies that align with your vision.</p>
              {/* <img loading="lazy"  className="img-fluid" src={AboutImg2} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-up">
              <span>03</span>
              <h4>
                Customer Satisfaction:
              </h4>
              <p>Your satisfaction is our top priority. We believe in building long-term relationships with our clients, and that starts with delivering exceptional services. We strive to exceed your expectations and ensure that you are fully satisfied with the final product. Our team is dedicated to providing prompt and reliable support throughout the entire process.</p>
              {/* <img loading="lazy"  className="img-fluid" src={AboutImg3} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-up">
              <span>04</span>
              <h4>
                Results-Driven Approach:
              </h4>
              <p>We are committed to delivering tangible results for your business. Our web designing and SEO strategies are designed to improve your online visibility, attract more organic traffic, and ultimately drive growth for your business. We focus on measurable outcomes and continuously monitor and optimize our strategies to ensure maximum effectiveness.</p>
              {/* <img loading="lazy"  className="img-fluid" src={AboutImg4} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box"data-aos="fade-down">
              <span>05</span>
              <h4>
                Transparent Communication:
              </h4>
              <p>We believe in transparent and open communication with our clients. From the initial consultation to the final delivery, we keep you informed about the progress of your project. We value your input and actively involve you in the decision-making process to ensure that the final product meets your expectations.</p>
              {/* <img loading="lazy"  className="img-fluid" src={AboutImg5} alt="" /> */}
            </div>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="main-box">
              <span>06</span>
              <h4>
                Competitive Pricing:
              </h4>
              <p>We understand the importance of cost-effectiveness for businesses. That's why we offer competitive pricing for our web designing, SEO services, and internship training programs. We believe in providing high-quality services at affordable rates, making it accessible for businesses of all sizes.</p>
              {/* <img loading="lazy"  className="img-fluid" src={AboutImg6} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Why);
